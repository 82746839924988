import { Component, Input } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatService } from 'src/app/services/chat.service';
import { Customvalidators } from 'src/app/services/customvalidators.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})


export class ChangePasswordComponent {
  changePasswordForm!: FormGroup;
  changePasswordFormsubmitted = false;
  changePasswordFormShowHide = false;
  id: any;
  loading: boolean = false


  get f(): { [key: string]: AbstractControl } {
    return this.changePasswordForm.controls;
  }

  constructor(private auth: AuthService, private chat: ChatService, private fb: FormBuilder) { }

  ngOnInit(): void {

    this.getUserID();
    this.changePasswordForm = this.fb.group({
      oldpassword: ['', [Validators.required, Validators.minLength(8)]],
      newpassword: ['', [Validators.required, Validators.minLength(8)]],
      // confpassword: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  getUserID() {
    this.id = this.chat.userId;
  }



  onReset() {
    this.loading = true;
    this.changePasswordFormShowHide = true;
    this.changePasswordFormsubmitted = true;
    if (this.changePasswordForm.invalid) {
      this.changePasswordFormShowHide = false;
    } else {
      var changepayload = {
        action: 'change_password',
        old_password: this.changePasswordForm.value.oldpassword,
        new_password: this.changePasswordForm.value.newpassword,
      };
      this.auth.patch('user/' + this.id, changepayload).subscribe({

        next: (res: any) => {
          if (res.status == 200) {
            Swal.fire({
              icon: 'success',
              title: 'Success.',
              text: 'You old password has been changed successfuly.',
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 2000,
            });
            this.loading = false;

          }
          this.changePasswordFormsubmitted = false;
          this.changePasswordFormShowHide = false;
        },
        error: () => {
          this.changePasswordFormsubmitted = false;
          this.changePasswordFormShowHide = false;
          this.loading = false;

        },
      });
    }
  }
}
