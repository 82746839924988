<h4 class="card-title mb-4 "><span class="border-btm"> App Settings</span></h4>
<hr>
<form [formGroup]="apikeyform" (ngSubmit)="onSubmitApikey()">

    <div class="row">
        <div class="col-12">
            <p>Please enter your Send Grid / Twillio API Key to send emails.</p>
        </div>
        <div class="col-sm-12 mb-3">
            <div class="form-group">
                <label class="form-label ">Sendgrid API Key <span class="text-danger">*</span></label>
                <input type="text" class="form-control border-0" style="background-color: #f0f2f8;"
                    formControlName="sendgridkey" [ngClass]="{ 'is-invalid': apisubmitted && f5['sendgridkey'].errors
                          }" required />
                <div *ngIf="apisubmitted && f5['sendgridkey'].errors" class="invalid-feedback">
                    <div *ngIf="f5['sendgridkey'].errors['required']">
                        API Key is required
                    </div>

                </div>
            </div>
        </div>
        <hr>
        <div class="col-12">
            <p>Please enter your Send Grid email to send emails.</p>
        </div>
        <div class="col-sm-12 mb-3">
            <div class="form-group">
                <label class="form-label ">Sendgrid Email <span class="text-danger">*</span></label>
                <input type="text" class="form-control border-0" style="background-color: #f0f2f8;"
                    formControlName="fromemail" [ngClass]="{ 'is-invalid': apisubmitted && f5['fromemail'].errors
                          }" required />
                <div *ngIf="apisubmitted && f5['fromemail'].errors" class="invalid-feedback">
                    <div *ngIf="f5['fromemail'].errors['required']">
                        Sendgrid email is required
                    </div>

                </div>
            </div>
        </div>
        <hr>
        <div class="col-12 ">
            <p>Please enter your Recurly key for payment system.</p>
        </div>
        <div class="col-sm-12 mb-3">
            <div class="form-group">
                <label class="form-label ">Recurly Key <span class="text-danger">*</span></label>
                <input type="text" class="form-control border-0" style="background-color: #f0f2f8;"
                    formControlName="recurlykey" [ngClass]="{ 'is-invalid': apisubmitted && f5['recurlykey'].errors}"
                    required />
                <div *ngIf="apisubmitted && f5['recurlykey'].errors" class="invalid-feedback">
                    <div *ngIf="f5['recurlykey'].errors['required']">
                        Stripe Key is required
                    </div>

                </div>
            </div>
        </div>
        <hr>
        <div class="col-12">
            <p>Please enter your OpenAI key.</p>
        </div>
        <div class="col-sm-12 mb-3">
            <div class="form-group">
                <label class="form-label ">Open AI Key <span class="text-danger">*</span></label>
                <input type="text" class="form-control border-0" style="background-color: #f0f2f8;"
                    formControlName="openaikey" [ngClass]="{ 'is-invalid': apisubmitted && f5['openaikey'].errors
                                                                                                                  }"
                    required />
                <div *ngIf="apisubmitted && f5['openaikey'].errors" class="invalid-feedback">
                    <div *ngIf="f5['openaikey'].errors['required']">
                        Open AI Key is required
                    </div>

                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="form-group mt-3 text-center" *ngIf="apishowhide">
                <div class="spinner-grow" role="status ">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div class="col-12">
            <button type="submit" class="btn btn-custom text-light waves-effect waves-light float-end"> <i
                    class="bx bx-save me-1"></i> Save
                settings</button>
        </div>
    </div>

</form>