import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';


import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  addMemberForm!: FormGroup;
  submitted = false;
  foundmember: boolean = false
  btnload = false;
  loading: boolean = false;
  first_name: any;
  last_name: any;
  email: any;
  password: any;
  members: any;


  get f(): { [key: string]: AbstractControl } {
    return this.addMemberForm.controls;
  }
  @ViewChild('closebutton') closebutton: any;

  constructor(
    private fb: FormBuilder,
    public auth: AuthService,
    private router: Router) { }
  ngOnInit() {

    this.addMemberForm = this.fb.group(
      {
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: [''],
      }
    );

    this.getMembers();


  }


  addMember() {

    this.btnload = true;
    this.submitted = true;
    let endpoint = 'team';
    let body = {
      first_name: this.addMemberForm.value.first_name,
      last_name: this.addMemberForm.value.last_name,
      email: this.addMemberForm.value.email,
      password: '12345678',
    };
    if (this.addMemberForm.invalid) {
      this.btnload = false;
      this.foundmember = false

    } else {
      this.auth.post(endpoint, body).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            // this.members.push(res.data);
            this.addMemberForm.reset();
            this.closebutton.nativeElement.click();
            Swal.fire({
              icon: 'success',
              title: 'Success.',
              text: res.data.message,
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 2000,
            });
            this.getMembers();

          }
          this.foundmember = true
          this.submitted = false;
          this.btnload = false;
        },
        error: (error: any) => {
          this.submitted = false;
          this.btnload = false;
          this.foundmember = this.members.length > 0 ? true : false;
          // this.foundmember = false;
          Swal.fire({
            icon: 'error',
            position: 'top',
            toast: true,
            title: 'Company limit has been reached.',
            showConfirmButton: false,
            timer: 5000,
          });
          this.addMemberForm.reset();
          this.closebutton.nativeElement.click();
        },
      });
    }
  }
  getMembers() {

    if (this.auth.isSuperAdmin() || this.auth.isUser()) {
      return;
    }
    else {
      this.auth.get('team').subscribe({
        next: (res) => {
          this.foundmember = true
          this.members = res.data.data;
        },
        error: (err) => {
          this.foundmember = false

        },
      });
    }
  }
  deleteMember(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete it?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.auth.del('team/' + id, '').subscribe({
          next: (res: any) => {
            if (res.status == 200) {
              this.members = this.members.filter((member: any) => member._id !== id);
              this.foundmember = this.members.length > 0 ? true : false;
              Swal.fire({
                icon: 'success',
                position: 'top',
                toast: true,
                title: 'Success',
                text: res.data.message,
                showConfirmButton: false,
                timer: 2500,
              });

            }
          },
          error: (err) => {
            this.foundmember = false

          },
        });
      }
    });
  }
}


