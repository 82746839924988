
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';


import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.scss']
})
export class FieldsComponent implements OnInit {

  addFieldForm!: FormGroup;
  editFieldForm!: FormGroup;
  submitted = false;
  foundfiled: boolean = false
  btnload = false;
  loading: boolean = false;
  name: any;
  type: any;
  fields: any;
  id: any;
  search: string = ''
  searchField: any[] = [];
  pag: number = 1;



  get f(): { [type: string]: AbstractControl } {
    return this.addFieldForm.controls;
  }
  get f1(): { [type: string]: AbstractControl } {
    return this.editFieldForm.controls;
  }
  @ViewChild('closebutton') closebutton: any;
  @ViewChild('editclosebutton') editclosebutton: any;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router) { }
  ngOnInit() {

    this.addFieldForm = this.fb.group(
      {
        name: ['', Validators.required],
        type: ['', Validators.required],
      }
    );
    this.editFieldForm = this.fb.group(
      {
        name: ['', Validators.required],
        type: ['', Validators.required],
      });

    this.getField();


  }

  addField() {
    this.btnload = true;
    this.submitted = true;
    let endpoint = 'field';

    let body = {
      name: this.addFieldForm.value.name,
      type: this.addFieldForm.value.type,

    };
    if (this.addFieldForm.invalid) {
      this.btnload = false;
      this.foundfiled = false

    } else {
      this.auth.post(endpoint, body).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.addFieldForm.reset();
            this.closebutton.nativeElement.click();
            Swal.fire({
              icon: 'success',
              title: 'Success.',
              text: res.data.message,
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 2000,
            });
            this.getField();

          }
          this.foundfiled = true
          this.submitted = false;
          this.btnload = false;
        },
        error: (error: any) => {
          this.submitted = false;
          this.btnload = false;
          Swal.fire({
            icon: 'error',
            position: 'top',
            toast: true,
            title: error.error.data.message,
            showConfirmButton: false,
            timer: 5000,
          });
          this.addFieldForm.reset();
          this.closebutton.nativeElement.click();
        },
      });

    }
  }
  getField() {
    this.auth.get('field?action=all').subscribe({
      next: (res) => {
        this.foundfiled = true
        this.loading = true
        this.fields = res.data.data;
        this.fields.sort((a: any, b: any) => a.name.localeCompare(b.name))
        this.searchField = this.fields;
        // if (this.fields.length > 0) {
        //   this.fieldData = this.fields.slice(0, 5)
        // }
      },
      error: (err) => {
        this.foundfiled = false
        this.loading = false

      },
    });
  }
  // fieldData: any[] = [];

  // showMore() {
  //   let liblength = this.fieldData.length + 5;
  //   if (liblength > this.fields.length) {
  //     liblength = this.fields.length;
  //   }
  //   this.fieldData = this.fields.slice(0, liblength);
  // }

  onSearch() {
    if (!this.search.trim()) {
      this.fields = this.searchField;
      // this.fieldData = this.fields.slice();
      this.foundfiled = true;
    } else {
      this.fields = this.searchField.filter((field) =>
        field.name.toLowerCase().includes(this.search.toLowerCase())
      );
      // this.fieldData = this.fields.slice(0, 5);
      this.foundfiled = this.fields.length > 0;
    }

  }





  editField(index: any) {
    this.editFieldForm.patchValue(this.fields[index])
    this.id = this.fields[index]._id;
  }

  deleteField(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete it?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.auth.del('field/' + id, '').subscribe({
          next: (res: any) => {
            if (res.status == 200) {
              this.fields = this.fields.filter((member: any) => member._id !== id);
              this.foundfiled = this.fields.length > 0 ? true : false;
              Swal.fire({
                icon: 'success',
                position: 'top',
                toast: true,
                title: 'Success',
                text: res.data.message,
                showConfirmButton: false,
                timer: 2500,
              });

            }
            this.getField()
          },
          error: (err) => {
            this.foundfiled = false
            Swal.fire({
              icon: 'error',
              position: 'top',
              toast: true,
              title: 'No Category Found',
              showConfirmButton: false,
              timer: 5000,
            });

          },
        });
      }
    });
  }
  edit() {
    this.loading = true;
    let body = {
      name: this.editFieldForm.value.name,
      type: this.editFieldForm.value.type,
    }
    this.auth.patch('field/' + this.id, body).subscribe({
      next: (res) => {


        if (res.status == 200) {
          this.editFieldForm.reset();
          this.editclosebutton.nativeElement.click();
          Swal.fire({
            icon: 'success',
            title: 'Success.',
            text: res.data.message,
            position: 'top',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
          });
          this.getField();

        }

      },
      error: (err) => {
        this.loading = false;
      },
    });
  }
}













