

import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatService } from 'src/app/services/chat.service';
import { trigger, transition, style, animate } from '@angular/animations';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.scss'],
  animations: [
    trigger('slideNext', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('300ms', style({ transform: 'translateX(0%)', opacity: 1 })),
      ]),
      transition(':leave', [
        animate('300ms', style({ transform: 'translateX(100%)', opacity: 0 })),
      ]),
    ]),

    trigger('slidePrevious', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('300ms', style({ transform: 'translateX(0%)', opacity: 1 })),
      ]),
      transition(':leave', [
        animate('300ms', style({ transform: 'translateX(100%)', opacity: 0 })),
      ]),
    ]),

  ],
})

export class BillComponent implements OnInit {
  myplan: any
  data: any
  words: any
  search: any
  id: any
  stripeerror = false
  url: any
  plans: any = []
  plansShown: any[] = [];
  iPlan: number = 0;
  plantype: any
  duration: any;
  yearlyPlansAvailable = false;

  accountCode: any;

  constructor(private auth: AuthService, public chat: ChatService, private route: ActivatedRoute) { }


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setShownPlans();
  }

  async ngOnInit(): Promise<void> {
    this.setShownPlans();


    this.plantype = 'supreme'
    this.duration = 'monthly'
    this.customOptions;
    this.data = JSON.parse(localStorage.getItem('userdata') || '{}');
    await this.packageInfo();
    // await this.getTrialPlan();
    // await this.nextPackage();
    this.getPlans();
    this.getAccountUrl()
    // this.getPayment()
  }

  getAccountUrl(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.accountCode = params.get('account');
    });
  }
  async getTrialPlan() {
    await new Promise(async (resolve) => {
      let q = '';
      q = 'plan?action=gettrial';
      this.auth.get(q).subscribe({
        next: (res) => {
          this.myplan = res.data.data?.title;
          this.words = res.data.data?.words;
          resolve(true);
        },
        error: (err) => {
          resolve(true);
        },
      });
    })

  }
  formatNumber(number: any) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getPlans() {
    let q = '';
    if (this.search) {
      q = 'plan?search=' + this.search;
    } else {
      q = 'plan';
    }
    this.auth.get(q).subscribe({
      next: (res) => {
        this.plans = res.data.plans;
        this.setShownPlans();
      },
      error: (err) => {
      },
    });
  }

  setShownPlans() {
    const cardsPerRow = this.calculateCardsPerRow();
    this.plansShown = this.plans.slice(this.iPlan * cardsPerRow, (this.iPlan + 1) * cardsPerRow);
  }

  calculateCardsPerRow(): number {
    const windowWidth = window.innerWidth;

    if (windowWidth >= 501) {
      return 3;
    } else if (windowWidth <= 500) {
      return 3;
    } else {
      return 1;
    }
  }

  previous() {
    if (this.iPlan !== 0) {
      this.iPlan--;
      this.setShownPlans();
    }
  }

  next() {
    const cardsPerRow = this.calculateCardsPerRow();
    if ((this.iPlan + 1) * cardsPerRow < this.plans.length) {
      this.iPlan++;
      this.setShownPlans();
    }
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      760: {
        items: 3
      }
    },
    nav: true
  }

  carouselInitialized = false;

  onCarouselInitialized() {
    this.carouselInitialized = true;
  }

  unsub=false
  cancel=false
  async packageInfo() {
    return new Promise(async (resolve) => {
      if (this.auth.isSuperAdmin()) {
        this.myplan = '';
        resolve(true);
      } else {
        this.auth.get('form?action=latest_plan').subscribe({
          next: (res) => {
            if (res.data.plan?.plan[0]?.title)
            {
              this.myplan = res.data.plan?.plan[0];
              this.cancel = res.data.plan?.status == 'cancelled' ? true : false
              this.unsub = (this.myplan.type=='trial' || this.cancel) ? false : true
            }else{
              this.myplan = ''
              this.unsub=false
            }
            resolve(true);
          },
          error: (err) => {
            this.myplan = '';
            resolve(true);
          },
        });
      }
    });
  }

  async nextPackage() {
    await new Promise(async (resolve) => {
      this.auth.get('planrequest?action=current').subscribe({
        next: (res) => {
          resolve(true);
        },
        error: (err) => {
          resolve(true);
        }
      })
    });
  }


  onUnsubscribe() {
    this.auth.del('payment/cancelsubscription', '').subscribe({
      next: (res: any) => {
        if (res.status == 200) {
          console.log(res);
          this.packageInfo()
          Swal.fire({
            icon: 'success',
            title: 'Success.',
            text: res.data.message,
            position: 'top',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      },
      error : (error:any)=>{
        Swal.fire({
          icon: 'error',
          position: 'top',
          toast: true,
          title: error.error.data.message,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    })
  }



  pay_id: any
  createlink(plan: any) {
    let temp = ''

    const userdata = JSON.parse(localStorage.getItem('userdata') || '{}');

    this.id = userdata.id;
    let body = {
      plan_id: plan?._id,
      duration: this.duration,
      isLifetime: false,
      multiple: false,
    }

    this.auth.post('payment', body).subscribe({
      next: (res) => {
        window.open(res.data.response, '_self')
      },
      error: (err) => {
        this.stripeerror = true;
      }
    })

  }

  updateSession() {
    let data = {
      // invoice: this.pay_id,
      // userId: this.id
      accountcode: this.accountCode
    };
    this.auth.patch('payment/' + this.id, data).subscribe({
      next: (res: any) => {
        this.auth.validateToken();
        this.chat.setWords(res.data.plan?.words);
        Swal.fire({
          icon: 'success',
          title: 'Success.',
          text: res.data.message,
          position: 'top',
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
      },
      error: (error: any) => {
        Swal.fire({
          icon: 'error',
          position: 'top',
          toast: true,
          title: error.error.data.message,
          showConfirmButton: false,
          timer: 5000,
        });
      },
    });
  }

  setPlantype(text: any) {
    this.plantype = text
  }

  setDuration(e: any) {
    if (e.target.checked) {
      this.duration = 'yearly'
      this.yearlyPlansAvailable = true
    } else {
      this.yearlyPlansAvailable = false
      this.duration = 'monthly'
    }
  }

}
