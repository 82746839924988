import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ApplayoutComponent } from './layout/applayout/applayout.component';
import { SitelayoutComponent } from './layout/sitelayout/sitelayout.component';

import { ForgotPasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';

import { AuthGuard } from './auth/auth.guard';

import { SuperadminModule } from './superadmin/superadmin.module';
import { AdminModule } from './admin/admin.module';
import { UserModule } from './user/user.module';
import { HomeComponent } from './shared/home/home.component';
import { SettingComponent } from './shared/setting/setting.component';
import { HeidichatComponent } from './shared/chatpage/heidichat/heidichat.component';
import { ChatlayoutComponent } from './layout/chatlayout/chatlayout.component';
import { ChangepasswordComponent } from './pages/changepassword/changepassword.component';


const routes: Routes = [
  {
    path: '',
    component: SitelayoutComponent,
    children: [
      { path: '', component: LoginComponent, data: { title: 'Login' } },
      { path: 'register', component: RegisterComponent, data: { title: 'Register' } },
      { path: 'login', component: LoginComponent, data: { title: 'Login' } },
      { path: 'forgot-password', component: ForgotPasswordComponent, data: { title: 'Forgot password' } },
      { path: 'reset-password', component: ChangepasswordComponent, data: { title: 'Reset password' } },

    ],

  },

  {
    path: 'superadmin',
    component: SitelayoutComponent,
    canActivate: [AuthGuard],
    data: { role: 'superadmin' },
    loadChildren: () => SuperadminModule,
  },
  {
    path: 'admin',
    component: SitelayoutComponent,
    canActivate: [AuthGuard],
    data: { role: 'admin' },
    loadChildren: () => AdminModule,
  },
  {
    path: 'user',
    component: SitelayoutComponent,
    canActivate: [AuthGuard],
    data: { role: 'user' },
    loadChildren: () => UserModule,
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
