<!-- <div class="pc-mob-header pc-header">
  
  <a class="pc-head-link d-flex flex-column-reverse align-right mt-0 text-end" id="mobile-collapse"
    (click)="onMenuButtonClick()">
    <div class="">
      <div class=" ">
        <div class="hamburger-inner">
        </div>
      </div>
    </div>
  </a>
</div> -->


<!-- <header class="pc-header mt-5 mt-md-0 pt-4 pt-md-0 photosonic-header">
  <div class="header-wrapper">
    <div class="me-auto pc-mob-drp d-flex">
      <div class="me-auto d-flex ">
        <div class="d-flex gap-lg-3 gap-2 align-items-center justify-content-center">
          <img src="./assets/images/logo.ico" width="30" height="30" class="img-fluid d-none d-md-flex" alt="logo" />
          <p class="fw-6  d-flex my-auto">Heidi Chat</p>

          <button *ngIf="this.auth.getPromt()" (click)="navigate()" type="button"
            class="btn px-3 py-0 text-blue border-blue prompts-lib" data-bs-toggle="tooltip" data-bs-placement="bottom"
            title="You can see massages">
            <span class="fst-normal text-sm">Conservation</span>
          </button>
          <button *ngIf="!this.auth.getPromt()" type="button" class="btn px-3 py-0 text-blue border-blue prompts-lib fw"
            data-bs-toggle="tooltip" data-bs-placement="bottom" title="You can select or edit prompts"
            (click)="navigate()">
            <span class="fst-normal">Prompts library</span>
          </button>
          <div class="d-none d-lg-flex gap-lg-3 gap-2 align-items-center justify-content-center">
            <a href="https://discord.com/" target="_blank" role="button" data-bs-toggle="tooltip"
              data-bs-placement="bottom" title="Discord">
              <i class="bx bxl-discord fs-4 text-blue"> </i>
            </a>
            <a role="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Google Extension"
              href="https://chrome.google.com/webstore/category/extensions" target="_blank">
              <i class="bx bx-extension fs-4 text-blue"></i>
            </a>
            <a class="fw-1" target="_blank" href="https://twitter.com/" role="button" data-bs-toggle="tooltip"
              data-bs-placement="bottom" title="Twitter">
              <i class="bx bxl-twitter fs-4 text-blue"></i>
            </a>
          </div>
        </div>
      </div>

    </div>
    <div>
      <ul class="list-unstyled">
        <div class="d-flex align-items-center justify-content-center">
          <a role="button" data-bs-toggle="modal" data-bs-target="#setting">
            <i class="bx bx-cog fs-4 text-blue" data-bs-toggle="tooltip" data-bs-placement="bottom"
              title="Open Settings"></i>
          </a>
        </div>
        <li class="dropdown pc-h-item">
          <a class="pc-head-link dropdown-toggle arrow me-0" data-bs-toggle="dropdown" href="#" role="button"
            aria-haspopup="false" aria-expanded="false">
            <img src="{{
              this.chat.profilePic
                ? this.auth.getapi() + this.chat.profilePic
                : profilePic
            }}" alt="" class="avatar-logo rounded-circle bg-transparent img-fluid me-2"
              style="height: 30px; width: 30px;" width="30px" /> <span>
              <span class="user-name">
                {{ this.chat.full_name }}
              </span>
              <span class="user-desc">
                {{ this.chat.email }}
              </span>
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-end pc-h-dropdown">
            <a routerLink="/admin/settings" class="dropdown-item mt-0">
              <i class='bx bx-cog fs-6 me-2'></i>Settings
            </a>
            <a class="dropdown-item mt-0 pointer" (click)="logout()">
              <i class="bx bx-power-off fs-6 me-2 cursor-pointer"></i>
              Logout
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</header> -->
<!-- [ Mobile header ] start -->
<div class="pc-mob-header pc-header">
  <!-- <div class="pcm-logo">
      <img src="./assets//images/logo.svg" alt="logo" class="logo logo-lg">
  </div> -->
  <a class="pc-head-link d-flex flex-column-reverse align-right mt-0 text-end" id="mobile-collapse"
    (click)="onMenuButtonClick()">
    <div class="">
      <div class=" ">
        <div class="hamburger-inner">
        </div>
      </div>
    </div>
  </a>
</div>
<!-- [ Mobile header ] End -->

<!-- [ Header ] start -->
<header class="pc-header mt-5 mt-md-0 pt-4 pt-md-0 photosonic-header"
  [ngClass]="this.auth.getsidebarstate() ? 'zero' : ''">

  <div class="header-wrapper">
    <div class="me-auto pc-mob-drp">
      <ul class="list-unstyled">
        <li class="dropdown pc-h-item">
          <a class="pc-head-link pointer d-flex flex-column-reverse align-right mt-0 text-end" id="mobile-collapse"
            (click)="onMenuButtonClick()">
            <div class="">
              <div class=" ">
                <div class="hamburger-inner d-none d-lg-block">
                </div>
              </div>
            </div>
          </a>
        </li>
        <div class="d-flex gap-lg-3 gap-2 align-items-center justify-content-center">
          <div class="pcm-logo">
            <img src="./assets//images/logo.png" alt="logo" class="logo logo-lg ms-4 d-none d-lg-block" height="40px">
          </div>
          <p class="fw-6 d-none d-md-inline-flex my-auto text-blue">Heidi</p>

          <button *ngIf="this.auth.getPromt()" (click)="navigate()" type="button"
            class="btn px-3 py-0 text-blue border-blue prompts-lib" data-bs-toggle="tooltip" data-bs-placement="bottom"
            title="You can see massages">
            <span class="fst-normal text-sm">Conversation</span>
          </button>
          <button *ngIf="!this.auth.getPromt()" type="button" class="btn px-3 py-0 text-blue border-blue prompts-lib fw"
            data-bs-toggle="tooltip" data-bs-placement="bottom" title="You can select or edit prompts"
            (click)="navigate()">
            <span class="fst-normal text-sm">Prompts template</span>
          </button>
          <div class="d-none mt-1 d-lg-flex gap-lg-3 gap-2 align-items-center justify-content-center">
            <a href="https://www.linkedin.com/company/98659888/" target="_blank" role="button" data-bs-toggle="tooltip"
              data-bs-placement="bottom" title="Linkedin">
              <i class="bx bxl-linkedin fs-4 text-blue"> </i>
            </a>
            <a href="https://www.tiktok.com/hi_heidi.ai" target="_blank" role="button" data-bs-toggle="tooltip"
              data-bs-placement="bottom" title="Tiktok">
              <!-- <i class="bx bxs-tiktok fs-4 text-blue"></i> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 24 24" style="fill:#008dff;">
                <path
                  d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z">
                </path>
              </svg>
            </a>
            <a role="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Instagram"
              href="https://www.instagram.com/hi_heidi.ai/" target="_blank">
              <i class="bx bxl-instagram fs-4 text-blue"></i>
            </a>
            <a class="fw-1" target="_blank" href="https://www.facebook.com/profile.php?id=61551215061042" role="button" data-bs-toggle="tooltip"
              data-bs-placement="bottom" title="Facebook">
              <i class="bx bxl-facebook fs-4 text-blue"></i>
            </a>
          </div>
        </div>
      </ul>
    </div>
    <div class="ms-auto">
      <ul class="list-unstyled">
        <li class="dropdown pc-h-item mx-2 d-none d-md-inline-flex">
          <div id="google_translate_element"></div>
        </li>
        <div class="d-flex align-items-center justify-content-center">
          <a role="button" data-bs-toggle="modal" data-bs-target="#setting">
            <i class="bx bx-cog mt-1 fs-4 text-blue" data-bs-toggle="tooltip" data-bs-placement="bottom"
              title="Open Settings"></i>
          </a>
        </div>
        <li class="dropdown pc-h-item">
          <a class="pc-head-link dropdown-toggle arrow me-0 mt-0" data-bs-toggle="dropdown" href="#" role="button"
            aria-haspopup="false" aria-expanded="false">
            <img src="{{this.profilePic ? this.auth.getapi() + this.profilePic : 'assets/images/user.png'
            }}" alt="" class="avatar-logo rounded-circle bg-transparent img-fluid me-2 objFit"
              style="height: 30px; width: 30px;" width="30px" />
            <!-- <img [src]="profilePic || 'assets/images/user.png'" alt=""
              class="avatar-logo rounded-circle bg-transparent img-fluid me-2 objFit" style="height: 30px; width: 30px;"
              width="30px" /> -->

            <span>
              <span class="user-name"> {{ this.chat.full_name }}
              </span>
              <span class="user-desc text-nowrap"> {{ this.chat.email }}
              </span>
            </span>
            <!-- <i class="bx bx-chevron-down text-black"></i> -->
          </a>
          <div class="dropdown-menu dropdown-menu-end pc-h-dropdown">
            <a routerLink="/admin/settings" class="dropdown-item mt-0">
              <i class='bx bx-cog fs-6 me-2'></i>Settings
            </a>
            <a class="dropdown-item mt-0 pointer" (click)="logout()">
              <i class="bx bx-power-off fs-6 me-2 cursor-pointer"></i>
              Logout
            </a>
          </div>
        </li>
      </ul>
    </div>

  </div>
</header>

<!-- Modal for setting -->
<div class="modal fade" id="setting" tabindex="-1" aria-labelledby="settinglable" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5 bold" id="settinglable">
          Customize HeidiChat
        </h1>
        <button type="button" class="btn-close" #closebutton data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body w-100">
        <!-- <div class="d-flex justify-content-between align-items-start">
            <div>
              <p class="pb-0">
                <span class="text-base"> Integrate Google search </span>
                <small class="d-block text-xs"
                  >(Turning on google search might delay the response)</small
                >
              </p>
            </div>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                checked
              />
            </div>
          </div> -->
        <div class="mt-3 d-flex justify-content-between align-items-start">
          <div>
            <p class="pb-0">
              <span class="text-base">Text To Speech Conversion</span>
            </p>
          </div>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" (change)="speechTextToggle($event)" role="switch" />
          </div>
        </div>
        <div class="mt-3 d-flex justify-content-between align-items-start">
          <div>
            <p class="pb-0">
              <span class="text-base">Follow up Memory</span>
            </p>
          </div>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" checked />
          </div>
        </div>
        <!-- <div class="mt-3 d-flex justify-content-between align-items-start">
            <div>
              <p class="pb-0">
                <span class="text-base">Search Results</span>
              </p>
            </div>
            <div class="">
              <select
                class="form-select form-select-sm"
                aria-label=".form-select-sm example"
              >
                <option selected>Concise</option>
                <option>Detailed</option>
              </select>
            </div>
          </div>
          <div class="mt-3 d-flex justify-content-between align-items-start">
            <div>
              <p class="pb-0">
                <span class="text-base">Location</span>
              </p>
            </div>
            <div class="">
              <select
                class="form-select form-select-sm"
                aria-label=".form-select-sm example"
              >
                <option selected>Location</option>
                <option>...</option>
              </select>
            </div>
          </div>
          <div class="mt-3 d-flex justify-content-between align-items-start">
            <div>
              <p class="pb-0">
                <span class="text-base">Image</span>
              </p>
            </div>
            <div class="">
              <select
                class="form-select form-select-sm"
                aria-label=".form-select-sm example"
              >
                <option selected>Stable Diffusion</option>
                <option>DALL-E</option>
              </select>
            </div>
          </div> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-custom w-100" (click)="savePreference()">
          <i class="bx bxs-save me-2"></i>Save Preferences
        </button>
      </div>
    </div>
  </div>
</div>