

// import { Customvalidators } from 'src/app/services/customvalidators.service';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-prompts',
  templateUrl: './prompts.component.html',
  styleUrls: ['./prompts.component.scss']
})
export class PromptsComponent implements OnInit {

  cards: any = new Array(3);
  selectedLanguage: string = '';
  promptForm!: FormGroup;
  promptEditForm!: FormGroup;
  submitted = false;
  btnload = false;
  notFound = false;

  categories: any = [];
  search: any;
  prompts: any = [];
  languages: any = [];
  hide = false;
  id: any;
  responsePic = './assets/images/logo.ico';
  profilePic: any = '';
  mesg: any;
  newMessage: any = '';
  chats: any = [];

  category_id: any;
  message_time: any;
  response_time: any;
  accent_name: any;
  language: any;
  systemLanguage: any;
  private synth: SpeechSynthesis;
  promptId: any;
  promptsToggle: any = false;
  isTabActive = true;

  get f(): { [key: string]: AbstractControl } {
    return this.promptForm.controls;
  }

  get f1(): { [key: string]: AbstractControl } {
    return this.promptEditForm.controls;
  }

  @ViewChild('closebutton') closebutton: any;
  @ViewChild('closebutton1') closebutton1: any;
  @ViewChild('openprompt') openprompt: any;



  constructor(public chat: ChatService, public auth: AuthService, private activateRoute: ActivatedRoute, private router: Router,
    private fb: FormBuilder,
    private renderer: Renderer2) {
    this.auth.getTriggerFunction().subscribe(() => {
      this.getMessages();
    });
    this.synth = window.speechSynthesis;
  }

  async ngOnInit(): Promise<void> {
    this.promptForm = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      template: ['', Validators.required],
      category_id: ['', Validators.required],
      language_id: ['',Validators.required],
      check: [''],
    });

    this.promptEditForm = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      template: ['', Validators.required],
      category_id: ['', Validators.required],
      language_id: [''],
      check: [''],
      action: [''],
    });
    await this.getPrompt();
    this.getCategory();
    this.getLanguages();


    this.getDetail()
    // this.auth.setPromt(false);
    this.chat.getData();
    this.chat.getLoader()
    // this.chat.setPageLoader(false);
    this.chat.getpageLoader();


    this.scrolling = false;
    this.profilePic = this.chat.profilePic;

    this.auth.setPromt(false);
    this.activateRoute.params.subscribe((params) => {
      this.mesg = '';
      this.id = params['id'];
      this.chat.setChatId(this.id);
      this.getMessages();

    });


  }

  ngOnDestroy() {
    this.stopSpeech();
  }


  pcheck = false;
  check(event: any): void {

    this.pcheck = event.target.checked;
  }

  getCategory() {
    this.auth.get('category?action=all').subscribe({
      next: (res) => {
        this.categories = res.data.data;
      },
      error: (err) => {
      },
    });
  }

  getLanguages() {
    this.auth.get('language').subscribe({
      next: (res) => {
        this.languages = res.data.data;
      },
      error: (err) => {
      },
    });
  }
  async getPrompt() {
    await new Promise((resolve) => {

      let q = '';
      if (this.category_id && this.search) {
        q = 'prompt?search=' + this.search + '&category_id=' + this.category_id;
      } else if (this.search) {
        q = 'prompt?search=' + this.search;
      } else if (this.category_id) {
        q = 'prompt?category_id=' + this.category_id;
      } else {
        q = 'prompt';
      }
      this.auth.get(q).subscribe({
        next: (res) => {
          this.notFound = true;
          this.prompts = res.data.data;
          resolve(true)
        },
        error: (err) => {
          this.notFound = false;
          resolve(true)

        },
      });
    })
  }
  submitForm() {
    this.btnload = true;
    this.promptForm.value.check = this.pcheck;
    this.submitted = true;
    let endpoint = 'prompt';
    let body = this.promptForm.value;

    if (this.promptForm.invalid) {
      this.btnload = false;
    } else {
      this.auth.post(endpoint, body).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.promptForm.reset();
            this.reset();
            this.closebutton.nativeElement.click();
            Swal.fire({
              icon: 'success',
              title: 'Success.',
              text: 'Prompt added successfully',
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 2000,
            });
            this.getPrompt();
            // this.router.navigate(['user/heidi/chat']);
          }
          this.submitted = false;
          this.btnload = false;
        },
        error: (error: any) => {
          this.submitted = false;
          this.btnload = false;
          Swal.fire({
            icon: 'error',
            position: 'top',
            toast: true,
            title: error.error.data.message,
            showConfirmButton: false,
            timer: 5000,
          });
        },
      });
    }
  }

  editPrompt(index: any, data: any): void {
    this.id = this.prompts[index]._id;
    if (data?.check) {
      Swal.fire({
        icon: 'warning',
        position: 'top',
        toast: true,
        title: 'Sorry',
        text: "You can't edit public prompt.",
        showConfirmButton: false,
        timer: 2500,
      });
    } else {
      this.openprompt.nativeElement.click();
      this.promptEditForm.patchValue(this.prompts[index]);
    }
  }

  oneditform() {
    this.btnload = true;
    this.submitted = true;

    if (this.promptEditForm.invalid) {
      this.btnload = false;
    } else {
      this.promptEditForm.value.action = 'edit';
      this.auth
        .patch('prompt/' + this.id, this.promptEditForm.value)
        .subscribe({
          next: (res: any) => {
            if (res.status == 200) {
              this.promptEditForm.reset();
              this.closebutton1.nativeElement.click();
              this.reset();
              Swal.fire({
                icon: 'success',
                title: 'Success.',
                text: res.data.message,
                position: 'top',
                toast: true,
                showConfirmButton: false,
                timer: 2000,
              });
            }
            this.submitted = false;
            this.btnload = false;
          },
          error: (error: any) => {
            this.submitted = false;
            this.btnload = false;
            Swal.fire({
              icon: 'warning',
              text: error.data.message,
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 2000,
            });
          },
        });
    }
  }

  delete(data: any) {
    let id = data?._id;
    if (data?.check) {
      Swal.fire({
        icon: 'warning',
        position: 'top',
        toast: true,
        title: 'Sorry',
        text: "You can't delete public prompt.",
        showConfirmButton: false,
        timer: 2500,
      });
    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to delete the Prompt? This cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.auth.del('prompt/' + id, '').subscribe({
            next: (res: any) => {
              if (res.status == 200) {
                this.reset();
                Swal.fire({
                  icon: 'success',
                  position: 'top',
                  toast: true,
                  title: 'Success',
                  text: res.data.message,
                  showConfirmButton: false,
                  timer: 2500,
                });
              }
            },
            error: (err) => { },
          });
        }
      });
    }
  }

  reset() {
    this.prompts = [];
    this.getPrompt();
    this.hide = false;
    this.selected = 999;
    this.promptId = '';
  }

  selected: any;
  selectPrompt(data: any, index: any) {
    this.hide = true;
    this.selected = index;
    this.promptId = data;
    this.chat.setPrompt(data)
  }

  scrolling = false;
  scrollOver() {
    this.scrolling = false;
  }
  hideButton(index: any): void {
    this.selected = index;
    this.hide = false;
  }
  isSpeaking = false;
  speech(str: any) {
    // const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(str);
    utterance.lang = 'en';
    utterance.lang = this.accent_name;
    this.synth.speak(utterance);
  }
  stopSpeech() {
    window.speechSynthesis.cancel();
  }
  @HostListener('document:visibilitychange', ['$event'])
  onVisibilityChange(event: Event): void {
    if (document.visibilityState === 'hidden') {
      this.isTabActive = false;
      this.stopSpeech();
    }
  }
  copy(str: any) {
    navigator.clipboard.writeText(str).then(
      () => {
        Swal.fire({
          icon: 'success',
          text: 'Copied To Clipboard ',
          position: 'top',
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
        // this.closebutton.nativeElement.click();
      },
      (err) => {
        Swal.fire({
          icon: 'warning',
          text: 'Failed to copy to clipboard: ',
          position: 'top',
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    );
  }


  getMessages() {
    this.auth.get('message?action=chat_detail&parent_id=' + this.chat.getChatId()).subscribe({
      next: (res) => {
        if (res.status == 200) {

          let body = {
            message: res.data.messages[0]?.message,
            time: res.data.messages[0]?.message_time,
          };
          this.chats = [];
          this.chats.push(body);
          for (
            var i = 0;
            i < res.data.messages[0]?.message_detail.length;
            i++
          ) {
            const fm = this.chat.formatMessage(res.data.messages[0]?.message_detail[i]?.message)
            let temp: any = []
            let code = false
            if (fm.code.length > 0) {
              code = true
              const min_length = Math.min(fm.code.length, fm.text.length);
              for (let i = 0; i < min_length; i++) {
                temp.push(fm.text[i]);
                temp.push(fm.code[i]);
              }

              if (fm.text.length > fm.code.length) {
                temp.push(...fm.text.slice(min_length));
              }
            }
            let x = {
              message: code ? temp : res.data.messages[0]?.message_detail[i]?.message,
              // message: res.data.messages[0]?.message_detail[i]?.message,
              time: res.data.messages[0]?.message_detail[i]?.message_time,
              links: res.data.messages[0]?.message_detail[i]?.response_links,
              code: code
            };
            this.chats.push(x);
          }
          this.chat.setPageLoader(true);
          this.chat.setLoader(false);

        }
      },
      error: (err) => {
        this.chat.setLoader(false);
        this.chat.setPageLoader(false);
      }
    });
  }


  getDetail() {
    this.auth.get('user?action=self').subscribe({
      next: (res) => {
        this.profilePic = res.data.user?.picture
          ? this.auth.getapi() + res.data.user.picture
          : this.profilePic;
      },
      error: (err) => { },
    });
  }

  // navigate() {
  //   this.router.navigate(['/user/heidi/chat']);
  // }
}
