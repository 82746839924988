<div class="loader-container" *ngIf="loading">
    <div class="loader-logo"></div>
</div>
<div class="container-fluid p-0  bg-white" [ngClass]="{ 'o-5': loading }">
    <div class="row g-0  align-items-center min-vh">
        <div class="col-xl-9">
            <div class="w-100">
                <img src="./assets/images/alpha1.png" class="img-fluid h-100 maskimg w-75" style="object-fit: contain"
                    alt="" />
            </div>
        </div>
        <div class="col-xl-3 mt-5 mt-lg-0">
            <div class="mb-3 mx-5">
                <a class="d-block auth-logo">
                    <img src="./assets/images/logo1-trans.png" class="img-fluid" />
                </a>
            </div>
            <div class="px-4 px-lg-5">
                <div class="w-100">
                    <div class="mb-5">
                        <div class="my-auto ">
                            <h5 class="text-pink-custom mt-3">Welcome Back !</h5>
                            <div class="mt-4">
                                <form [formGroup]="loginForm" (ngSubmit)="onSubmitLoginForm()" class="mb-2">
                                    <div class="mb-3">
                                        <label for="email" class="form-label">Email</label>
                                        <input type="text" class="form-control" id="email" placeholder="Enter email"
                                            formControlName="email"
                                            [ngClass]="{ 'is-invalid': submitted && f1['email'].errors }">
                                        <div *ngIf="submitted && f1['email'].errors" class="invalid-feedback">
                                            <div *ngIf="f1['email'].errors['required']">
                                                Email address is required
                                            </div>
                                            <div *ngIf="f1['email'].errors['email']">
                                                Email address is not valid
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="float-end">
                                            <a routerLink="/forgot-password"
                                                class="text-muted text-decoration-none">Forgot
                                                password?</a>
                                        </div>
                                        <label class="form-label">Password</label>
                                        <div class="input-group">
                                            <input type="{{ showPassword ? 'text' : 'password' }}"
                                                class="form-control pass" placeholder="Enter password"
                                                aria-label="Password" aria-describedby="password-addon"
                                                formControlName="password"
                                                [ngClass]="{ 'is-invalid': submitted && f1['password'].errors }" />
                                            <div class=" rounded-0">
                                                <button
                                                    class="btn btn-light pass d-flex text-center rounded-end rounded-0"
                                                    type="button" (click)=" togglePasswordVisibility()">
                                                    <span class="password-toggle-icon text-pink-custom text-center">
                                                        <i class="bx bx-hide active" *ngIf="!showPassword"></i>
                                                        <i class="bx bx-show" *ngIf="showPassword"></i>
                                                    </span>
                                                </button>
                                            </div>
                                            <div *ngIf="submitted && f1['password'].errors" class="invalid-feedback">
                                                <div *ngIf="f1['password'].errors['required']">
                                                    Password is required
                                                </div>
                                                <div *ngIf="f1['password'].errors['minlength']">
                                                    Password must be at least 8 characters long.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-4 d-grid">
                                        <button class="btn btn-custom w-100 btn-lg" type="submit">Log In</button>
                                    </div>
                                </form>
                                <div class="mt-3 text-center">
                                    <p>
                                        Don't have an account ?
                                        <a routerLink="/register"
                                            class="text-pink-custom text-hover hover pointer text-decoration-none">
                                            Register
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>