import { Component, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent {
  show = false;
  submittedform = false;
  packageform!: FormGroup;
  editpackageform!: FormGroup;
  foundPackage: boolean = false


  loading = false;
  islife = false
  search = '';
  id: any;
  plans: any = [];
  desc: any
  type: any;

  get f(): { [key: string]: AbstractControl } {
    return this.packageform.controls;
  }

  get f1(): { [key: string]: AbstractControl } {
    return this.editpackageform.controls;
  }

  @ViewChild('closebutton') closebutton: any;
  @ViewChild('closebutton1') closebutton1: any;
  constructor(private auth: AuthService, private fb: FormBuilder) { }

  public Editor = ClassicEditor;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Clash Grotesk',
  };

  ngOnInit(): void {
    this.islife = false
    this.packageform = this.fb.group({
      title: ['', Validators.required],
      type: ['trial'],
      lifetime: [''],
      monthly_amount: [''],
      yearly_amount: [''],
      monthly_discount: [''],
      yearly_discount: [''],
      monthly_discount_start_date: [''],
      monthly_discount_end_date: [''],
      yearly_discount_start_date: [''],
      yearly_discount_end_date: [''],
      life_amount: [''],
      life_discount: [''],
      words: [''],
      recurly_plan_id: [''],
      recurly_plan_code: [''],
      recurly_monthly_plan_code: [''],
      recurly_yearly_plan_code: ['']
    });

    this.editpackageform = this.fb.group({
      title: ['', Validators.required],
      type: ['trial'],
      lifetime: [''],
      monthly_amount: [''],
      yearly_amount: [''],
      words: [''],
      projects: [''],
      generated_text: [''],
      description: [''],
      recurly_plan_id: [''],
      recurly_plan_code: [''],
      recurly_monthly_plan_code: [''],
      recurly_yearly_plan_code: ['']
    });

    this.getPlans()

  }

  getPlans() {
    let q = '';
    if (this.search) {
      q = 'plan?search=' + this.search;
    } else {
      q = 'plan';
    }
    this.auth.get(q).subscribe({
      next: (res) => {
        this.loading = true
        this.foundPackage = true
        this.plans = res.data.plans;
      },
      error: (err) => {
        // this.loading = false
        this.foundPackage = false
      },
    });
  }

  onsubmitform() {
    this.show = true;
    this.packageform.value.lifetime = false
    this.packageform.value.description = this.desc
    this.submittedform = true;
    if (this.packageform.invalid) {
      this.show = false;
      this.foundPackage = false

    } else {
      let body: any
      if (!this.islife) {
        body = {
          title: this.packageform.value.title,
          type: 'custom',
          plan_type: 'supreme',
          multiple: false,
          lifetime: this.islife,
          monthly_amount: this.packageform.value.monthly_amount,
          yearly_amount: this.packageform.value.yearly_amount,
          monthly_discount: this.packageform.value.monthly_discount,
          yearly_discount: this.packageform.value.yearly_discount,
          monthly_discount_start_date: this.packageform.value.monthly_discount_start_date,
          monthly_discount_end_date: this.packageform.value.monthly_discount_end_date,
          yearly_discount_start_date: this.packageform.value.yearly_discount_start_date,
          yearly_discount_end_date: this.packageform.value.yearly_discount_end_date,
          words: this.packageform.value.words,
          description: this.packageform.value.description,
        }

      } else {
        body = {
          type: 'custom',
          plan_type: 'supreme',
          multiple: false,
          title: this.packageform.value.title,
          lifetime: this.islife,
          words: this.packageform.value.words,
          description: this.packageform.value.description,
          life_amount: this.packageform.value.life_amount,
          life_discount: this.packageform.value.life_discount,
        }
      }





      this.auth.post('plan', body).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.packageform.reset();
            this.closebutton.nativeElement.click();
            this.reset();
            Swal.fire({
              icon: 'success',
              title: 'Success.',
              text: res.data.message,
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 2000,
            });
          }
          this.submittedform = false;
          this.foundPackage = true;

          this.show = false;
        },
        error: (error: any) => {
          this.submittedform = false;
          this.foundPackage = false;

          this.show = false;
          Swal.fire({
            icon: 'warning',
            text: error.data.message,
            position: 'top',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
          });
        },
      });
    }
  }

  oneditform() {
    this.show = true;
    this.editpackageform.value.lifetime = false
    this.editpackageform.value.description = this.desc

    this.submittedform = true;
    if (this.editpackageform.invalid) {
      this.show = false;
    } else {
      let body: any
      if (!this.islife) {
        body = {
          title: this.editpackageform.value.title,
          type: this.type,
          plan_type: 'supreme',
          multiple: false,
          lifetime: this.islife,
          monthly_amount: this.editpackageform.value.monthly_amount,
          yearly_amount: this.editpackageform.value.yearly_amount,
          monthly_discount: this.editpackageform.value.monthly_discount,
          yearly_discount: this.editpackageform.value.yearly_discount,
          monthly_discount_start_date: this.editpackageform.value.monthly_discount_start_date,
          monthly_discount_end_date: this.editpackageform.value.monthly_discount_end_date,
          yearly_discount_start_date: this.editpackageform.value.yearly_discount_start_date,
          yearly_discount_end_date: this.editpackageform.value.yearly_discount_end_date,
          words: this.editpackageform.value.words,
          description: this.editpackageform.value.description,
          action: 'edit'

        }

      } else {
        body = {
          title: this.editpackageform.value.title,
          type: this.type,
          plan_type: 'supreme',
          multiple: true,
          lifetime: this.islife,
          words: this.editpackageform.value.words,
          description: this.editpackageform.value.description,
          life_amount: this.editpackageform.value.life_amount,
          life_discount: this.editpackageform.value.life_discount,
        }
      }

      this.auth.patch('plan/' + this.id, body).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.packageform.reset();
            this.closebutton1.nativeElement.click();
            this.reset();
            Swal.fire({
              icon: 'success',
              title: 'Success.',
              text: res.data.message,
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 2000,
            });
          }
          this.submittedform = false;
          this.show = false;
        },
        error: (error: any) => {
          this.submittedform = false;
          this.show = false;
          Swal.fire({
            icon: 'warning',
            text: error.data.message,
            position: 'top',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
          });
        },
      });
    }
  }

  editPackage(index: any) {
    this.editpackageform.reset()
    this.editpackageform.patchValue(this.plans[index]);
    this.desc = this.plans[index].description;
    this.type = this.plans[index].type;
    this.islife = this.plans[index]?.lifetime
    this.id = this.plans[index]._id;
  }

  change(id: any, status: any) {
    let aa = '';
    if (status == 'active') {
      aa = 'inactive';
    } else {
      aa = 'active';
    }
    let arr = {
      status: aa,
      action: 'change_status',
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to change the package status?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Change Status!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.auth.patch('plan/' + id, arr).subscribe({
          next: (res: any) => {
            if (res.status == 200) {
              this.reset();
              Swal.fire({
                icon: 'success',
                position: 'top',
                toast: true,
                title: 'Success',
                text: res.data.message,
                showConfirmButton: false,
                timer: 2500,
              });
            }
          },
          error: () => { },
        });
      }
    });
  }

  reset() {
    this.plans = [];
    this.getPlans();
  }

  delete(ind: any) {
    let id = ind;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete the Package? This cannot be undo.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.auth.del('plan/' + id, '').subscribe({
          next: (res: any) => {
            if (res.status == 200) {
              this.reset();
              Swal.fire({
                icon: 'success',
                position: 'top',
                toast: true,
                title: 'Success',
                text: res.data.message,
                showConfirmButton: false,
                timer: 2500,
              });
            }
          },
          error: (err) => {
          },
        });
      }
    });
  }

  lifetime(e: any) {
    if (e.target.checked) {
      this.islife = true;
    }
    else {
      this.islife = false
    }

  }



  isValidNumber(value: any): boolean {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }
}


// monthly_discount: [''],
// yearly_discount: [''],
// monthly_discount_start_date: [''],
// monthly_discount_end_date: [''],
// yearly_discount_start_date: [''],
// yearly_discount_end_date: [''],
// life_amount: [''],
// life_discount: [''],
