import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatService } from 'src/app/services/chat.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-chatsidebar',
  templateUrl: './chatsidebar.component.html',
  styleUrls: ['./chatsidebar.component.scss']
})
export class ChatsidebarComponentr implements OnInit {

  isSidebarOpen = false;
  chats: any = [];
  data: any;
  menu = false;
  plan: any;

  constructor(
    public auth: AuthService,
    public chat: ChatService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.auth.getTriggerFunction().subscribe(() => {
      this.getChats();
    });
  }

  ngAfterViewInit() {

    this.auth.sidebarStateChange.subscribe((state) => {

      (state);
      // this.isSidebarOpen = state;
      this.auth.getsidebarstate();
      this.cdr.detectChanges();

    });
  }

  ngOnInit(): void {

    this.data = JSON.parse(localStorage.getItem('userdata') || '{}');
    this.getChats();
    this.getWords();
    this.packageInfo();

  }
  packageInfo() {
    this.auth.get('form?action=latest_plan').subscribe({
      next: (res) => {
        // if(res.data.plan?.status=='cancelled'){
        //   this.plan='Cancelled'
        // }else{
        if (res.data.plan?.plan[0]?.title) {
          this.plan = res.data.plan?.plan[0];
        } else {
          let x = {
            title: 'Trial',
            type: 'Trial',
          };
          this.plan = x;
        }
      },
      error: (err) => {
        let x = {
          title: 'Trial',
          type: 'Trial',
        };
        this.plan = x;
      },
    });
  }

  toggle1() {
    const element = document.getElementsByClassName(
      'vertical-menu'
    )[0] as HTMLElement;
    if (element.style.display == 'block') {
      element.style.display = 'none';
      this.menu = false;
      // localStorage.setItem('side', '0');
    } else {
      element.style.display = 'block';
      this.menu = true;
      // localStorage.setItem('side', '1');
    }
  }

  getWords() {
    this.auth.get('company/' + this.data?.company_id).subscribe({
      next: (res) => {
        this.chat.setWords(res.data?.data?.words);
      },
      error: (err) => { },
    });
  }

  deleteAll() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete the chat histories? This cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        var val = {
          action: 'delete-chat',
        };
        this.auth.del('message', '').subscribe({
          next: (res: any) => {
            this.reset();
            if (res.status == 200) {
              this.getChats();
              Swal.fire({
                icon: 'success',
                position: 'top',
                toast: true,
                title: 'Success',
                text: 'Your chat history is deleted successfully.',
                showConfirmButton: false,
                timer: 2500,
              });
              this.chat.setPageLoader(false)
            }
            this.reloadCurrentRoute()
          },
          error: () => { },
        });
      }
    });
  }
  reloadCurrentRoute() {
    let targetUrl = ''
    if (this.auth.isAdmin()) {
      targetUrl = '/admin/heidi/chat';
    } else {
      targetUrl = '/user/heidi/chat';

    }
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([targetUrl]);
    });
  }

  isValidNumber(value: any): boolean {
    // Implement your logic to check if 'value' is a valid number here.
    // You can use JavaScript's built-in isNaN() function for simple checks.

    // Example: Check if 'value' is a valid number
    return !isNaN(parseFloat(value)) && isFinite(value);
  }
  navigatetoSetting(){
    this.router.navigate(['/admin/upgrade']);
  }

  reset() {
    this.chats = [];
    this.getChats();
  }

  getChats() {
    this.auth.get('message?action=chats').subscribe({
      next: (res) => {
        this.chats = res.data.messages;
      },
      error: (err) => { },
    });
  }

  newChat() {
    this.chat.setChatId('');
    this.auth.setPromt(false);
    this.reloadCurrentRoute()
  }

  hide = false;
  selected: any;
  showButton(index: any): void {
    this.selected = index;
    this.hide = true;
  }

  hideButton(index: any): void {
    this.selected = index;
    this.hide = false;
  }
  tabactive(index: any) {
    this.auth.setTab(index);
  }

  deleteChat(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete the chat? This cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        var val = {
          action: 'delete-chat',
        };
        this.auth.del('message/' + id, '').subscribe({
          next: (res: any) => {
            this.reset();
            if (res.status == 200) {
              this.getChats();
              Swal.fire({
                icon: 'success',
                position: 'top',
                toast: true,
                title: 'Success',
                text: 'Your chat is deleted successfully.',
                showConfirmButton: false,
                timer: 2500,
              });
              if (this.auth.isAdmin()) {
                this.router.navigate(['/admin/heidi/chat']);
              } else {
                this.router.navigate(['/user/heidi/chat']);

              }
            }
          },
          error: () => { },
        });
      }
    });
  }
}
