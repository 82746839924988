<div class="loader-container" *ngIf="loading">
    <div class="loader-logo"></div>
</div>
<div class="mt-2 px-3">
    <div class="row">
        <p class="h3">Password</p>
        <form [formGroup]="changePasswordForm" (ngSubmit)="onReset()">
            <div class="mb-3 d-block">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="" class="form-label small">Old Password</label>
                            <input type="password" class="form-control" placeholder="" formControlName="oldpassword"
                                [ngClass]="{'is-invalid':changePasswordFormsubmitted && f['oldpassword'].errors}" />
                            <div *ngIf="changePasswordFormsubmitted && f['oldpassword'].errors"
                                class="invalid-feedback">
                                <div *ngIf="f['oldpassword'].errors['required']">
                                    Password is required
                                </div>
                                <div *ngIf="f['oldpassword'].errors['minlength']">
                                    Password length does not match
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="" class="form-label small">New Password</label>
                            <input type="password" class="form-control" formControlName="newpassword" placeholder=""
                                [ngClass]="{
                'is-invalid':
                  changePasswordFormsubmitted && f['newpassword'].errors
              }" />
                            <div *ngIf="changePasswordFormsubmitted && f['newpassword'].errors"
                                class="invalid-feedback">
                                <div *ngIf="f['newpassword'].errors['required']">
                                    Password is required
                                </div>
                                <div *ngIf="f['newpassword'].errors['minlength']">
                                    Password length does not match
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-end">
                <button class="btn btn-custom" type="submit">
                    <i class="bx bx-edit"></i> Save Password
                </button>
            </div>
        </form>
    </div>
</div>