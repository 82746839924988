<div class="loader-container" *ngIf="!loading">
    <div class="loader-logo"></div>
</div>
<div *ngIf="loading">
    <div class="p-2 pb-3 bg-white rounded">
        <div class="row my-2 px-2">
            <p class="h3"><strong>Fields</strong></p>
        </div>

        <div class="my-2 px-2">
            <div class="d-flex flex-1 justify-content-end">
                <div class="search-box flex-grow-1 me-1">
                    <div class="position-relative">
                        <input type="text" class="form-control" [(ngModel)]="search" (input)="onSearch()"
                            placeholder="Search field here..." />
                    </div>
                </div>
                <a href="javascript:void(0)"
                    class="btn btn-custom d-flex justify-content-center align-items-center btn-sm py-1 text-nowrap"
                    style="border-radius: 5px !important" data-bs-toggle="modal"
                    data-bs-target=".bs-field-modal-center">
                    <i class="fa fa-plus my-auto fs-6 me-1"></i> Add New field</a>
            </div>
        </div>
        <div class="my-4 p-2 rounded bg-white ">
            <div class="d-flex flex-column justify-content-center align-items-center py-2" *ngIf="!foundfiled">
                <p class="h4"><strong>Field not found</strong></p>
                <span class="d-flex justify-content-center mt-2">
                </span>
            </div>
            <table class="table table-hover table-fixed" *ngIf="foundfiled">
                <thead>
                    <tr>
                        <th class="">Name</th>
                        <th class="">Type</th>
                        <th class="d-flex justify-content-end pe-5">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let field of fields;  let i=index">
                        <td class="text-capitalize">{{field?.name}}</td>
                        <td class="text-capitalize">{{field?.type}}</td>
                        <td class="d-flex justify-content-end pe-4">
                            <button class="btn btn-info btn-sm me-2 btn-icon" data-toggle="tooltip" data-placement="top"
                                data-backdrop="static" data-keyboard="false" data-bs-toggle="modal"
                                data-bs-target=".bs-edit-modal-center" title="Edit field" (click)="editField(i)">
                                <!-- (click)="editfield(i)" -->
                                <i class="fa fa-edit"> </i>
                            </button>
                            <button class="btn btn-danger btn-sm me-2 btn-icon" data-toggle="tooltip"
                                data-placement="top" title="Delete field" (click)="deleteField(field?._id)">
                                <i class="fa fa-trash-alt"> </i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- Add field -->
<div class="modal fade bs-field-modal-center" tabindex="-1" aria-hidden="true" style="display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add field</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    #closebutton></button>
            </div>
            <div class="modal-body">
                <form action="" [formGroup]="addFieldForm" (ngSubmit)="addField()">
                    <div class="row">


                        <div class="col-12">
                            <div class="form-group">
                                <label for="name" class="form-label">field name</label>
                                <input type="text" class="form-control" formControlName="name"
                                    placeholder="Enter field name" [ngClass]="{
                                                            'is-invalid': submitted && f['name'].errors
                                                          }" />
                                <div *ngIf="submitted && f['name'].errors" class="invalid-feedback">
                                    <div *ngIf="f['name'].errors['required']">
                                        field name is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group  ">
                                <label for="type " class="form-label">Field type</label>
                                <input type="text" class="form-control" formControlName="type"
                                    placeholder="Enter Last Name"
                                    [ngClass]="{'is-invalid': submitted && f['type'].errors}" />
                                <div *ngIf="submitted && f['type'].errors" class="invalid-feedback">
                                    <div *ngIf="f['type'].errors['required']">
                                        Field type is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" mt-3 d-flex justify-content-end">
                        <div class="text-end">
                            <button class="btn btn-custom d-inline-flex gap-1 " type="submit">
                                <i class="bx bx-plus-medical my-auto fs-6"></i> Add field</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>

<!-- edit field -->
<div class="modal fade bs-edit-modal-center" tabindex="-1" aria-hidden="true" style="display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Update field</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    #editclosebutton></button>
            </div>
            <div class="modal-body">
                <form action="" [formGroup]="editFieldForm" (ngSubmit)="edit()">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="name" class="form-label">field name</label>
                                <input type="text" class="form-control" formControlName="name" placeholder=""
                                    [ngClass]="{'is-invalid': submitted && f1['name'].errors}" />
                                <div *ngIf="submitted && f1['name'].errors" class="invalid-feedback">
                                    <div *ngIf="f1['name'].errors['required']">
                                        field name is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group  ">
                                <label for="type " class="form-label">Field type</label>
                                <input type="text" class="form-control" formControlName="type" placeholder=""
                                    [ngClass]="{'is-invalid': submitted && f1['type'].errors}" />
                                <div *ngIf="submitted && f1['type'].errors" class="invalid-feedback">
                                    <div *ngIf="f1['type'].errors['required']">
                                        Field type is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class=" mt-3 d-flex justify-content-end">
                        <div class="text-end">
                            <button class="btn btn-custom d-inline-flex gap-1 " type="submit">
                                <i class="bx bx-plus-medical my-auto fs-6"></i> Save field</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>