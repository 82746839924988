import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { SettingComponent } from './setting/setting.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BillComponent } from './setting/bill/bill.component';
import { ProfileComponent } from './setting/profile/profile.component';
import { ChangePasswordComponent } from './setting/change-password/change-password.component';
import { PreferencesComponent } from './setting/preferences/preferences.component';
import { TeamComponent } from './setting/team/team.component';
import { HeidichatComponent } from './chatpage/heidichat/heidichat.component';
import { ChatBoxComponent } from './chatpage/chatbox/chatbox.component';
import { ChatDetailsComponent } from './chatpage/chatdetails/chatdetails.component';
import { PromptsComponent } from './chatpage/prompts/prompts.component';
import { PhotochatComponent } from './photochat/photochat.component';
import { ProjectsComponent } from './history/projects/projects.component';
import { ProjectdetailsComponent } from './history/projectdetails/projectdetails.component';
import { LongTextComponent } from './history/longtext/longtext.component';
import { BrowserModule } from '@angular/platform-browser';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxEditorModule } from 'ngx-editor';
import { AppsettingComponent } from './setting/appsetting/appsetting.component';
import { UpgradeComponent } from './upgrade/upgrade.component';


@NgModule({
  declarations: [
    HomeComponent,
    SettingComponent,
    BillComponent,
    ProfileComponent,
    ChangePasswordComponent,
    PreferencesComponent,
    TeamComponent,
    HeidichatComponent,
    ChatBoxComponent,
    ChatDetailsComponent,
    PromptsComponent,
    PhotochatComponent,
    ProjectsComponent,
    ProjectdetailsComponent,
    LongTextComponent,
    AppsettingComponent,
    UpgradeComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    CarouselModule,
    BrowserAnimationsModule,
    NgxEditorModule.forRoot({
      locals: {
        // menu
        bold: 'Bold',
        italic: 'Italic',
        code: 'Code',
        underline: 'Underline',
        strike: 'Strike',
        blockquote: 'Blockquote',
        bullet_list: 'Bullet List',
        ordered_list: 'Ordered List',
        heading: 'Heading',
        h1: 'Header 1',
        h2: 'Header 2',
        h3: 'Header 3',
        h4: 'Header 4',
        h5: 'Header 5',
        h6: 'Header 6',
        align_left: 'Left Align',
        align_center: 'Center Align',
        align_right: 'Right Align',
        align_justify: 'Justify',
        text_color: 'Text Color',
        background_color: 'Background Color',
        insertLink: 'Insert Link',
        removeLink: 'Remove Link',

        // pupups, forms, others...
        url: 'URL',
        text: 'Text',
        openInNewTab: 'Open in new tab',
        insert: 'Insert',
        altText: 'Alt Text',
        title: 'Title',
        remove: 'Remove',
      },
    })

  ]
})
export class SharedModule { }
