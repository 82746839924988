<div class="loader-container " *ngIf="loading">
    <div class="loader-logo"></div>
</div>
<div *ngIf="!loading" class="h-100">
    <div class="p-2 pb-3 bg-white rounded ">
        <div class="my-2 px-2 d-flex justify-content-between">
            <p class="h3"><strong>Superadmin Users</strong></p>
            <a class="btn btn-custom d-flex justify-content-center align-items-center btn-sm py-1 text-nowrap text-white"
                data-bs-toggle="modal" data-bs-target="#superadmin" data-bs-whatever="@getbootstrap"
                style="border-radius: 5px !important">
                <i class="fa fa-plus my-auto fs-6 me-1"></i> Add User</a>
        </div>
        <div class="my-2 px-2">
            <div class="search-box flex-grow-1 me-1">
                <div class="position-relative">
                    <input type="text" [(ngModel)]="search" (input)="reset()" class="form-control"
                        placeholder="Search User here..." />
                </div>
            </div>
        </div>

        <div class="mt-4 p-2 rounded bg-white">
            <div class="d-flex flex-column justify-content-center align-items-center py-2" *ngIf="found">
                <p class="h4"><strong>User not found</strong></p>
                <span class="d-flex justify-content-center mt-2">

                </span>
            </div>
            <table class="table table-hover table-fixed" *ngIf="!found">
                <!--Table head-->
                <thead>
                    <tr>
                        <th class="">Full Name</th>
                        <th class="">Email</th>
                        <!-- <th class="">Package</th> -->
                        <!-- <th class="">Words Remaining</th>
                    <th class="">Package Renewal Date</th> -->
                        <th class="">Status</th>
                        <th class="">Action</th>
                    </tr>
                </thead>
                <!--Table head-->

                <!--Table body-->
                <tbody>
                    <tr *ngFor="let u of brokers ; let i = index">
                        <td class="text-capitalize">
                            {{ u?.first_name }} {{ u?.last_name }}
                        </td>
                        <td>{{ u?.email }}</td>
                        <!-- <td>{{u?.package}}</td> -->
                        <!-- <td>Trial</td> -->

                        <td>
                            <span class="badge bg-success"
                                *ngIf="u?.status === 'active' else disabledcontent">Active</span>
                            <ng-template #disabledcontent>
                                <span class="badge bg-danger">Inactive</span>
                            </ng-template>
                        </td>
                        <td>
                            <button class="btn btn-warning btn-sm me-2 btn-icon d-inline-flex align-items-center"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Change Status"
                                (click)="change(u?._id, u?.status)">
                                <i class='bx bx-play-circle'></i> </button>
                            <button class="btn btn-danger btn-sm me-2 d-inline-flex align-items-center"
                                (click)="delete(u?._id)" data-toggle="tooltip" data-placement="top" title="Delete User">
                                <i class="bx bx-trash"> </i>
                            </button>
                        </td>
                    </tr>
                </tbody>

                <!--Table body-->
            </table>
            <!-- <div class="d-flex justify-content-center align-items-center text-center">
                <button class="btn btn-sm btn-custom text-center mt-2  px-3" (click)="showMore()"
                    *ngIf="userData.length < brokers.length">Show More</button>
            </div> -->
            <!--Table-->
        </div>
    </div>
</div>



<!-- Add superadmin -->
<div class="modal fade" id="superadmin" tabindex="-1" aria-labelledby="superadminModalLabel" aria-hidden="true"
    style="display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="superadminModalLabel">Add New User</h5>
                <button #closebutton type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form action="" [formGroup]="addBrokerForm" (ngSubmit)="addBroker()">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="first_name" class="form-label">First Name</label>
                                <input type="text" class="form-control" formControlName="first_name"
                                    placeholder="Enter First Name" [ngClass]="{
                                                        'is-invalid': submitted && f['first_name'].errors
                                                      }" />
                                <div *ngIf="submitted && f['first_name'].errors" class="invalid-feedback">
                                    <div *ngIf="f['first_name'].errors['required']">
                                        First Name is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group  ">
                                <label for="last_name " class="form-label">Last Name</label>
                                <input type="text" class="form-control" formControlName="last_name"
                                    placeholder="Enter Last Name" [ngClass]="{
                                        'is-invalid': submitted && f['last_name'].errors
                                      }" />
                                <div *ngIf="submitted && f['last_name'].errors" class="invalid-feedback">
                                    <div *ngIf="f['last_name'].errors['required']">
                                        Last Name is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="email" class="form-label">Email</label>
                                <input type="email" formControlName="email" class="form-control"
                                    placeholder="Enter Email"
                                    [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" />
                                <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                                    <div *ngIf="f['email'].errors['required']">
                                        Email address is required
                                    </div>
                                    <div *ngIf="f['email'].errors['email']">
                                        Email address is not valid
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="password" class="form-label">Password</label>
                                <input type="password" placeholder="Password" formControlName="password"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
                                <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                                    <div *ngIf="f['password'].errors['required']">
                                        Password is required
                                    </div>
                                    <div *ngIf="f['password'].errors['minlength']">
                                        Password must be at least 8 characters long.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" mt-3 d-flex justify-content-end">
                        <!-- <button class="mb-4 btn btn-custom position-relative w-50" [disabled]="btnload" type="submit"> -->

                        <div class="text-end">
                            <button #closebutton class="btn btn-custom d-inline-flex gap-1 " type="submit">
                                <i class="bx bx-user-plus my-auto fs-6"></i> Add User</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>