<div class="my-2">
    <p class="h3">Languages</p>
</div>

<div [formGroup]="preForm">
    <div class="row">
        <div class="col-md-6">
            <div class="">
                <label for="" class="form-label text-muted small">System Language</label>
                <select class="form-select form-select-sm" aria-label=".form-select-sm example" id="language_id"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="systemLanguage">
                    <option value="">Select a language</option>
                    <option selected>English </option>

                </select>
            </div>
            <div class="d-flex align-items-end">
                <p class="text-muted small">Set the default language for the system.</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="">
                <label for="" class="form-label text-muted small">Generation Language</label>
                <select class="form-select form-select-sm" aria-label=".form-select-sm example" id="language_id"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="language">
                    <option [selected]="true">Select a language</option>
                    <option *ngFor="let language of languages" [value]="language?.name">
                        {{ language?.name }}
                    </option>
                </select>
            </div>
            <div class="d-flex align-items-end">
                <p class="text-muted small">
                    Set the default language for output generation.
                </p>
            </div>
        </div>

        <div class="col-6 mb-3">
            <div class="">
                <label for="voiceSelect" class="form-label text-muted small">Heidi Accents</label>
                <select id="voiceSelect" class="form-select form-select-sm" aria-label=".form-select-sm example"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="accent_name">
                    <option *ngFor="let option of accents" [value]="option?.lang">
                        {{ option?.text }}
                        <span *ngIf="option?.isDefault">— DEFAULT</span>
                    </option>
                </select>
            </div>
        </div>
        <div class="col-6 mb-3">
            <div>
                <label for="languageSelector" class="form-label text-muted small">Voice Accents</label>
                <select id="languageSelector" class="form-select form-select-sm" aria-label=".form-select-sm"
                    [(ngModel)]="recognition" [ngModelOptions]="{standalone: true}">
                    <option [selected]="true">Select a language</option>
                    <option *ngFor="let language of recognitionLanguage" [value]="language?.code">
                        {{ language.name }}
                    </option>
                </select>
            </div>

        </div>
        <div class="text-end">
            <button class="btn btn-custom" (click)="updatePrefrences()">
                <i class="bx bx-edit"></i> Save Information
            </button>
        </div>
    </div>
</div>
<div class="loader-container" *ngIf="loading">
    <div class="loader-logo"></div>
</div>