import { Component, ViewChild } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-superadmin-user',
  templateUrl: './superadmin-user.component.html',
  styleUrls: ['./superadmin-user.component.scss']
})
export class SuperadminUserComponent {
  @ViewChild('closebutton') closebutton: any;
  search = '';
  users: any = [];
  id: any;
  p = 0;
  l = 200;
  total = 0;
  loaded = 0;
  found = false;
  ld = true;
  lm = true;
  pag: number = 1;


  addBrokerForm!: FormGroup;
  submitted = false;
  foundmember: boolean = false
  btnload = false;
  loading: boolean = false;
  first_name: any;
  last_name: any;
  email: any;
  password: any;
  brokers: any;



  get f(): { [key: string]: AbstractControl } {
    return this.addBrokerForm.controls;
  }
  constructor(private fb: FormBuilder, public auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.addBrokerForm = this.fb.group(
      {
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
      }
    );

    this.getUsers();
  }

  getUsers() {
    this.loading = false
    this.ld = true;
    this.lm = false;
    this.found = false;
    this.p++;
    // let q = '';
    // if (this.search) {
    //   q =
    //     'user/get?page=' + this.p + '&limit=' + this.l + '&action=list' + '&search=' + this.search;
    // } else {
    //   q = 'user/get?page=' + this.p + '&limit=' + this.l + '&action=list';
    // }
    this.auth.get(`user/get?search=${this.search || ''}&limit=${this.l}`).subscribe({
      next: (res) => {
        if (res.status == 200) {

          if (this.p == 1) {
            this.brokers = res.data.brokers
            this.loaded = parseInt(res.data.limit);
          } else {
            for (var i = 0; i < res.data.brokers.length; i++) {
              this.brokers.push(res.data.brokers[i]);
              this.loaded++;
            }
          }
          this.total = parseInt(res.data.total_count);
          this.ld = false;
          this.lm = true;
          if (this.loaded >= this.total) {
            this.lm = this.ld = false;
          }

          // this.loading = false;
        }
        // if (this.brokers.length > 0) {
        //   this.userData = this.brokers.slice(0, 5);
        // }
      },
      error: (err) => {
        this.lm = this.ld = false;
        this.found = true;
        // this.brokers = []
        // this.userData = []
      },
    });
  }

  addBroker() {

    this.btnload = true;
    this.submitted = true;
    let endpoint = 'user/add';
    let body = {
      first_name: this.addBrokerForm.value.first_name,
      last_name: this.addBrokerForm.value.last_name,
      email: this.addBrokerForm.value.email,
      password: this.addBrokerForm.value.password,
    };
    if (this.addBrokerForm.invalid) {
      this.btnload = false;
      this.foundmember = false

    } else {
      this.auth.post(endpoint, body).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            // this.members.push(res.data);
            this.reset()
            this.addBrokerForm.reset();
            this.closebutton.nativeElement.click();

            Swal.fire({
              icon: 'success',
              title: 'Success.',
              text: 'User added successfully',
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 2000,
            });

          }

          this.foundmember = true
          this.submitted = false;
          this.btnload = false;
        },
        error: (error: any) => {
          Swal.fire({
            icon: 'error',
            position: 'top',
            toast: true,
            title: 'Email is already exist.',
            showConfirmButton: false,
            timer: 5000,
          });
          this.submitted = false;
          this.btnload = false;
          // this.foundmember = this.brokers.length > 0 ? true : false;
          // this.foundmember = false;

          // this.addBrokerForm.reset();
          this.closebutton.nativeElement.click();
        },
      });
    }
  }
  change(id: any, status: any) {
    let aa = '';
    if (status == 'active') {
      aa = 'inactive';
    } else {
      aa = 'active';
    }
    let arr = {
      status: aa,
      action: 'change_status',
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to change the status?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Change Status!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.auth.patch('user/' + id, arr).subscribe({
          next: (res: any) => {
            if (res.status == 200) {
              this.reset();
              Swal.fire({
                icon: 'success',
                position: 'top',
                toast: true,
                title: 'Success',
                text: res.data.message,
                showConfirmButton: false,
                timer: 2500,
              });
            }
          },
          error: () => { },
        });
      }
    });
  }
  delete(ind: any) {
    let id = ind;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete the user? This cannot be undo.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
        this.auth.del('user/' + id, '').subscribe({
          next: (res: any) => {
            if (res.status == 200) {
              this.reset();
              Swal.fire({
                icon: 'success',
                position: 'top',
                toast: true,
                title: 'Success',
                text: res.data.message,
                showConfirmButton: false,
                timer: 2500,
              });
              this.loading = false;
            }
          },
          error: (err) => {
            this.loading = false;

          },
        });
      }
    });
  }

  reset() {
    this.found = false;
    this.p = 0;
    this.l = 50;
    this.total = 0;
    this.loaded = 0;
    this.loading = false;
    this.brokers = [];
    this.getUsers();
  }
}
