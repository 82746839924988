<div class="pb-5 chat-height mt-5 mt-lg-1" #scroll [scrollTop]="scroll.scrollHeight" *ngIf="chats.length > 0">
    <div class="" *ngFor="let chat of chats; let i = index">
        <div class="rounded-top  text-muted  px-md-2 px-1  d-flex flex-row-reverse gap-2 gap-lg-3 msg-color mb-3 mt-3 mt-lg-0"
            *ngIf="i % 2 == 0" style="width: 90%; float: right;">
            <div class="conversation-list d-flex">
                <div class="dropdown">
                    <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <i class="bx bx-dots-vertical-rounded fs-3"></i>
                    </a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item pointer" (click)="speech(chat?.message)">Speech
                            <span class="icon rounded-circle ms-2 my-auto">
                                <i class="bx bx-volume-full text-black me-1"></i>
                            </span>
                        </a>
                        <a class="dropdown-item pointer" (click)="copy(chat?.message)">Copy
                            <span class="icon rounded-circle ms-2 my-auto">
                                <i class="bx bx-copy text-black me-1"></i>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="ctext-wrap">
                    <div class="d-flex justify-content-end gap-2">
                        <p class=" mb-0 py-2 text-justify " style="white-space: pre-line !important; font-size: 0.9rem; font-weight: 600;
                                    font-size: 0.9rem; font-weight: 600;">
                            {{ chat?.message }}
                        </p>
                        <img src="{{this.chat.profilePic? this.auth.getapi() + this.chat.profilePic: profilePic}}"
                            alt="" class="avatar-logo rounded-circle bg-transparent img-fluid me-2 objFit"
                            style="height: 30px; width: 30px;" width="30px" />
                    </div>
                    <p class="chat-time small my-0 py-1 text-start">
                        {{ chat?.time }}
                    </p>
                </div>

            </div>
        </div>
        <div class="rounded-top my-3 text-muted   px-md-2 px-1 d-flex gap-2 gap-lg-3 msg-color my-2" *ngIf="i % 2 != 0"
            style="width: 90%;">
            <div class="conversation-list d-flex">
                <div class="stext-wrap ">
                    <div class="d-flex gap-2">
                        <img src={{responsePic}} alt="" class="img-fluid mx-0 px-0 pb-2" style="height: 30px;">
                        <div>
                            <div *ngIf="chat?.code">
                                <div *ngFor="let m of chat?.message, let i = index">
                                    <p class="mb-0  text-justify" *ngIf="i%2==0"
                                        style="white-space: pre-line !important; font-size: 0.9rem; font-weight: 600;">
                                        {{ m }}
                                    </p>
                                    <div *ngIf="i%2!=0" class="bg-code p-2 my-2">
                                        <code class="m-2 text-justify code">
                                        {{ m }}
                                        </code>
                                    </div>
                                </div>
                            </div>
                            <p class=" mb-0  text-justify f-w-600" *ngIf="!chat?.code"
                                style="white-space: pre-line !important; font-size: 0.9rem;">
                                {{ chat?.message }}
                            </p>
                        </div>
                    </div>
                    <div class="ml-30 search-links mt-3" *ngIf="chat?.links.length>0">
                        <h6 class="f-w-600 text-decoration-underline" style="color: #7267ef;">Suggestions:</h6>
                        <a target="_blank" [href]="google?.link" class="link my-1" title="{{ google.title }}"
                            *ngFor="let google of chat?.links">
                            <img class="link-favicon img-fluid"
                                [src]="'https://www.google.com/s2/favicons?domain=' + google?.displayLink">
                            <small class="ms-3 f-w-600">{{ google?.title }}</small>
                        </a>
                    </div>
                    <p class="small chat-time small my-0 py-2 text-end">
                        {{chat?.time}}
                    </p>
                </div>
                <div class="dropdown">
                    <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <i class="bx bx-dots-vertical-rounded fs-3"></i>
                    </a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item pointer" (click)="speech(chat?.message)">Speech
                            <span class="icon rounded-circle ms-2 my-auto">
                                <i class="bx bx-volume-full text-black me-1"></i>
                            </span>

                        </a>
                        <a class="dropdown-item pointer" (click)="copy(chat?.message)">Copy
                            <span class="icon rounded-circle ms-2 my-auto">
                                <i class="bx bx-copy text-black me-1"></i>
                            </span>
                        </a>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div class="d-inline-flex float-end justify-content-end w-100" *ngIf="this.chat.getLoader()">
        <div class="rounded-top float-end text-muted  px-md-2 px-1 ps-5 msg-color d-block p-l-50 gap-3 gap-lg-5 mb-3 ">
            <div class="conversation-list d-flex">
                <div class="ctext-wrap">
                    <div class="d-flex gap-2">
                        <p class=" mb-0 py-1 text-justify"
                            style="white-space: pre-line !important; font-size: 0.9rem; font-weight: 600;">
                            {{ this.chat.getMessage() }}
                        </p>
                        <img src="{{ profilePic }}" alt=""
                            class="avatar-logo rounded-circle bg-transparent img-fluid me-2 objFit"
                            style="height: 30px; width: 30px;" width="30px" />
                    </div>
                    <small class="chat-time small my-0 py-1 text-end">
                        {{ message_time }}
                    </small>

                </div>
            </div>
        </div>
    </div>
    <div class="text-blue bgResponse py-3 mx-3 pe-4 d-flex w-50" *ngIf="this.chat.getLoader()">
        <img src={{responsePic}} alt=""
            class="img-fluid ms-3 px-0 avatar-logo rounded-circle bg-transparent me-2 objFit"
            style="height: 30px; width: 30px;">
        <div class="d-flex gap-3  ps-2 py-3">
            <div class="spinner-grow spinner-grow-sm " role="status" aria-hidden="true">
                <span class="visually-hidden text-dark">Loading...</span>
            </div>
            <div class="spinner-grow spinner-grow-sm " role="status">
                <span class="visually-hidden text-dark">Loading...</span>
            </div>
            <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="visually-hidden text-dark">Loading...</span>
            </div>
        </div>
    </div>
    <app-chatbox [childData]="id"></app-chatbox>
</div>