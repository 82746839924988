



import { ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatService } from 'src/app/services/chat.service';

import Swal from 'sweetalert2';
interface Option {
  text: string;
  isDefault: boolean;
  lang: string;
  name: string;
}
@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})


export class PreferencesComponent {
  languages: any = [];
  language: any = "";
  accent_name: any;
  accents: any[] = [];
  sysLanguage: any = "English";
  id: any;
  isSidebarOpen: boolean = false;
  loading: boolean = false
  systemLanguage: any;
  recognition: any;
  preForm!: FormGroup;
  preFormsubmitted = false;
  get f(): { [key: string]: AbstractControl } {
    return this.preForm.controls;
  }

  recognitionLanguage = [
    { code: 'af', name: 'Afrikaans (af)' },
    { code: 'eu', name: 'Basque (eu)' },
    { code: 'bg', name: 'Bulgarian (bg)' },
    { code: 'ca', name: 'Catalan (ca)' },
    { code: 'ar-EG', name: 'Arabic (Egypt) (ar-EG)' },
    { code: 'ar-JO', name: 'Arabic (Jordan) (ar-JO)' },
    { code: 'ar-KW', name: 'Arabic (Kuwait) (ar-KW)' },
    { code: 'ar-LB', name: 'Arabic (Lebanon) (ar-LB)' },
    { code: 'ar-QA', name: 'Arabic (Qatar) (ar-QA)' },
    { code: 'ar-AE', name: 'Arabic (UAE) (ar-AE)' },
    { code: 'ar-MA', name: 'Arabic (Morocco) (ar-MA)' },
    { code: 'ar-IQ', name: 'Arabic (Iraq) (ar-IQ)' },
    { code: 'ar-DZ', name: 'Arabic (Algeria) (ar-DZ)' },
    { code: 'ar-BH', name: 'Arabic (Bahrain) (ar-BH)' },
    { code: 'ar-LY', name: 'Arabic (Lybia) (ar-LY)' },
    { code: 'ar-OM', name: 'Arabic (Oman) (ar-OM)' },
    { code: 'ar-SA', name: 'Arabic (Saudi Arabia) (ar-SA)' },
    { code: 'ar-TN', name: 'Arabic (Tunisia) (ar-TN)' },
    { code: 'ar-YE', name: 'Arabic (Yemen) (ar-YE)' },
    { code: 'cs', name: 'Czech (cs)' },
    { code: 'nl-NL', name: 'Dutch (nl-NL)' },
    { code: 'en-AU', name: 'English (Australia) (en-AU)' },
    { code: 'en-CA', name: 'English (Canada) (en-CA)' },
    { code: 'en-IN', name: 'English (India) (en-IN)' },
    { code: 'en-NZ', name: 'English (New Zealand) (en-NZ)' },
    { code: 'en-ZA', name: 'English (South Africa) (en-ZA)' },
    { code: 'en-GB', name: 'English (UK) (en-GB)' },
    { code: 'en-US', name: 'English (US) (en-US)' },
    { code: 'fi', name: 'Finnish (fi)' },
    { code: 'fr-FR', name: 'French (fr-FR)' },
    { code: 'gl', name: 'Galician (gl)' },
    { code: 'de-DE', name: 'German (de-DE)' },
    { code: 'he', name: 'Hebrew (he)' },
    { code: 'hu', name: 'Hungarian (hu)' },
    { code: 'is', name: 'Icelandic (is)' },
    { code: 'it-IT', name: 'Italian (it-IT)' },
    { code: 'id', name: 'Indonesian (id)' },
    { code: 'ja', name: 'Japanese (ja)' },
    { code: 'ko', name: 'Korean (ko)' },
    { code: 'la', name: 'Latin (la)' },
    { code: 'zh-CN', name: 'Mandarin Chinese (zh-CN)' },
    { code: 'zh-TW', name: 'Traditional Taiwan (zh-TW)' },
    { code: 'zh-HK', name: 'Simplified Hong Kong (zh-HK)' },
    { code: 'zh-yue', name: 'Yue Chinese (Traditional Hong Kong) (zh-yue)' },
    { code: 'ms-MY', name: 'Malaysian (ms-MY)' },
    { code: 'no-NO', name: 'Norwegian (no-NO)' },
    { code: 'pl', name: 'Polish (pl)' },
    { code: 'xx-piglatin', name: 'Pig Latin (xx-piglatin)' },
    { code: 'pt-PT', name: 'Portuguese (pt-PT)' },
    { code: 'pt-BR', name: 'Portuguese (Brasil) (pt-BR)' },
    { code: 'ro-RO', name: 'Romanian (ro-RO)' },
    { code: 'ru', name: 'Russian (ru)' },
    { code: 'sr-SP', name: 'Serbian (sr-SP)' },
    { code: 'sk', name: 'Slovak (sk)' },
    { code: 'es-AR', name: 'Spanish (Argentina) (es-AR)' },
    { code: 'es-BO', name: 'Spanish (Bolivia) (es-BO)' },
    { code: 'es-CL', name: 'Spanish (Chile) (es-CL)' },
    { code: 'es-CO', name: 'Spanish (Colombia) (es-CO)' },
    { code: 'es-CR', name: 'Spanish (Costa Rica) (es-CR)' },
    { code: 'es-DO', name: 'Spanish (Dominican Republic) (es-DO)' },
    { code: 'es-EC', name: 'Spanish (Ecuador) (es-EC)' },
    { code: 'es-SV', name: 'Spanish (El Salvador) (es-SV)' },
    { code: 'es-GT', name: 'Spanish (Guatemala) (es-GT)' },
    { code: 'es-HN', name: 'Spanish (Honduras) (es-HN)' },
    { code: 'es-MX', name: 'Spanish (Mexico) (es-MX)' },
    { code: 'es-NI', name: 'Spanish (Nicaragua) (es-NI)' },
    { code: 'es-PA', name: 'Spanish (Panama) (es-PA)' },
    { code: 'es-PY', name: 'Spanish (Paraguay) (es-PY)' },
    { code: 'es-PE', name: 'Spanish (Peru) (es-PE)' },
    { code: 'es-PR', name: 'Spanish (Puerto Rico) (es-PR)' },
    { code: 'es-ES', name: 'Spanish (Spain) (es-ES)' },
    { code: 'es-US', name: 'Spanish (US) (es-US)' },
    { code: 'es-UY', name: 'Spanish (Uruguay) (es-UY)' },
    { code: 'es-VE', name: 'Spanish (Venezuela) (es-VE)' },
    { code: 'sv-SE', name: 'Swedish (sv-SE)' },
    { code: 'tr', name: 'Turkish (tr)' },
    { code: 'zu', name: 'Zulu (zu)' }
  ];

  constructor(private auth: AuthService, public chat: ChatService, private cdr: ChangeDetectorRef, private fb: FormBuilder) {

    this.preForm = new FormGroup({
      language: new FormControl(),
      systemLanguage: new FormControl(),
      accent_name: new FormControl(),
      recognition: new FormControl(),
    });
    this.recognition = this.chat.recognition;

  }
  ngOnInit(): void {
    this.getLanguages();
    this.populateVoiceList();
    this.getDetail();

    this.preForm = this.fb.group({
      systemLanguage: [''],
      language: [''],
      accent_name: [''],
      recognition: [''],

    });


    if (
      typeof speechSynthesis !== 'undefined' &&
      speechSynthesis.onvoiceschanged !== undefined
    ) {
      speechSynthesis.onvoiceschanged = () => this.populateVoiceList();
    }

  }
  // changeLanguage() {
  //   // Update the selected language in the service
  //   this.chat.recognition = this.recognition;
  //   // Update the recognition language dynamically
  //   this.chat.recognition.lang = this.recognition;
  // }
  changeLanguage() {
    // Update the selected language in the service
    this.chat.recognition = this.recognition;
    // Update the recognition language dynamically
    this.chat.recognition.lang = this.recognition;
  }

  removeVendorName(voiceName: string): string {
    const vendorNamesToRemove = ["Google", "Microsoft"]; // Add other names if needed

    for (const name of vendorNamesToRemove) {
      const regex = new RegExp(name, "i");
      voiceName = voiceName.replace(regex, "").trim();
    }

    return voiceName;
  }

  populateVoiceList(): void {
    if (typeof speechSynthesis === 'undefined') {
      return;
    }
    this.accents = speechSynthesis.getVoices().map(voice => this.createOption(voice));
  }

  public createOption(voice: SpeechSynthesisVoice): Option {
    const cleanedVoiceName = this.removeVendorName(voice.name);
    const option: Option = {
      text: `${cleanedVoiceName} (${voice.lang})`,
      isDefault: voice.default,
      lang: voice.lang,
      name: voice.name
    };


    return option;
  }

  getLanguages() {
    this.auth.get('language').subscribe({
      next: (res) => {
        this.languages = res.data.data;
        this.language = res.data.data.language?.name;
        this.languages.sort((a: any, b: any) => a.name.localeCompare(b.name));
      },
      error: (err) => {
      },
    });
  }


  updatePrefrences() {
    this.loading = true;
    let body = {
      accent_name: this.accent_name,
      language: this.language,
      systemLanguage: this.systemLanguage,
      action: 'update-prefrence',
      recognition: this.recognition
    };
    this.auth.patch('user/' + this.id, body).subscribe({
      next: (res) => {
        Swal.fire({
          icon: 'success',
          title: 'Success.',
          text: "Preferences updated successfully",
          position: 'top',
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
        this.loading = false;
        this.reset();
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }
  getDetail() {
    this.auth.get('user?action=self').subscribe({
      next: (res) => {

        this.id = res.data.user?._id;
        this.accent_name = res.data.user?.accent
        this.language = res.data.user?.language;
        this.recognition = res.data.user?.recognition
        this.systemLanguage = res.data.user?.systemLanguage || 'English';
        // this.chat.getDetail()
      },
      error: (err) => {
      },
    });
  }

  reset() {
    this.getDetail();
  }

}

