<div class="loader-container" *ngIf="!loading">
    <div class="loader-logo"></div>
</div>
<div *ngIf="loading">
    <div class="px-2 pb-5 bg-white">
        <div class="row my-2">
            <p class="h3"><strong>Company Listing</strong></p>
        </div>

        <div class="my-2">
            <div class="d-flex flex-1 justify-content-between">
                <div class="search-box flex-grow-1 me-1">
                    <div class="position-relative">
                        <input type="text" [(ngModel)]="search" (input)="reset()" class="form-control"
                            placeholder="Search Company here...">
                    </div>
                </div>
                <a href="javascript:void(0)" class="btn btn-custom d-flex justify-content-center align-items-center"
                    style="border-radius: 8px !important;" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <i class="fa fa-plus me-1"></i> Add New Company</a>
            </div>
        </div>

        <div class="my-4 p-4 rounded bg-white shadow">
            <table class="table table-hover table-fixed">
                <!--Table head-->
                <thead>
                    <tr>
                        <th class="">Company Name</th>
                        <th class="">Company Email</th>
                        <th class="">Admin Name</th>
                        <th class="">Admin Email</th>
                        <th class="">Address</th>
                        <th class="">Status</th>
                        <th class="">Action</th>
                    </tr>
                </thead>
                <!--Table head-->

                <!--Table body-->
                <tbody>
                    <tr *ngFor="let u of companies; let i = index">
                        <td class="text-capitalize">
                            {{u?.name}}
                        </td>
                        <td>{{u?.email}}</td>
                        <td class="text-capitalize">
                            {{u?.user[0]?.first_name}} {{u?.user[0]?.last_name}}
                        </td>
                        <td>{{u?.user[0]?.email}}</td>
                        <td>{{u?.address}}</td>
                        <td>
                            <span class="badge bg-success">{{u?.status}}</span>
                        </td>
                        <td>
                            <button class="btn btn-danger btn-sm me-2" (click)="delete(u?._id)" data-toggle="tooltip"
                                data-placement="top" title="Delete User">
                                <i class="fa fa-trash"> </i>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <!--Table body-->
            </table>
            <!--Table-->
        </div>
    </div>
</div>


<!-- add company modal  -->
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Add Company</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="companyForm" (ngSubmit)="onSubmit()">
                    <p class="h5 fw-bold mb-3">Company Information</p>
                    <div class="mb-3 row">
                        <div class="form-group">
                            <label for="name" class="form-label">Company Name</label>
                            <input type="text" class="form-control" formControlName="name" placeholder=""
                                [ngClass]="{ 'is-invalid': submitted && f['name'].errors }" />
                            <div *ngIf="submitted && f['name'].errors" class="invalid-feedback">
                                <div *ngIf="f['name'].errors['required']">
                                    Company Name is required.
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="mb-3">
                        <div class="form-group">
                            <!-- hello  -->
                            <label for="" class="">Email</label>
                            <input type="email" formControlName="email" class="form-control" placeholder=""
                                [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" />
                            <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                                <div *ngIf="f['email'].errors['required']">
                                    Email address is required
                                </div>
                                <div *ngIf="f['email'].errors['email']">
                                    Email address is not valid
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <div class="form-group">
                            <label for="lname" class="">Contact Number</label>
                            <input type="text" class="form-control" formControlName="phone" placeholder=""
                                [ngClass]="{ 'is-invalid': submitted && f['phone'].errors }" />
                            <div *ngIf="submitted && f['phone'].errors" class="invalid-feedback">
                                <div *ngIf="f['phone'].errors['required']">
                                    Contact Information is required.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <div class="form-group">
                            <label for="lname" class="">Address</label>
                            <input type="text" class="form-control" formControlName="address" placeholder=""
                                [ngClass]="{ 'is-invalid': submitted && f['address'].errors }" />
                            <div *ngIf="submitted && f['address'].errors" class="invalid-feedback">
                                <div *ngIf="f['address'].errors['required']">
                                    Address is required.
                                </div>
                            </div>
                        </div>
                    </div>

                    <p class="h5 fw-bold mb-3">Admin Information</p>
                    <div class="mb-3 row">
                        <div class="form-group col-md-6">
                            <label for="fname" class="">First Name</label>
                            <input type="text" class="form-control" formControlName="first_name" placeholder=""
                                [ngClass]="{ 'is-invalid': submitted && f['first_name'].errors }" />
                            <div *ngIf="submitted && f['first_name'].errors" class="invalid-feedback">
                                <div *ngIf="f['first_name'].errors['required']">
                                    First Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="lname" class="">Last Name</label>
                            <input type="text" class="form-control" formControlName="last_name" placeholder=""
                                [ngClass]="{ 'is-invalid': submitted && f['last_name'].errors }" />
                            <div *ngIf="submitted && f['last_name'].errors" class="invalid-feedback">
                                <div *ngIf="f['last_name'].errors['required']">
                                    Last Name is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="form-group">
                            <!-- hello  -->
                            <label for="" class="">Admin Email</label>
                            <input type="email" formControlName="admin_email" class="form-control" placeholder=""
                                [ngClass]="{ 'is-invalid': submitted && f['admin_email'].errors }" />
                            <div *ngIf="submitted && f['admin_email'].errors" class="invalid-feedback">
                                <div *ngIf="f['admin_email'].errors['required']">
                                    Email address is required
                                </div>
                                <div *ngIf="f['admin_email'].errors['email']">
                                    Email address is not valid
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="form-group">
                            <label for="" class="">Password</label>
                            <input type="password" formControlName="password" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
                            <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                                <div *ngIf="f['password'].errors['required']">
                                    Password is required
                                </div>
                                <div *ngIf="f['password'].errors['minlength']">
                                    Password must be at least 8 characters long.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="form-group">
                            <label for="" class="">Confirm Password</label>
                            <input type="password" formControlName="conpassword" class="form-control" [ngClass]="{
                    'is-invalid': submitted && f['conpassword'].errors
                  }" />
                            <div *ngIf="submitted && f['conpassword'].errors" class="invalid-feedback">
                                <div *ngIf="f['conpassword'].errors['required']">
                                    Confirm Password is required
                                </div>
                                <div *ngIf="f['conpassword'].errors['minlength']">
                                    Password must be at least 8 characters long.
                                </div>
                                <div *ngIf="f['conpassword'].errors['confirmedValidator']">
                                    Password and Confirm Password must be match.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-custom w-25"
                            style="border-radius: 8px !important;">Save</button>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>