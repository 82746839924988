<!-- <app-header [title]="pageName"></app-header> -->
<div class="h-100" style="height: 100vh">
    <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="font-size-18">Projects</h4>
    </div>
    <div class="breadcrumbs mt-5 mt-md-2 pt-2">
        <!-- <span>History</span> &gt;-->
        <span class="pointer" (click)="navigate1()">Projects &gt; </span>
        <a class="bold">{{ current?.name }}</a>
    </div>
    <div class="d-flex justify-content-end">
        <div class="input-group mb-3 w-100 rounded">
            <span class="input-group-text" id="basic-addon1">
                <i class="bx bx-search"></i>
            </span>
            <input type="text" class="form-control" placeholder="Search file" aria-label="Search"
                aria-describedby="basic-addon1" />
            <i class="bx bx-file fs-3 border border-1 p-2 ms-1 rounded-end" (click)="navigate()"></i>
        </div>
    </div>

    <table class="table table-responsive table-striped mt-4" *ngIf="longText.length > 0; else notfound">
        <thead>
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Owner</th>
                <th scope="col">Type</th>
                <th scope="col">Status</th>
                <th scope="col" class="text-nowrap">Last edited</th>
                <th scope="col">Action</th>

            </tr>
        </thead>
        <tbody class="table-group-divider">
            <tr class="pointer" *ngFor="let lt of longText; let i = index">
                <!-- <i class="bx bxl-linkedin ml-2"></i> -->
                <!-- <td (click)="openLongText(lt)">{{ lt }}</td> -->
                <td (click)="openLongText(lt)">{{lt?.name}}</td>
                <td>me</td>
                <td>
                    {{ lt?.library[0]?.title }}
                    <ul class="mb-0">
                        <li class="custom-dot">
                            <a class="text-muted">{{ lt?.library[0]?.category[0]?.name }}</a>
                        </li>
                    </ul>
                </td>
                <td>
                    <span class="px-2 py-1 rounded-pill" style="background: #e1fdff">{{ lt?.output ? lt.output + '
                        Copies' : '1 Copy' }}</span>
                </td>
                <td>{{ lt?.updatedAt | date }}</td>
                <td>
                    <!-- <i class="bx bx-edit-alt me-2"></i> -->
                    <i class="bx bx-trash ms-2" (click)="deleteProjectDetail(lt?._id)"></i>
                </td>
            </tr>
        </tbody>
    </table>
    <ng-template #notfound>
        <div class="h-100 m-auto">
            <div class="text-center">
                <div class="avatar-sm mx-auto mt-5">
                    <img src="./assets/images/logo.png" alt="logo" class="img-fluid" />
                </div>
                <p claas="text-primary">You haven't generated any copies yet.</p>
                <button class="btn btn-custom w-25" type="button" (click)="navigate()">
                    <i class="bx bx-plus-medical-circle my-auto me-1"></i>Create Copy
                </button>
            </div>
        </div>
    </ng-template>
</div>