

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  email = '';

  loading = false;

  constructor(private router: Router, private auth: AuthService) { }

  ngOnInit(): void {
    if (this.auth.isLogin()) {
      if (this.auth.isSuperAdmin()) {
        this.router.navigate(['superadmin']);
      } else if (this.auth.isAdmin()) {
        this.router.navigate(['admin']);
      } else if (this.auth.isUser()) {
        this.router.navigate(['user']);
      }
    }
  }
  resetPassword() {
    this.loading = true;
    let body = {
      email: this.email
    }
    this.auth.post('forgetPassword', body).subscribe({
      next: (res) => {
        this.loading = false;
        Swal.fire({
          icon: 'success',
          title: 'Success.',
          text: 'Password reset email sent.',
          position: 'top',
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
      },
      error: (err) => {
        this.loading = false;
        Swal.fire({
          position: 'top',
          title: 'Failed',
          icon: 'error',
          text: 'Failed to send password reset email.',
          timer: 5000,
        });
      }
    })
  }
}
