<div class=" pb-2 border-bottom mx-4">
    <div class="d-flex justify-content-between mb-3">
        <h4 class=""><span class="border-bottom "> Current Subscription</span></h4>
        <button class="btn btn-danger btn-sm cursor-pointer" (click)="onUnsubscribe()" *ngIf="unsub">Unsubscribe</button>
    </div>
    <p class="fw-semibold mb-0 text-capitalize">
        Current Plan :
        <small class="text-muted">{{myplan?.title == "" ? "Choose your plan" : myplan?.title}}</small>
    </p>
    <p class="fw-semibold">
        Words :
        <small class="text-muted">{{ this.chat.getWords() }}</small>
    </p>
</div>

<div class="mx-4 ">
    <div class="row">
        <div class="col-12">
            <div class="bg-white pb-3 pt-4 px-2 rounded  border">
                <div class="d-flex justify-content-between align-items-center flex-lg-row flex-column px-2">
                    <div>
                        <p class="fw-bold m-0">Upgrade Your Account</p>
                    </div>
                    <h4 class="text-center color-animate mb-0">
                        Choose your plan & enjoy 7-days free trial and cancel when you want
                    </h4>

                    <div class="form-check form-switch d-flex justify-content-between">
                        <p class="form-label m-0" for="flexSwitchCheckChecked">Monthly</p>
                        <input class="form-check-input mx-1 mt-0" type="checkbox" role="switch"
                            id="flexSwitchCheckChecked" (change)="setDuration($event)" />
                        <p class="form-label m-0" for="flexSwitchCheckChecked">Annual</p>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
            </div>
            <div class=" mb-4">
                <div class="row">
                    <div *ngFor=" let plan of plansShown; let i = index" class="col-md-4 col-sm-6 col-12">
                        <div class="card plan-box border gap-1 my-2 my-lg-0"
                            [@slidePrevious]="iPlan < plansShown.length - 1" [@slideNext]="iPlan > 0"
                            style="height: 100% !important; " *ngIf="!yearlyPlansAvailable">
                            <div class="card-body py-4 gap-2 w-100 "
                                *ngIf="duration === 'monthly' && (plan?.duration === 'monthly' || plan?.duration === 'both')">
                                <div class="d-flex" *ngIf="plantype == 'supreme' && plan?.plan_type == 'supreme'">
                                    <div class="flex-grow-1 text-capitalize">
                                        <h4 class="text-center text-uppercase fw-600" [ngClass]="{ 'text-first': i == 0, 'text-second': i == 1, 'text-third': i == 2 }">{{ plan?.title }}</h4>
                                    </div>
                                </div>
                                <div class="py-4">
                                    <h4><sup><small>$</small></sup> {{
                                        plan?.monthly_amount
                                        ? plan.monthly_amount
                                        : (0 )
                                        }} <span class="font-size-13">/month</span></h4>
                                </div>
                                <div class="text-center plan-btn">
                                    <button class="btn btn-custom btn-lg py-2 small fw-semibold"
                                        (click)="createlink(plan)"
                                        *ngIf="(plan?.title != 'trial' && plan?.title != myplan?.title) || (plan?.title == myplan?.title && cancel)">
                                        Upgrade
                                    </button>
                                    <button class="btn btn-custom text-white btn-lg py-2 small fw-semibold"
                                        [disabled]="plan?.title == myplan?.title" *ngIf="plan?.title == myplan?.title && !cancel">
                                        Current
                                    </button>

                                </div>

                                <div class="plan-features mt-5">
                                    <div [innerHtml]="plan?.description"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class=" mb-4" *ngIf="duration === 'yearly' && yearlyPlansAvailable">
                    <div class="row">
                        <div *ngFor=" let plan of plansShown; let i = index;" class="col-md-4 col-sm-6 col-12">
                            <div class="card w-100 plan-box border gap-1 my-2 my-lg-0"
                                [@slidePrevious]="iPlan < plansShown.length - 1" [@slideNext]="iPlan > 0"
                                style="height: 100% !important; " *ngIf="yearlyPlansAvailable">
                                <div class="card-body py-4 gap-2 w-100 "
                                    *ngIf="duration != 'monthly' && (plan?.duration != 'monthly' || plan?.duration === 'both')">
                                    <div class="d-flex" *ngIf="plantype == 'supreme' && plan?.plan_type == 'supreme'">
                                        <div class="flex-grow-1 text-capitalize">
                                            <h4 class="text-center text-uppercase fw-600" [ngClass]="{ 'text-first': i == 0, 'text-second': i == 1, 'text-third': i == 2 }">{{ plan?.title }}</h4>
                                        </div>
                                    </div>
                                    <div class="py-4">
                                        <h4><sup><small>$</small></sup>
                                            {{plan?.yearly_amount? plan.yearly_amount: (0 )}}
                                            <span class="font-size-13">/yearly</span>
                                        </h4>
                                    </div>
                                    <div class="text-center plan-btn">
                                        <button class="btn btn-custom btn-lg py-2 small fw-semibold"
                                            (click)="createlink(plan)"
                                            *ngIf="plan?.title != 'trial' && plan?.title != myplan?.title">
                                            Upgrade
                                        </button>
                                        <button class="btn btn-custom text-white btn-lg py-2 small fw-semibold"
                                            [disabled]="plan?.title == myplan?.title"
                                            *ngIf="plan?.title == myplan?.title">
                                            Current
                                        </button>

                                    </div>

                                    <div class="plan-features mt-5">
                                        <div [innerHtml]="plan?.description"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>