<nav class="pc-sidebar light-sidebar" [class.open]="isSidebarOpen2">
	<div class="navbar-wrapper">
		<div class="m-header" *ngIf="this.auth.isUser() ">
			<a class="b-brand" routerLink="/user">
				<img src="/assets/images/logo1-trans.png" height="43px" alt="logo" class="logo logo-lg">
			</a>
		</div>
		<div class="m-header" *ngIf=" this.auth.isAdmin()">
			<a class="b-brand" routerLink="/admin">
				<img src="/assets/images/logo1-trans.png" height="43px" alt="logo" class="logo logo-lg">
			</a>
		</div>
		<div class="m-header" *ngIf="this.auth.isSuperAdmin()">
			<a class="b-brand" routerLink="/superadmin">
				<img src="/assets/images/logo1-trans.png" height="43px" alt="logo" class="logo logo-lg">
			</a>
		</div>
		<div class="navbar-content ">



			<div class="mx-2 mb-3">
				<button *ngIf="this.auth.isUser() || this.auth.isAdmin()"
					class="btn btn-custom w-100 d-flex justify-content-center" style="align-items: center"
					data-bs-toggle="modal" data-bs-target="#createNewproject">
					<i class="bx bx-plus-medical-circle me-1"></i>Create new
				</button>
			</div>
			<ul class="pc-navbar" *ngIf="this.auth.isUser()">
				<li class="pc-item pc-hasmenu mx-2">
					<div class="d-flex justify-content-between text-black w-100 pointer my-1 btn btn-transition"
						data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions"
						aria-controls="offcanvasWithBothOptions" *ngIf="projectfound">
						<a class="text-black text-center">{{selectedProject?.name}}</a>
						<i class="bx bx-right-arrow-alt mt-1"></i>
					</div>
				</li>
				<li class="pc-item pc-hasmenu" *ngIf="projectfound">
					<a routerLink="/user" class="pc-link">
						<span class="pc-micon">
							<i class="bx bx-collection"></i>
						</span>
						<span class="pc-mtext">Template</span>
					</a>
				</li>
				<li class="pc-item pc-hasmenu" *ngIf="projectfound">
					<a routerLink="/user/history" class="pc-link "><span class="pc-micon"><i
								class="bx bx-history"></i></span><span class="pc-mtext">History</span></a>
				</li>
				<li class="pc-item pc-hasmenu d-none">
					<a routerLink="/user/photochat" class="pc-link "><span class="pc-micon"><i
								class="bx bx-photo-album"></i></span><span class="pc-mtext">Photo</span></a>

				</li>
				<li class="pc-item pc-hasmenu d-none">
					<a routerLink="/user" class="pc-link "><span class="pc-micon"><i
								class="bx bx-cloud-upload"></i></span><span class="pc-mtext">Bulk Upload</span></a>

				</li>
				<li class="pc-item pc-hasmenu">
					<a routerLink="/user/heidi/chat" class="pc-link "><span class="pc-micon"><i
								class="bx bx-message-alt-dots"></i></span><span class="pc-mtext">Heidi Chat</span></a>

				</li>
				<li class="pc-item pc-hasmenu">
					<a routerLink="/user/settings" class="pc-link"><span class="pc-micon"><i
								class="bx bxs-cog"></i></span><span class="pc-mtext">Settings</span></a>
				</li>
				<li class="pc-item pc-hasmenu mt-5 pt-3 upgrade">
					<div class="mx-3 border-top mb-3">
						<div class="w-100 my-auto pt-3" *ngIf="this.auth.isAdmin() || this.auth.isUser()">
							<div class=" packageBox text-muted pt-2">
								<div class="d-flex justify-content-between">
									<div class="text">
										<p class="small text-capitalize">Plan : {{ plan?.type }}</p>
									</div>
									<div class="text">
										<p class="small text-capitalize">Quality : {{ plan?.plan_type }}</p>
									</div>
								</div>
								<div class="">
									<div class="text-center">
										<!-- <p>Words . {{ this.chat.getWords() | number}}</p> -->
										<p>Words . {{ this.chat.getWords() }}</p>


									</div>
								</div>
								<!-- <div class="px-0">
									<select class="form-select form-select-sm" aria-label=".form-select-sm example">
										<option value="1">Premium</option>
										<option value="2">Superior (GPT4)</option>
									</select>
								</div> -->
							</div>
						</div>

						<!-- <div class="mt-2 w-100" *ngIf="this.auth.isUser()">
							<a class="btn gradient-button w-100  py-2" role="button" routerLink="/user/settings">
								<i class="bx bxs-zap me-2"></i>Upgrade
							</a>
						</div> -->
						<div class="mt-2 w-100" *ngIf="this.auth.isAdmin()">
							<a class="btn gradient-button w-100 py-2" role="button" (click)="navigatetoSetting()">
								<i class="bx bxs-zap me-2"></i>Upgrade
							</a>
						</div>
					</div>
				</li>
			</ul>


			<ul class="pc-navbar" id="menu" *ngIf="this.auth.isSuperAdmin()">
				<li class="pc-item pc-hasmenu">
					<a routerLink="/superadmin/userlist" class="pc-link">
						<span class="pc-micon">
							<i class="bx bxs-user-detail"></i>
						</span>
						<span class="pc-mtext">Users</span>
					</a>
				</li>
				<li class="pc-item pc-hasmenu">
					<a routerLink="/superadmin/users" class="pc-link">
						<span class="pc-micon">
							<i class='bx bxs-group'></i>
						</span>
						<span class="pc-mtext">Superadmin Users</span>
					</a>
				</li>
				<li class="pc-item pc-hasmenu d-none">
					<a routerLink="/superadmin/companylist" class="pc-link">
						<span class="pc-micon">
							<i class="bx bxs-building-house"></i>
						</span>
						<span class="pc-mtext">Company</span>
					</a>
				</li>
				<li class="pc-item pc-hasmenu">
					<a routerLink="/superadmin/packages" class="pc-link">
						<span class="pc-micon">
							<i class="bx bx-dollar"></i>
						</span>
						<span class="pc-mtext">Packages</span>
					</a>
				</li>
				<li class="pc-item pc-hasmenu">
					<a routerLink="/superadmin/category" class="pc-link">
						<span class="pc-micon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
								viewBox="0 0 24 24" style="fill: rgb(100, 97, 97); ">
								<path
									d="M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM9 9H5V5h4v4zm11-6h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm-1 6h-4V5h4v4zm-9 4H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zm-1 6H5v-4h4v4zm8-6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z">
								</path>
							</svg></span>
						<span class="pc-mtext">Category</span>
					</a>
				</li>
				<li class="pc-item pc-hasmenu">
					<a routerLink="/superadmin/library" class="pc-link">
						<span class="pc-micon">
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
								style="fill: rgb(102, 98, 98);">
								<path
									d="M7 3h2v18H7zM4 3h2v18H4zm6 0h2v18h-2zm9.062 17.792-6.223-16.89 1.877-.692 6.223 16.89z">
								</path>
							</svg></span>
						<span class="pc-mtext">Library</span>
					</a>
				</li>
				<li class="pc-item pc-hasmenu">
					<a routerLink="/superadmin/field" class="pc-link">
						<span class="pc-micon">
							<i class='bx bx-food-menu'></i> </span>
						<span class="pc-mtext">Fields</span>
					</a>
				</li>
				<li class="pc-item pc-hasmenu">
					<a routerLink="/superadmin/template" class="pc-link">
						<span class="pc-micon">
							<i class='bx bx-book-content'></i> </span>
						<span class="pc-mtext">Template</span>
					</a>
				</li>
				<li class="pc-item pc-hasmenu">
					<a routerLink="/superadmin/settings" class="pc-link">
						<span class="pc-micon">
							<i class="bx bxs-cog"></i>
						</span>
						<span class="pc-mtext">Settings</span>
					</a>
				</li>
				<li class="pc-item pc-hasmenu">
					<a routerLink="/admin" class="pc-link" (click)="logout()">
						<span class="pc-micon">
							<i class="bx bx-power-off"></i>
						</span>
						<span class="pc-mtext">Logout</span>
					</a>
				</li>
			</ul>

			<ul class="pc-navbar" *ngIf="this.auth.isAdmin()">
				<li class="pc-item pc-hasmenu mx-2">
					<div class="d-flex justify-content-between text-black w-100 pointer my-1 btn btn-transition"
						data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions"
						aria-controls="offcanvasWithBothOptions" *ngIf="projectfound">
						<a class="text-black text-center text-capitalize">{{selectedProject?.name}}</a>
						<i class="bx bx-right-arrow-alt mt-1"></i>
					</div>


				</li>
				<li class="pc-item pc-hasmenu" *ngIf="projectfound">
					<a routerLink="/admin" class="pc-link">
						<span class="pc-micon">
							<i class="bx bx-collection"></i>
						</span>
						<span class="pc-mtext">Template</span>
					</a>
				</li>
				<li class="pc-item pc-hasmenu" *ngIf="projectfound">
					<a routerLink="/admin/history" class="pc-link "><span class="pc-micon"><i
								class="bx bx-history"></i></span><span class="pc-mtext">History</span></a>
				</li>
				<li class="pc-item pc-hasmenu d-none">
					<a routerLink="/admin/photochat" class="pc-link "><span class="pc-micon"><i
								class="bx bx-photo-album"></i></span><span class="pc-mtext">Photo</span></a>

				</li>
				<li class="pc-item pc-hasmenu d-none">
					<a routerLink="/admin" class="pc-link "><span class="pc-micon"><i
								class="bx bx-cloud-upload"></i></span><span class="pc-mtext">Bulk Upload</span></a>

				</li>
				<li class="pc-item pc-hasmenu">
					<a routerLink="/admin/heidi/chat" class="pc-link "><span class="pc-micon"><i
								class="bx bx-message-alt-dots"></i></span><span class="pc-mtext">Heidi Chat</span></a>

				</li>
				<li class="pc-item pc-hasmenu">
					<a routerLink="/admin/settings" class="pc-link"><span class="pc-micon"><i
								class="bx bxs-cog"></i></span><span class="pc-mtext">Settings</span></a>
				</li>
				<li class="pc-item pc-hasmenu mt-5 pt-3 upgrade ">
					<div class="mx-3 border-top mb-3">
						<div class="w-100 my-auto pt-3" *ngIf="this.auth.isAdmin()">

							<div class=" packageBox text-muted pt-2 bg-white">
								<div class="d-flex justify-content-between">
									<div class="text">
										<p class="small text-capitalize">Plan : {{ plan?.title }}</p>
									</div>
									<!-- <div class="text">
										<p class="small text-capitalize">Quality : {{ plan?.plan_type }}</p>
									</div> -->
								</div>
								<div class="">
									<div class="text-center">
										<p>Words . {{ this.chat.getWords() == "unlimited" ? "Unlimited" :
											this.chat.getWords() }}</p>

									</div>
								</div>
								<!-- <div class="px-0">
									<select class="form-select form-select-sm" aria-label=".form-select-sm example">
										<option value="1">Premium</option>
										<option value="2">Superior (GPT4)</option>
									</select>
								</div> -->
							</div>
						</div>

						<!-- <div class="mt-2 w-100" *ngIf="this.auth.isUser()">
							<a class="btn gradient-button w-100  py-2" role="button" routerLink="/user/settings">
								<i class="bx bxs-zap me-2"></i>Upgrade
							</a>
						</div> -->
						<div class="mt-2 w-100" *ngIf="this.auth.isAdmin()">
							<a class="btn gradient-button w-100  py-2" role="button" (click)="navigatetoSetting()">
								<i class="bx bxs-zap me-2"></i>Upgrade
							</a>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</nav>



<!-- create Modal -->
<div class="modal fade" id="createNewproject" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
	aria-labelledby="createNewLable" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="p-3 d-flex justify-content-between border-bottom">
				<h1 class="modal-title fs-5 bold" id="createNewLable">New Project</h1>
				<button type="button" class="btn-close" data-bs-dismiss="modal" #closebutton
					aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<form action="" [formGroup]="projectForm" (ngSubmit)="onProjectSubmit()">
					<label for="" class="form-label fw-semibold">Project name </label>
					<input type="text" class="form-control" formControlName="name"
						[ngClass]="{ 'is-invalid': submitted && f['name'].errors }" />
					<div *ngIf="submitted && f['name'].errors" class="invalid-feedback">
						<div *ngIf="f['name'].errors['required']">
							Project name is required.
						</div>
					</div>
					<div class="mt-3 d-flex justify-content-end">
						<button class="mb-4 btn btn-custom position-relative w-50" [disabled]="btnload" type="submit">
							<span class="btn-loader me-2" *ngIf="btnload"></span>
							<span>
								<i class="bx bx-save me-1"></i>
								Continue
							</span>
						</button>
						<!-- <button type="submit" class="btn btn-custom px-5">
              <i class="bx bx-save me-1"></i>Continue
            </button> -->
					</div>
				</form>
			</div>
		</div>
	</div>
</div>

<!-- Edit Modal -->
<div class="modal fade" id="editNew" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
	aria-labelledby="createNewLable" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="p-3 d-flex justify-content-between border-bottom">
				<h1 class="modal-title fs-5 bold" id="createNewLable">Edit Project</h1>
				<button type="button" class="btn-close" data-bs-dismiss="modal" #closebutton1
					aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<form action="" [formGroup]="editprojectForm" (ngSubmit)="onProjectEdit()">
					<label for="" class="form-label fw-semibold">Project name </label>
					<input type="text" class="form-control" formControlName="name"
						[ngClass]="{ 'is-invalid': submitted && f1['name'].errors }" />
					<div *ngIf="submitted && f1['name'].errors" class="invalid-feedback">
						<div *ngIf="f1['name'].errors['required']">
							Project name is required.
						</div>
					</div>
					<div class="p-3">
						<button class="btn btn-custom position-relative w-100" [disabled]="btnload" type="submit">
							<span class="btn-loader me-2" *ngIf="btnload"></span>
							<span>
								<i class="bx bx-save me-1"></i>
								Edit
							</span>
						</button>
						<!-- <button type="submit" class="btn btn-custom px-5">
              <i class="bx bx-save me-1"></i>Continue
            </button> -->
					</div>
				</form>
			</div>
		</div>
	</div>
</div>

<!--offcanvas-->

<div class="offcanvas offcanvas-start nested-sidebar" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions"
	aria-labelledby="offcanvasWithBothOptionsLabel">
	<div class="offcanvas-header d-flex justify-content-between align-items-center my-0"
		style="margin-top: -15px !important">
		<p data-bs-dismiss="offcanvas" aria-label="Close" class="border-0 border">
			<i class="bx bx-chevrons-left fs-6 mt-3"></i>
		</p>
		<span>
			<i class="bx bx-question-mark fs-6 border border-1 rounded-circle p-1" data-bs-toggle="tooltip"
				data-bs-placement="top" data-bs-custom-class="custom-tooltip"
				data-bs-title="Projects allow you to systematically organize your content."></i>
		</span>
	</div>
	<div class="offcanvas-body mt-0">
		<h5 class="offcanvas-title" id="offcanvasWithBothOptionsLabel">Projects</h5>
		<p>Projects allow you to systematically organize your content.</p>
		<input type="text" placeholder="Search" [(ngModel)]="search" (input)="reset()"
			class="form-control btn-custom-outline-border" />
		<a class="d-flex justify-content-between my-3 border py-2 px-3 rounded-4 gradient-border gradient_border"
			*ngFor="let p of projects; let i = index">
			<h5 class="mb-0 mt-1 text-capitalize pointer" data-bs-dismiss="offcanvas"
				*ngIf="!inputshow && projects.length>0" (click)="setProject(p)">
				{{ p?.name }}
			</h5>
			<input type="text" class="border-0" *ngIf="inputshow && selected == i" placeholder="Enter name"
				[(ngModel)]="name" (keydown.enter)="editName()" />
			<div class="mt-2">
				<span><i class="bx bx-edit-alt ml-2" (click)="editProject(p?._id, i)"></i></span>
				<span (click)="deleteProject(p?._id)"><i class="bx bx-trash ms-2"></i></span>
			</div>
		</a>
	</div>
</div>