import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatService } from 'src/app/services/chat.service';
import Swal from 'sweetalert2';
import { Editor, Toolbar } from 'ngx-editor';


@Component({
  selector: 'app-longtext',
  templateUrl: './longtext.component.html',
  styleUrls: ['./longtext.component.scss']
})
export class LongTextComponent implements OnInit, OnDestroy {


  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  longTextForm!: FormGroup;
  fieldForm!: FormGroup;
  validForm!: FormGroup;
  submitted = false;
  showHide = false;
  btnload = false;
  response = false;
  id: any;
  text: any
  longText: any[] = [];
  languages: any = [];
  libraryDetail: any;
  projectdetail: any;
  longtext_Id: any = '';
  open = false;
  fields: any
  filteredFields: any;
  promptaction: any;
  quality_type: any
  project_id: any
  library_id: any
  language_id: any
  output: any
  keywords: any;
  description: any;
  action: any;
  audience: any;
  subject: any
  scenario: any;
  wordcount: any;
  name: any;
  brand_info: any;
  brand_name: any;
  content: any
  search: any;
  category_id: any;
  categories: any;
  filteredLibrary: any[] = [];
  library: any[] = [];
  isSearchEmpty: boolean = true;
  noTempFound: boolean = false;
  disableBtn: boolean = false;
  notFound = false;
  cat_id: any;
  template_id: any;
  fieldsName: any
  tone: string = 'Formal';

  tons = [
    { name: "Approachable" },
    { name: "Excited" },
    { name: "Playful" },
    { name: "Assertive" },
    { name: "Formal" },
    { name: "Poetic" },
    { name: "Bold" },
    { name: "Friendly" },
    { name: "Positive" },
    { name: "Candid" },
    { name: "Funny" },
    { name: "Powerful" },
    { name: "Caring" },
    { name: "Gentle" },
    { name: "Professional" },
    { name: "Casual" },
    { name: "Helpful" },
    { name: "Quirky" },
    { name: "Cheerful" },
    { name: "Hopeful" },
    { name: "Reassuring" },
    { name: "Clear" },
    { name: "Humorous" },
    { name: "Reflective" },
    { name: "Commanding" },
    { name: "Informal" },
    { name: "Respectful" },
    { name: "Comprehensive" },
    { name: "Informative" },
    { name: "Romantic" },
    { name: "Confident" },
    { name: "Inspirational" },
    { name: "Sarcastic" },
    { name: "Conversational" },
    { name: "Inspiring" },
    { name: "Scientific" },
    { name: "Curious" },
    { name: "Lively" },
    { name: "Serious" },
    { name: "Detailed" },
    { name: "Melancholic" },
    { name: "Technical" },
    { name: "Educational" },
    { name: "Motivational" },
    { name: "Thought-provoking" },
    { name: "Eloquent" },
    { name: "Negative" },
    { name: "Thoughtful" },
    { name: "Emotional" },
    { name: "Neutral" },
    { name: "Uplifting" },
    { name: "Empathetic" },
    { name: "Nostalgic" },
    { name: "Urgent" },
    { name: "Empowering" },
    { name: "Offbeat" },
    { name: "Vibrant" },
    { name: "Encouraging" },
    { name: "Passionate" },
    { name: "Visionary" },
    { name: "Engaging" },
    { name: "Personal" },
    { name: "Witty" },
    { name: "Enthusiastic" },
    { name: "Persuasive" },
    { name: "Zealous" }
  ]


  constructor(
    private router: Router,
    public auth: AuthService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public chat: ChatService
  ) { }



  get f(): { [key: string]: AbstractControl } {
    return this.longTextForm.controls;
  }

  async ngOnInit(): Promise<void> {

    this.filteredLibrary = this.library;
    if (sessionStorage.getItem('longtext'))
      this.longtext_Id = JSON.parse(sessionStorage.getItem('longtext') || '');
    if (sessionStorage.getItem('project'))
      this.projectdetail = JSON.parse(sessionStorage.getItem('project') || '');

    this.route.params.subscribe(async (params) => {
      this.id = params['id'];
      this.getLanguages();
      this.getProjects()
      await this.getlibraryDetail();
      await this.getTemplateDetail();
      this.getlongTextDetail();
      this.getCategory()
      this.getLibraries();
      this.library_id = this.id;
      const formControlsConfig: any = {};
      this.fieldsName.forEach((fieldName: string | number) => {
        // formControlsConfig[fieldName] = [''];
        formControlsConfig[fieldName] = ['', Validators.required];

      });

      this.fieldForm = this.fb.group(formControlsConfig);
    });

  }
  hasError(fieldName: string, errorName: string): boolean {
    return this.fieldForm.get(fieldName)?.hasError(errorName) || false;
  }
  isRequiredField(fieldName: string): boolean {
    const requiredFields = [
      'description', 'company_name', 'company_description', 'housing_info', 'company_info', 'accommodation_info', 'context_description', 'Scenario', 'email_received',
    ];
    return requiredFields.includes(fieldName);
  }
  ngOnDestroy(): void {
    // this.editor.destroy();
  }
  getFields() {
    const fields = this.chat.getFields();
    this.filteredFields = {};
    Object.entries(fields).forEach(([key, value]) => {
      if (value) {
        this.filteredFields[key] = value;
      }
    });
  }

  getLanguages() {
    this.auth.get('language').subscribe({
      next: (res) => {
        this.languages = res.data.data;
        this.languages.sort((a: any, b: any) => a.name.localeCompare(b.name));
      },
      error: (err) => {
      },
    });
  }

  fiel(id: any) {
    this.isUpdating = false
    this.close()
    if (this.auth.isAdmin()) {
      this.router.navigate(['/admin/template', id])
    } else {
      this.router.navigate(['/user/template', id])
    }
    // this.auth.get('field/' + id).subscribe({
    //   next: (res) => {
    //     this.chat.setFields(res.data.data?.data)
    //     if (this.auth.isAdmin()) {
    //       this.router.navigate(['/admin/template', id])
    //     } else {
    //       this.router.navigate(['/user/template', id])
    //     }

    //     this.isUpdating = false;


    //   },
    //   error: (err) => {
    //     this.isUpdating = false;
    //   }
    // })
  }

  tempresponse: any[] = [];
  editorarray: any[] = [];
  async getlongTextDetail() {
    this.editorarray.push(new Editor());

    await new Promise((resolve) => {
      if (sessionStorage.getItem('longtext'))
        this.longtext_Id = JSON.parse(sessionStorage.getItem('longtext') || '');

      if (this.longtext_Id !== "") {
        this.auth.get('longtext/' + this.longtext_Id?._id).subscribe({
          next: (res) => {
            this.fieldForm.patchValue(res.data.data.data);
            this.name = res.data.data.name
            // this.longText = res.data.data?.response;
            // this.wordcount = res.data.data.wordcount;
            this.longtext_Id = res.data.data._id

            this.isUpdating = true;
            this.longText = [];
            this.tempresponse = [];
            this.editorarray = []
            for (let i = 0; i < res.data.data?.response.length; i++) {
              const response = res.data.data.response[i];
              this.longText.push(response);
              let aa = response?.ai_response.replace(/\n/g, "<br />");
              this.tempresponse.push(aa);
              this.editorarray.push(new Editor());
              this.response = true;
            }
            resolve(true);
          },
          error: (err) => {
            this.isUpdating = false;
            resolve(true)
          },
        });
      }
    });
  }
  async getCategory() {
    await new Promise((resolve) => {
      this.auth.get('category').subscribe({
        next: (res) => {
          this.categories = res.data.data;
          this.cat_id = this.categories[0]._id;
          resolve(true)
        },
        error: (err) => {
          resolve(true)
        },
      });
    })
  }

  getLibraries() {
    this.auth.get('library').subscribe({
      next: (res) => {
        this.library = res.data.data;
      },
      error: (err) => { },
    });
  }
  logo: any
  async getlibraryDetail() {
    await new Promise((resolve) => {
      this.auth.get('library/' + this.id).subscribe({
        next: (res) => {
          this.template_id = res.data.data[0].library?.template_id;
          this.libraryDetail = res.data.data[0].library?.template;
          this.logo = res.data.data[0].library?.logo;
          resolve(true)
        },
        error: (err) => {
          resolve(true)
        },
      });
    })
  }

  // dictionary: { [key: string]: [value: string] } = {};
  async getTemplateDetail() {
    if (!this.template_id) {
      // this.router.navigate(['/admin']);
      this.noTempFound = true;
      this.response = true
      return
    }
    await new Promise((resolve) => {
      this.auth.get('template/' + this.template_id).subscribe({
        next: (res) => {
          this.fieldsName = res.data.data.fields;
          // for (let i = 0; i < this.fieldsName.length; i++) {
          //   this.dictionary[this.fieldsName[i]] = this.fieldsName[i];
          // }
          resolve(true)
        },
        error: (err) => {
          resolve(true)
        },
      });

    })
  }
  formatLabel(text: string) {
    return text.replace(/_/g, ' ').trim()
  }

  reset() {
    this.getlongTextDetail();
  }
  closeBtn(): void {
    if (this.auth.isAdmin()) {
      this.router.navigate(['/admin']);
    } else {
      this.router.navigate(['/user']);
    }
    sessionStorage.removeItem('longtext');
    sessionStorage.clear()
  }

  close(): void {
    sessionStorage.removeItem('longtext');
    this.longText = [];
    this.reset()
    // sessionStorage.clear()
    this.response = false;
    this.noTempFound = false

  }

  openItemIndex: number = -1;

  select: any;
  toggleCollapse(index: number): void {
    this.select = index;
    this.openItemIndex = this.openItemIndex === index ? -1 : index;
    if (this.open) {
      this.open = false;
    } else {
      this.open = true;
    }
  }

  isItemOpen(index: number): boolean {
    return this.openItemIndex === index;
  }

  getProjects() {
    this.auth.get('project').subscribe({
      next: (res) => {
        this.project_id = res.data.data[0]._id;
      },
      error: (err) => {
      },
    });
  }
  isUpdating: boolean = false;

  sendData() {
    this.isUpdating = true;
    this.fieldForm.value.library_id = this.id;
    this.fieldForm.value.project_id = this.projectdetail?._id;
    // this.isUpdating = false;
    this.showHide = true;
    this.submitted = true;
    this.btnload = true;

    let endpoint = 'longtext';
    let body = {
      data: this.fieldForm.value,
      name: this.name,
      library_id: this.id,
      project_id: this.project_id,
      // action: this.promptaction,
    }

    if (this.fieldForm.invalid) {
      this.btnload = false;
      this.showHide = false;
      this.isUpdating = false;

    } else {
      this.auth.post(endpoint, body).subscribe({
        next: async (res: any) => {
          let temp: any
          if (res.status == 200) {
            this.longText = res.data.response;
            this.response = true;
            temp = res.data.longtext
            sessionStorage.setItem('longtext', JSON.stringify(temp));
            // this.reset();
          }
          // this.isUpdating = true;
          this.submitted = false;
          this.showHide = false;
          this.btnload = false;
          if (temp) {
            this.getlongTextDetail();
          }
        },
        error: (error: any) => {
          this.submitted = false;
          this.isUpdating = false;
          this.showHide = false;
          this.btnload = false;
          // this.isUpdating = false;
        },
      });
    }
  }
  updateData() {
    this.fieldForm.value.library_id = this.id;
    this.fieldForm.value.project_id = this.projectdetail?._id;
    this.showHide = true;
    this.submitted = true;
    this.btnload = true;
    let endpoint = 'longtext/' + this.longtext_Id;
    let body = {
      data: this.fieldForm.value,

      name: this.name,
      library_id: this.id,
      project_id: this.project_id,
      // action: this.promptaction,

    }
    if (this.fieldForm.invalid) {
      this.btnload = false;
      this.showHide = false;

    } else {

      this.isUpdating = true;
      this.auth.patch(endpoint, body).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.longText = res.data.response;
            this.response = true;
            this.getlongTextDetail();
          }
          this.submitted = false;
          this.showHide = false;
          this.btnload = false;
        },
        error: (error: any) => {
          this.submitted = false;
          this.showHide = false;
          this.btnload = false;
        },
      });
    }
  }

  searchLibrary() {
    if (this.search.trim() === '') {
      this.filteredLibrary = this.library;
      this.isSearchEmpty = true;
    } else {
      this.filteredLibrary = this.library.filter((item) =>
        item.name.toLowerCase().includes(this.search.toLowerCase()));
      this.isSearchEmpty = false;
    }
    this.getProjects();
  }
  getLabel(key: any): string {
    switch (key) {
      case 'language_id':
        return 'Language';
      case 'quality_type':
        return 'Quality';
      default:
        return key;
    }
  }

  async field() {
    await new Promise((resolve) => {
      this.auth.get('field/' + this.id).subscribe({
        next: (res) => {
          this.chat.setFields(res.data.data?.data)
          this.action = res.data.data.action;
          resolve(true)
        },
        error: (err) => {
          resolve(true)
        }
      })
    })

  }
  namecount = 0;
  countWords() {
    const inputText = this.longTextForm.value.product_name;
    this.namecount = inputText.length;
  }

  desccount = 0;
  countdesc() {
    const inputText = this.longTextForm.value.description;
    this.desccount = inputText.length;
  }
  keycount = 0;
  countkeys() {
    const inputText = this.longTextForm.value.keywords;
    this.keycount = inputText.length;
  }

  hide = false;
  selected: any;
  showButton(index: any): void {
    this.selected = index;
    this.hide = true;
  }

  showIcons(index: any): void {
    this.selected = index;
    this.hide = true;
  }

  hideButton(index: any): void {
    this.selected = index;
    this.hide = false;
  }

  copy(str: any) {
    navigator.clipboard.writeText(str).then(
      () => {
        Swal.fire({
          icon: 'success',
          text: 'Copied To Clipboard ',
          position: 'top',
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
        // this.closebutton.nativeElement.click();
      },
      (err) => {
        Swal.fire({
          icon: 'warning',
          text: 'Failed to copy to clipboard: ',
          position: 'top',
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    );
  }

  deleteLongText(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete the response? This cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.longtext_Id = JSON.parse(sessionStorage.getItem('longtext') || '{}');
        let body = {
          index: id
        }

        this.auth.del('longtext/detail/' + this.longtext_Id._id, body).subscribe({
          next: (res: any) => {

            if (res.status == 200) {
              this.reset();
              Swal.fire({
                icon: 'success',
                position: 'top',
                toast: true,
                title: 'Success',
                text: res.data.message,
                showConfirmButton: false,
                timer: 2500,
              });
            }
          },
          error: (err) => {
          },
        });
      }
    });
  }
}
