<!-- Team  -->
<div class="d-flex flex-column justify-content-center align-items-center py-2" *ngIf="!foundmember">
    <p class="h4"><strong>Your team is not available</strong></p>
    <span class="d-flex justify-content-center mt-2">
        <!-- Please upgrade your plan to be able to invite your teammates. -->
        <a class="link btn btn-custom btn-lg waves-effect waves-light text-center" data-bs-toggle="modal"
            data-bs-target=".bs-team-modal-center">
            <i class="bx bx-user-plus my-auto fs-6 me-1"></i>Add Member
        </a>
    </span>
</div>
<div *ngIf="foundmember">
    <div class="d-flex justify-content-end mb-3">
        <a class=" link">
            <a class="btn btn-custom btn-lg waves-effect waves-light" data-bs-toggle="modal"
                data-bs-target=".bs-team-modal-center">
                <i class="bx bx-user-plus my-auto fs-6 me-1"></i>Add Member</a>
        </a>
    </div>
    <table class="table mb-0">

        <thead class="table-light">
            <tr>
                <!-- <th>#</th> -->
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody *ngFor="let member of members let i = index">
            <tr>
                <!-- <th scope="row">1</th> -->
                <td>{{member?.first_name}}</td>
                <td>{{member?.last_name}}</td>
                <td>{{member?.email}}</td>
                <td><i class="bx bx-trash ms-2" (click)="deleteMember(member?._id)"></i></td>

            </tr>

        </tbody>
    </table>
</div>
<!-- Add Member -->
<div class="modal fade bs-team-modal-center" tabindex="-1" aria-hidden="true" style="display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add member</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    #closebutton></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="addMemberForm" (ngSubmit)="addMember()">
                    <div class="row">


                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="first_name" class="form-label">First Name</label>
                                <input type="text" class="form-control" formControlName="first_name"
                                    placeholder="Enter First Name" [ngClass]="{
                                                        'is-invalid': submitted && f['first_name'].errors
                                                      }" />
                                <div *ngIf="submitted && f['first_name'].errors" class="invalid-feedback">
                                    <div *ngIf="f['first_name'].errors['required']">
                                        First Name is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group  ">
                                <label for="last_name " class="form-label">Last Name</label>
                                <input type="text" class="form-control" formControlName="last_name"
                                    placeholder="Enter Last Name" [ngClass]="{
                                        'is-invalid': submitted && f['last_name'].errors
                                      }" />
                                <div *ngIf="submitted && f['last_name'].errors" class="invalid-feedback">
                                    <div *ngIf="f['last_name'].errors['required']">
                                        Last Name is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="email" class="form-label">Email</label>
                                <input type="email" formControlName="email" class="form-control"
                                    placeholder="Enter Email"
                                    [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" />
                                <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                                    <div *ngIf="f['email'].errors['required']">
                                        Email address is required
                                    </div>
                                    <div *ngIf="f['email'].errors['email']">
                                        Email address is not valid
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-6">
                                 <div class="form-group">
                                                <label for="password" class="form-label">Password</label>
                                                <input type="password" placeholder="Password" formControlName="password"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
                                                <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                                                    <div *ngIf="f['password'].errors['required']">
                                                        Password is required
                                                    </div>
                                                    <div *ngIf="f['password'].errors['minlength']">
                                                        Password must be at least 8 characters long.
                                                 </div>
                                     </div>
                                </div>
                        </div> -->
                    </div>

                    <div class=" mt-3 d-flex justify-content-end">
                        <!-- <button class="mb-4 btn btn-custom position-relative w-50" [disabled]="btnload" type="submit"> -->

                        <div class="text-end">
                            <button class="btn btn-custom d-inline-flex gap-1 " type="submit">
                                <i class="bx bx-user-plus my-auto fs-6"></i> Add Member</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>