

import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { ChatService } from './services/chat.service';
declare var google: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private auth: AuthService,
    private router: Router,
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private chat: ChatService
  ) { }

  ngOnInit(): void {
    this.setPageTitle();
    //this.chat.getDetail()

  }

  private setPageTitle(): void {
    const defaultPageTitle = 'Heidi';

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;

          if (!child) {
            return (
              this.activatedRoute.snapshot.data['title'] || defaultPageTitle
            );
          }

          while (child.firstChild) {
            child = child.firstChild;
          }

          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'] || defaultPageTitle;
          }
        })
      )
      .subscribe((title: string) =>
        this.title.setTitle(title + ' | Heidi - Best AI Tool')
      );

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url == '/user/heidichat') {
          this.auth.setBar(true);
          // setTimeout(() => {
          //   new google.translate.TranslateElement(
          //     { pageLanguage: 'en' },
          //     'google_translate_element2'
          //   );
          // }, 2000);
        } else {
          this.auth.setBar(false);

        }
      }
    });
  }
}
