<div class="loader-container" *ngIf="loading">
    <div class="loader-logo"></div>
</div>

<div class="container-fluid p-0 bg-white" [ngClass]="{ 'o-5': loading }">
    <div class="row g-0 align-items-center min-vh">
        <div class="col-xl-9">
            <div class="w-100">
                <img src="./assets/images/forgot.png" class="img-fluid h-100 maskimg w-75" style="object-fit: contain"
                    alt="" />
            </div>
        </div>
        <div class="col-xl-3 mt-5 mt-lg-0 bg-white">
            <div class="px-lg-5 px-4">
                <div class="w-100">
                    <div class="my-5">
                        <div class="">
                            <a class="d-block">
                                <img src="./assets/images/logo1-trans.png" class="img-fluid" />
                            </a>
                        </div>
                        <div class="my-auto">
                            <h5 class=" mb-1 text-pink-custom">Reset your password</h5>
                            <p class=" text-muted mb-3">
                                We’ll send you an email to reset your password.
                            </p>
                            <div class="mt-4">
                                    <div class="mb-3">
                                        <label for="email" class="form-label">Email</label>
                                        <input type="text" class="form-control" [(ngModel)]="email" id="email" placeholder="Enter email">
                                    </div>
                                    <div class="mt-4 d-grid">
                                        <button class="btn btn-custom w-100 btn-lg" type="button" (click)="resetPassword()">Reset</button>
                                    </div>
                                <div class="mt-3 text-center">
                                    <a routerLink="/login"
                                        class="fw-medium text-pink-custom text-hover pointer text-decoration-none">
                                        Back to login</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>