
import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatService } from 'src/app/services/chat.service';
declare var google: any;

@Component({
  selector: 'app-appheader',
  templateUrl: './appheader.component.html',
  styleUrls: ['./appheader.component.scss']
})
export class AppheaderComponent implements OnInit {
  profilePic: any;


  @ViewChild('closebutton') closebutton: any;
  constructor(
    private router: Router,
    public auth: AuthService,
    public chat: ChatService
  ) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.chat.googleTranslate();
      }
    });

  }


  ngOnInit(): void {
    setTimeout(() => {
      this.googleTranslate()
    }, 2000);
    this.getDataFromService();
    this.getDetail();

  }


  googleTranslate() {

    // this.chat.googleTranslate();
  }
  ngAfterViewChecked(): void {
    this.chat.getData()
  }


  getDataFromService(): void {
    const data = this.chat.getData();
  }
  onMenuButtonClick(): void {
    this.auth.toggleSidebarState();
  }

  logout() {
    this.auth.setLoginStatus(false);
    this.auth.logout();
    this.router.navigate(['/']);
  }

  tabactive(index: any) {
    this.auth.setTab(index);
  }

  navigate() {
    if (this.auth.getPromt()) {
      this.auth.setPromt(false);
      if (this.auth.isUser()) {
        this.router.navigate(['/user/heidi/chat']);
      } else {
        this.router.navigate(['/admin/heidi/chat']);
      }
    } else {
      this.auth.setPromt(true);
      if (this.auth.isUser()) {
        this.router.navigate(['/user/heidi/prompt']);
      } else {
        this.router.navigate(['/admin/heidi/prompt']);
      }
    }
  }

  speechTextToggle(e: any) {
    this.chat.setSpeechText(e.target.checked);
  }

  savePreference() {
    this.closebutton.nativeElement.click();
  }
  getDetail(): void {
    // const data = this.chat.getData();
    this.auth.get('user?action=self').subscribe({
      next: (res) => {
        this.profilePic = res.data.user?.picture


        // this.profilePic = res.data.user?.picture
        //   ? this.auth.getapi() + res.data.user.picture
        //   : this.profilePic;
      },
      error: (err) => { },
    });


  }
}
