import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';


import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {
  @ViewChild('inputfileforaddtrademark', { static: false })
  inputfileforaddtrademark!: ElementRef;


  addLibraryForm!: FormGroup;
  editlibraryForm!: FormGroup;
  submitted = false;
  foundLibrary: boolean = false
  btnload = false;
  loading: boolean = false;
  template: any;
  description: any;
  labraries: any;
  tag: any;
  id: any;
  category_id: any = "";
  cat_id: any;
  categories: any;
  search: string = '';
  searchLibrary: any[] = [];
  pag: number = 1;
  image: any;



  get f(): { [key: string]: AbstractControl } {
    return this.addLibraryForm.controls;
  }
  get f1(): { [key: string]: AbstractControl } {
    return this.editlibraryForm.controls;
  }
  @ViewChild('closebutton') closebutton: any;
  @ViewChild('editclosebutton') editclosebutton: any;

  constructor(
    private fb: FormBuilder,
    public auth: AuthService,
    private router: Router) { }
  async ngOnInit() {
    this.addLibraryForm = this.fb.group(
      {
        template: ['', Validators.required],
        description: ['', Validators.required],
        tag: [''],
        category_id: [''],
        file: ['']
      }
    );
    this.editlibraryForm = this.fb.group(
      {
        template: ['', Validators.required],
        description: ['', Validators.required],
        tag: [''],
        category_id: ['']
      });
    await this.getCategory();
    this.getLibrary();
  }
  addLibrary() {
    this.btnload = true;
    this.submitted = true;
    let endpoint = 'library';

    let body = {
      template: this.addLibraryForm.value.template,
      description: this.addLibraryForm.value.description,
      tag: this.addLibraryForm.value.tag,
      category_id: this.category_id
    };
    if (this.addLibraryForm.invalid) {
      this.btnload = false;
      this.foundLibrary = false

    } else {
      this.auth.post(endpoint, body).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.addLibraryForm.reset();
            this.closebutton.nativeElement.click();
            Swal.fire({
              icon: 'success',
              title: 'Success.',
              text: res.data.message,
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 2000,
            });
            this.getLibrary();
          }
          this.foundLibrary = true
          this.submitted = false;
          this.btnload = false;
        },
        error: (error: any) => {
          this.submitted = false;
          this.btnload = false;
          Swal.fire({
            icon: 'error',
            position: 'top',
            toast: true,
            title: error.data.message,
            showConfirmButton: false,
            timer: 5000,
          });
          this.addLibraryForm.reset();
          this.closebutton.nativeElement.click();
        },
      });
    }
  }

  onSearch() {
    if (!this.search.trim() && !this.category_id) {
      this.labraries = this.searchLibrary;
      this.foundLibrary = true;
    } else {
      let filteredLibraries = this.searchLibrary;

      if (this.search.trim()) {

        filteredLibraries = filteredLibraries.filter((library) =>
          library.template.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      if (this.category_id) {
        filteredLibraries = filteredLibraries.filter((library) =>
          library.category_id === this.category_id
        );
      }
      this.labraries = filteredLibraries;

      this.foundLibrary = this.labraries.length > 0;
    }
  }
  getLibrary() {
    this.auth.get('library/librarydetail/' + this.category_id).subscribe({
      next: (res) => {
        this.foundLibrary = true;
        this.loading = true;
        this.labraries = res.data.data;
        this.searchLibrary = this.labraries;
      },
      error: (err) => {
        this.foundLibrary = false
        this.loading = false

      },
    });
  }
  async getCategory() {
    await new Promise((resolve) => {
      this.auth.get('category?action=all').subscribe({
        next: (res) => {
          resolve(true)
          this.categories = res.data.data;
          this.category_id = this.categories[0]._id;
        },
        error: (err) => {
          resolve(true)
        },
      });
    })
  }
  onCategorySelected() {
    this.cat_id = this.category_id;
    this.getLibrary();
  }
  editLibrary(index: any) {
    this.editlibraryForm.patchValue(this.labraries[index])
    this.id = this.labraries[index]._id;
  }
  deleteLibrary(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete it?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.auth.del('library/' + id, '').subscribe({
          next: (res: any) => {
            if (res.status == 200) {
              this.labraries = this.labraries.filter((member: any) => member._id !== id);
              this.foundLibrary = this.labraries.length > 0 ? true : false;
              Swal.fire({
                icon: 'success',
                position: 'top',
                toast: true,
                title: 'Success',
                text: res.data.message,
                showConfirmButton: false,
                timer: 2500,
              });

            }
          },
          error: (err) => {
            this.foundLibrary = false
            Swal.fire({
              icon: 'error',
              position: 'top',
              toast: true,
              title: 'No Library Found',
              showConfirmButton: false,
              timer: 5000,
            });

          },
        });
      }
    });
  }

  file: any = [];
  onLogoChange(event: any) {
    this.file = event.target.files;
  }



  edit() {
    this.loading = true;
    let formData = new FormData();
    formData.append('template', this.editlibraryForm.value.template);
    formData.append('description', this.editlibraryForm.value.description);
    formData.append('tag', this.editlibraryForm.value.tag);
    formData.append('category_id', this.editlibraryForm.value.category_id);

    if ((this.file.length > 0) && this.file) {
      for (const file of this.file) {
        formData.append('file', file)
      }
    }


    this.auth.patch('library/' + this.id, formData).subscribe({
      next: (res) => {
        if (res.status == 200) {
          this.editlibraryForm.reset();
          this.inputfileforaddtrademark.nativeElement.value = "";
          this.editclosebutton.nativeElement.click();
          Swal.fire({
            icon: 'success',
            title: 'Success.',
            text: res.data.message,
            position: 'top',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
          });
          this.getLibrary();
        }
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }
}












