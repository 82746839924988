


import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatService } from 'src/app/services/chat.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  active_tab: any;
  show = false;
  showkey = false;
  session: any;
  cancel: any = false;
  fname: any;
  lname: any;
  name: any;
  address: any;
  phone: any;
  email: any;
  pageName = 'Settings';
  id: any;
  profilePic: any = '';
  loading = false;
  profileActive = true;

  constructor(
    public auth: AuthService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    public chat: ChatService
  ) { }

  ngAfterViewInit() {
    // Code to activate the "v-pills-bill" tab
    this.route.queryParams.subscribe(params => {
      let activeTab = params['tab']
      if (activeTab == 'v-pills-bill') {
        this.profileActive = false;
        const profileTab = document.getElementById('v-pills-profile-tab');
        if (profileTab) {
          this.renderer.removeClass(profileTab, 'active');
        }
        const billTab = document.getElementById('v-pills-bill-tab');
        if (billTab) {
          this.renderer.addClass(billTab, 'active');
          billTab.click();
        }
      }
    });
  }
  ngOnInit(): void {
    if (this.auth.getTab()) {
      this.active_tab = this.auth.getTab();
    } else {
      this.active_tab = 0;
    }

    this.route.queryParams.subscribe(params => {
      let activeTab = params['tab']
    });
    this.getDetail();


    this.route.queryParamMap.subscribe((params) => {
      // if (params.hasOwnProperty('account')) {
      //   this.accountValue = params['account'];
      // }
      this.session = params.get('account');
    })

    if (this.cancel == 'true') {
      this.cancel = true;
      this.cancelSession();
    } else if (this.session) {
      this.loading = true
      setTimeout(() => {
        this.updateSession();
      }, 7000);
      this.cancel = false;
    }
  }
  cancelSession() {
    let data = {
      invoice: this.session,
    };
    this.auth.del('payment/' + this.session, data).subscribe({
      next: (res: any) => { },
      error: (error: any) => {
        if (typeof error.error.data != 'undefined') {
          Swal.fire({
            icon: 'error',
            title: '',
            text: error.error.data.message,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: '',
            text: error.message,
          });
        }
      },
    });
  }

  updateSession() {
    const userdata = JSON.parse(localStorage.getItem('userdata') || '{}');
    this.id = userdata.id;
    let data = {
      accountcode: this.session
    };
    this.auth.patch('payment/' + this.id, data).subscribe({
      next: (res: any) => {
        // this.auth.validateToken();
        this.chat.setWords(res.data.plan?.words);
        this.loading = false
        Swal.fire({
          icon: 'success',
          title: 'Success.',
          text: res.data.message,
          position: 'top',
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
        this.auth.validateToken();
      },
      error: (error: any) => {
        this.loading = false
        Swal.fire({
          icon: 'error',
          position: 'top',
          toast: true,
          title: error.error.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      },
    });
  }

  active(index: any) {
    this.active_tab = index;
    // const navLinks = document.querySelectorAll('.link');
    // navLinks.forEach((link) => {
    //   link.addEventListener('click', (event: any) => {
    //     event.preventDefault();

    //     navLinks.forEach((link) => link.classList.remove('active'));
    //     link.classList.add('active');
    //     // window.location.href = this.href; // navigate to the link URL
    //   });
    // });
  }
  tabactive(index: any) {
    this.auth.setTab(index);
  }


  temp: any

  getDetail(): void {
    // const data = this.chat.getData();
    this.auth.get('user?action=self').subscribe({
      next: (res) => {

        this.fname = res.data.user?.first_name;
        this.lname = res.data.user?.last_name;
        this.name = this.fname + " " + this.lname;
        this.email = res.data.user?.email;
        this.phone = res.data.user.meta?.phone;
        this.id = res.data.user?._id;
        this.profilePic = res.data.user?.picture
          ? this.auth.getapi() + res.data.user.picture
          : this.profilePic;
      },
      error: (err) => { },
    });


  }

  onPicChange(event: any) {
    let q = '';
    if (event.target.files.length > 0) {
      var file = event.target.files[0];
      var filesize = 0;
      myNumber: filesize = Number(file.size);
      filesize = Number((filesize / 1024 / 1024).toFixed(4));
      if (filesize < 10) {
        this.readFile(file, (e: any) => {
          this.profilePic = e.target.result;
          var profileData = new FormData();
          profileData.append('file', file, file.name);
          profileData.append('action', 'profile');
          if (this.auth.isSuperAdmin()) {
            q = 'user/' + this.id;
          } else {
            q = 'user/' + this.id;
          }
          this.auth.patch(q, profileData).subscribe({
            next: (res: any) => {
              if (res.status == 200) {
                this.auth.validateToken();
                Swal.fire({
                  icon: 'success',
                  title: 'Success.',
                  text: res.data.message,
                  position: 'top',
                  toast: true,
                  showConfirmButton: false,
                  timer: 2000,
                });
                // this.chat.getData()
              }
            },
            error: () => { },
          });
        });
      } else {
        Swal.fire({
          title: 'Warning',
          text: 'File must be less than 10 MB.',
          icon: 'warning',
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  }
  readFile(file: any, onLoadCallback: any) {
    var reader = new FileReader();
    reader.onload = onLoadCallback;
    reader.readAsDataURL(file);
  }
  reset() {
    this.getDetail();
  }
}
