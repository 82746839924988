import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
declare var google: any;

@Component({
  selector: 'app-chatlayout',
  templateUrl: './chatlayout.component.html',
  styleUrls: ['./chatlayout.component.scss']
})
export class ChatlayoutComponent implements OnInit {
  constructor(public auth: AuthService) { }
  ngOnInit(): void {
    setTimeout(() => {
      let googleelement = new google.translate.TranslateElement(
        { pageLanguage: 'en' },
        'google_translate_element'
      );
    }, 2000);
  }

}
