<div class="loader-container " *ngIf="loading">
    <div class="loader-logo"></div>
</div>
<div *ngIf="!loading" class="h-100">
    <div class="p-2 pb-3 bg-white rounded ">
        <div class="row my-2 px-2">
            <p class="h3"><strong>User Listing</strong></p>
        </div>

        <div class="my-2 px-2">
            <div class="search-box flex-grow-1 me-1">
                <div class="position-relative">
                    <input type="text" [(ngModel)]="search" (input)="reset()" class="form-control"
                        placeholder="Search User here..." />
                </div>
            </div>
        </div>

        <div class="mt-4 p-2 rounded bg-white">
            <div class="d-flex flex-column justify-content-center align-items-center py-2" *ngIf="found">
                <p class="h4"><strong>User not found</strong></p>
                <span class="d-flex justify-content-center mt-2">

                </span>
            </div>
            <table class="table table-hover table-fixed" *ngIf="!found">
                <!--Table head-->
                <thead>
                    <tr>
                        <th class="">Full Name</th>
                        <th class="">Email</th>
                        <th class="">Package</th>
                        <!-- <th class="">Words Remaining</th>
                    <th class="">Package Renewal Date</th> -->
                        <th class="">Status</th>
                        <th class="">Action</th>
                    </tr>
                </thead>
                <!--Table head-->

                <!--Table body-->
                <tbody>
                    <tr *ngFor="let u of userData ; let i = index">
                        <td class="text-capitalize">
                            {{ u?.first_name }} {{ u?.last_name }}
                        </td>
                        <td>{{ u?.email }}</td>
                        <!-- <td>{{u?.package}}</td> -->
                        <td *ngFor="let plan of u?.plan; let i = index">{{plan?.title}}</td>

                        <td>
                            <span class="badge bg-success">{{ u?.status }}</span>
                        </td>
                        <td>
                            <button class="btn btn-danger btn-sm me-2" (click)="delete(u?._id)"
                                style="border-radius: 0.2rem !important" data-toggle="tooltip" data-placement="top"
                                title="Delete User">
                                <i class="fa fa-trash"> </i>
                            </button>
                        </td>
                    </tr>
                </tbody>

                <!--Table body-->
            </table>
            <div class="d-flex justify-content-center align-items-center text-center">
                <button class="btn btn-sm btn-custom text-center mt-2  px-3" (click)="showMore()"
                    *ngIf="userData.length < users.length">Show More</button>
            </div>
            <!--Table-->
        </div>
    </div>
</div>