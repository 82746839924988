import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { Customvalidators } from 'src/app/services/customvalidators.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-register-company',
  templateUrl: './register-company.component.html',
  styleUrls: ['./register-company.component.scss']
})
export class RegisterCompanyComponent {
  loading = false;
  search = '';
  companies: any = [];
  id: any;
  p = 0;
  l = 50;
  total = 0;
  loaded = 0;
  found = false;
  ld = true;
  lm = true;

  companyForm!: FormGroup;
  submitted = false;
  registershowhide = true;

  get f(): { [key: string]: AbstractControl } {
    return this.companyForm.controls;
  }

  constructor(private fb: FormBuilder, private auth: AuthService) { }

  ngOnInit(): void {
    this.companyForm = this.fb.group(
      {
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', Validators.required],
        address: ['', Validators.required],
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        admin_email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(8)]],
        conpassword: ['', [Validators.required, Validators.minLength(8)]],
      },
      { validator: Customvalidators('password', 'conpassword') }
    );

    this.getCompanies();
  }

  getCompanies() {
    this.ld = true;
    this.lm = false;
    this.found = false;
    this.p++;
    let q = '';
    if (this.search) {
      q =
        'company?page=' +
        this.p +
        '&limit=' +
        this.l +
        '&search=' +
        this.search;
    } else {
      q = 'company?page=' + this.p + '&limit=' + this.l;
    }
    this.auth.get(q).subscribe({
      next: (res) => {
        if (res.status == 200) {
          this.loading = true
          if (this.p == 1) {
            this.companies = res.data.data;
            this.loaded = parseInt(res.data.limit);
          } else {
            for (var i = 0; i < res.data.data.length; i++) {
              this.companies.push(res.data.data[i]);
              this.loaded++;
            }
          }
          this.total = parseInt(res.data.total_count);
          this.ld = false;
          this.lm = true;
          if (this.loaded >= this.total) {
            this.lm = this.ld = false;
          }
        }
      },
      error: (err) => {
        this.lm = this.ld = false;
        this.found = true;
        this.loading = true

      },
    });
  }

  onSubmit(): void {
    this.loading = true;
    this.registershowhide = true;
    this.submitted = true;
    if (this.companyForm.invalid) {
      this.loading = false;
      this.registershowhide = false;
    } else {
      this.auth.post('company', this.companyForm.value).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.companyForm.reset();
            Swal.fire({
              icon: 'success',
              title: 'Success.',
              text: 'Your company has been registered successfully.',
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 2000,
            });
            this.reset();
          }
          this.submitted = false;
          this.registershowhide = false;
          this.loading = false;
        },
        error: (error: any) => {
          this.submitted = false;
          this.registershowhide = false;
          this.loading = false;
          Swal.fire({
            icon: 'error',
            position: 'top',
            toast: true,
            title: error.error.data.message,
            showConfirmButton: false,
            timer: 5000,
          });
        },
      });
    }
  }



  reset() {
    this.found = false;
    this.p = 0;
    this.l = 50;
    this.total = 0;
    this.loaded = 0;
    this.loading = false;
    this.companies = [];
    this.getCompanies();
  }

  delete(ind: any) {
    let id = ind;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete the company? This cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
        this.auth.del('company/' + id, '').subscribe({
          next: (res: any) => {
            if (res.status == 200) {
              this.reset();
              Swal.fire({
                icon: 'success',
                position: 'top',
                toast: true,
                title: 'Success',
                text: res.data.message,
                showConfirmButton: false,
                timer: 2500,
              });
            }
          },
          error: (err) => {
          },
        });
      }
    });
  }
}
