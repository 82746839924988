
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject, lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';

const API_URL = environment.apiurl;

interface auth {
  status: any;
  data: any;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  plan:any
  ngOnInit() {
    this.toggleSidebarState()
    this.sidebarStateChange
  }

  isSidebarOpen = false;
  sidebarStateChange = new EventEmitter<boolean>();

  toggleSidebarState(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.sidebarStateChange.emit(this.isSidebarOpen);
  }

  getsidebarstate() {
    return this.isSidebarOpen;

  }

  private loginStatus = false;
  private role = '';
  private authtime = 0;
  private triggerFunction = new Subject<any>();


  constructor(private http: HttpClient) { }

  getapi() {
    return API_URL;
  }

  getToken() {
    if (localStorage.getItem('heidiauthtoken') != null) {
      return localStorage.getItem('heidiauthtoken');
    } else {
      return null;
    }
  }


  post(ep: any, q: any) {
    var token = this.getToken();
    if (token) {
      return this.http.post<auth>(API_URL + ep, q, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
    } else {
      return this.http.post<auth>(API_URL + ep, q);
    }
  }


  getTriggerFunction() {
    return this.triggerFunction.asObservable();
  }

  trigger() {
    this.triggerFunction.next(true);
  }

  get(ep: any) {
    var token = this.getToken();
    if (token) {
      return this.http.get<auth>(API_URL + ep, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
    } else {
      return this.http.get<auth>(API_URL + ep);
    }
  }

  patch(ep: any, q: any) {
    var token = this.getToken();
    if (token) {
      return this.http.patch<auth>(API_URL + ep, q, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
    } else {
      return this.http.patch<auth>(API_URL + ep, q);
    }
  }

  del(ep: any, q: any) {
    var token = this.getToken();
    if (token) {
      const options = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + token,
        }),
        body: q,
      };
      return this.http.delete<auth>(API_URL + ep, options);
    } else {
      const options = {
        headers: {},
        body: q,
      };
      return this.http.delete<auth>(API_URL + ep, options);
    }
  }

  validateToken() {
    const promise = new Promise((resolve) => {
      if (this.getToken()) {
        var token = this.getToken();
        lastValueFrom(
          this.http.get<auth>(API_URL + 'validate', {
            headers: { Authorization: 'Bearer ' + token },
          })
        ).then(
          (res: any) => {
            if (res.status == 200) {
              if (res.auth_token == null) {
                this.setLoginStatus(false);
                localStorage.clear();
                resolve(false);
              }
              localStorage.setItem('heidiauthtoken', res.auth_token);
              localStorage.setItem('userdata', JSON.stringify(res.data.info));
              this.setLoginStatus(true);
              this.setValidTime();
              var user = localStorage.getItem('userdata')
                ? localStorage.getItem('userdata')
                : '';
              var role = user ? JSON.parse(user).role_key : '';
              this.setuserRole(role);
              resolve(true);
            }
          },
          (err) => { }
        );
      } else {
        this.setLoginStatus(false);
        localStorage.clear();
        resolve(false);
      }
    });
    return promise;
  }

  setLoginStatus(v: boolean) {
    this.loginStatus = v;
  }

  isLogin() {
    return this.loginStatus;
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
  }

  setuserRole(r: string) {
    this.role = r;
  }

  userRole() {
    return this.role;
  }

  isSuperAdmin() {
    return this.role === 'superadmin';
  }

  isAdmin() {
    return this.role === 'admin';
  }

  isUser() {
    return this.role === 'user';
  }

  setValidTime() {
    this.authtime = Math.floor(Date.now() / 1000) + 100000;
  }

  getValidTIme() {
    return this.authtime <= Math.floor(Date.now() / 1000);
  }

  side: any;
  showSide() {
    let w = localStorage.getItem('side');
    if (w) {
      if (w == '0') {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  sonic = false;
  setBar(x: any) {
    if (x == true) {
      this.sonic = true;
    } else {
      this.sonic = false;
    }
  }

  getBar() {
    return this.sonic;
  }

  promt = false;
  setPromt(x: any) {
    if (x == true) {
      this.promt = true;
    } else {
      this.promt = false;
    }
  }

  getPromt() {
    return this.promt;
  }

  tabin: any;
  setTab(x: any) {
    this.tabin = x;
  }

  getTab() {
    return this.tabin;
  }

  public getIPAddress() {
    return this.http.get('http://api.ipify.org/?format=json');
  }

  projectId: any;
  setProjectId(w: any) {
    this.projectId = w;
  }

  getProjectId() {
    return this.projectId;
  }
}

// To save data
// sessionStorage.setItem('key', JSON.stringify(data));

// To retrieve data
// const savedData = JSON.parse(sessionStorage.getItem('key'));
// JSON.parse(localStorage.getItem('user') || '{}');
