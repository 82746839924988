<!-- <app-header [title]="pageName"></app-header> -->
<!-- <app-long-text-header></app-long-text-header> -->



<!-- start page title -->
<div class="row mobl-height">
    <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-4 font-size-18">History</h4>
        </div>
        <div class="page-title-left">
            <ol class="breadcrumb mb-2">
                <!-- <li class="breadcrumb-item"> -->
                <!-- <a routerLink="admin" class="text-dark">History</a> -->
                <!-- </li> -->
                <li class="breadcrumb-item active">Projects</li>
            </ol>
        </div>
    </div>
</div>
<!-- end page title -->





<div class="card">
    <div class="card-body">
        <div class="d-flex justify-content-end">
            <div class="input-group mb-3 w-100 rounded">
                <span class="input-group-text" id="basic-addon1">
                    <i class="bx bx-search"></i>
                </span>
                <input type="text" class="form-control w-50" placeholder="Search file for folder" aria-label="Search"
                    aria-describedby="basic-addon1" [(ngModel)]="search" (input)="reset()" />
                <i class="bx bx-folder-plus fs-5 border border-1 p-2 ms-1" data-bs-toggle="modal"
                    data-bs-target="#createnew"></i>

                <i class="bx bx-file fs-5 border border-1 p-2 rounded-end" (click)="navigate()"> </i>
            </div>
        </div>
        <table class="table table-responsive mt-4 table-striped">
            <thead class="border-0">
                <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Owner</th>
                    <th scope="col">Type</th>
                    <th scope="col">Status</th>
                    <th scope="col" class="text-nowrap">Last edited</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody class="table-group-divider">
                <tr class="pointer bg-light" *ngFor="let pr of projects; let i = index">
                    <td class="text-capitalize" *ngIf="!inputshow" (click)="nav(pr?._id)">
                        <i class="bx bx-folder-open me-2"></i> {{ pr?.name }}
                    </td>
                    <td *ngIf="inputshow && selected === i">
                        <i class="bx bx-folder-open me-2"></i>
                        <input type="text" class="border-blue rounded p-1" [(ngModel)]="name" />
                        <span class="p-2 ms-2 border-x rounded" (click)="updateName(pr?._id)"><i
                                class="bx bx-check"></i></span>
                    </td>
                    <td>Me</td>
                    <td></td>
                    <td></td>
                    <td>{{ pr?.updatedAt | date }}</td>

                    <td>
                        <i class="bx bx-edit-alt me-2" (click)="editProject(pr, i)"></i>
                        <!-- <button class="btn btn-danger ms-2" type="button"> -->
                        <i class="bx bx-trash ms-2" (click)="deleteProject(pr?._id)"></i>
                        <!-- </button> -->
                    </td>
                </tr>
            </tbody>
        </table>


    </div>
</div>



<!-- <div class="breadcrumbs mt-5 mt-md-2 pt-5">
    <span>History</span>
    &gt; <a href="" class="bold">Projects</a>
</div> -->



<!-- <div class="row">
    <div class="col-12">
        <div class="text-center my-3">
            <a href="javascript:void(0);" class="text-success"><i
                    class="bx bx-loader bx-spin font-size-18 align-middle me-2"></i> Load more </a>
        </div>
    </div> 
</div> -->
<!-- end row -->


<!-- create Modal -->
<div class="modal fade" id="createnew" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="createNewLable" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="p-3 d-flex justify-content-between border-bottom">
                <h1 class="modal-title fs-5 bold" id="createNewLable">New Project</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" #closebutton
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form action="" [formGroup]="projectForm" (ngSubmit)="onProjectSubmit()">
                    <label for="" class="form-label fw-semibold">Project name </label>
                    <input type="text" class="form-control" formControlName="name"
                        [ngClass]="{ 'is-invalid': submitted && f['name'].errors }" />
                    <div *ngIf="submitted && f['name'].errors" class="invalid-feedback">
                        <div *ngIf="f['name'].errors['required']">
                            Project name is required.
                        </div>
                    </div>
                    <div class="mt-3 d-flex justify-content-end">
                        <button class="mb-4 btn btn-custom position-relative w-50" [disabled]="btnload" type="submit">
                            <span class="btn-loader me-2" *ngIf="btnload"></span>
                            <span>
                                <i class="bx bx-save me-1"></i>
                                Continue
                            </span>
                        </button>
                        <!-- <button type="submit" class="btn btn-custom px-5">
                <i class="bx bx-save me-1"></i>Continue
              </button> -->
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>