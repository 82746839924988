

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatService } from 'src/app/services/chat.service';
declare var google: any;

@Component({
  selector: 'app-chatheader',
  templateUrl: './chatheader.component.html',
  styleUrls: ['./chatheader.component.scss'],
})
export class ChatHeaderComponent implements OnInit {
  profilePic: any;
  selectedLanguage: string = 'en'; // Default language


  @ViewChild('closebutton') closebutton: any;
  constructor(
    private router: Router,
    public auth: AuthService,
    public chat: ChatService
  ) { }

  ngafterViewInit() {
    this.getDataFromService();
  }



  // ngOnInit(): void {
  //   this.getDataFromService();
  //   this.getDetail();
  // }
  ngOnInit(): void {

    this.getDataFromService();
    this.getDetail();
    // setTimeout(() => {
    //   this.googleTranslate()
    // }, 2000);

  }


  googleTranslate() {
    // document.getElementById("divfortranslate")!.innerHTML = '<div id="google_translate_element"></div>';

    // new google.translate.TranslateElement(
    //   { pageLanguage: 'en' },
    //   'google_translate_element'
    // );
    // this.chat.googleTranslate();

  }
  onMenuButtonClick(): void {
    this.auth.toggleSidebarState();
  }

  getDataFromService(): void {
    const data = this.chat.getData();
  }

  logout() {
    this.auth.setLoginStatus(false);
    this.auth.logout();
    this.router.navigate(['/']);
  }

  tabactive(index: any) {
    this.auth.setTab(index);
  }

  navigate() {
    if (this.auth.getPromt()) {
      this.auth.setPromt(false);
      if (this.auth.isUser()) {
        this.router.navigate(['/user/heidi/chat']);
      } else {
        this.router.navigate(['/admin/heidi/chat']);
      }
    } else {
      this.auth.setPromt(true);
      if (this.auth.isUser()) {
        this.router.navigate(['/user/heidi/prompt']);
      } else {
        this.router.navigate(['/admin/heidi/prompt']);
      }
    }
  }

  speechTextToggle(e: any) {
    this.chat.setSpeechText(e.target.checked);
  }

  savePreference() {
    this.closebutton.nativeElement.click();
  }
  getDetail(): void {
    this.auth.get('user?action=self').subscribe({
      next: (res) => {
        this.profilePic = res.data.user?.picture
      },
      error: (err) => { },
    });
  }
}
