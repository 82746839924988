import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SuperadminRoutingModule } from './superadmin-routing.module';
import { UserlistComponent } from './userlist/userlist.component';
import { RegisterCompanyComponent } from './register-company/register-company.component';
import { PackagesComponent } from './packages/packages.component';

import { ReactiveFormsModule } from '@angular/forms';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular/ckeditor.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CategoryComponent } from './category/category.component';
import { LibraryComponent } from './library/library.component';
import { FieldsComponent } from './fields/fields.component';
import { TemplateComponent } from './template/template.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { SuperadminUserComponent } from './superadmin-user/superadmin-user.component';


@NgModule({
  declarations: [
    UserlistComponent,
    RegisterCompanyComponent,
    PackagesComponent,
    CategoryComponent,
    LibraryComponent,
    FieldsComponent,
    TemplateComponent,
    SuperadminUserComponent,


  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    CommonModule,
    SuperadminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    NgSelectModule,


  ]
})
export class SuperadminModule { }
