<div class="loader-container" *ngIf="!loading">
    <div class="loader-logo"></div>
</div>
<div *ngIf="loading">
    <div class="p-2 pb-3 bg-white rounded">
        <div class="row my-2 px-2">
            <p class="h3"><strong>Packages</strong></p>
        </div>

        <div class="my-2 px-2">
            <div class="d-flex flex-1 justify-content-between">
                <div class="search-box flex-grow-1 me-1">
                    <div class="position-relative">
                        <input type="text" [(ngModel)]="search" (input)="reset()" class="form-control"
                            placeholder="Search package here..." />
                    </div>
                </div>
                <!-- <a href="javascript:void(0)"
                    class="btn btn-custom d-flex justify-content-center align-items-center btn-sm py-1 text-nowrap"
                    style="border-radius: 5px !important" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <i class="fa fa-plus me-1"></i> Add New Package</a> -->
            </div>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center py-2 my-5" *ngIf="!foundPackage">
            <p class="h4"><strong>Plan not found</strong></p>
            <span class="d-flex justify-content-center mt-2">

            </span>
        </div>
        <div class="my-4 p-2 rounded bg-white " *ngIf="foundPackage">
            <table class="table table-hover table-fixed">
                <!--Table head-->
                <thead>
                    <tr>
                        <th class="">Package Name</th>
                        <th class="">Words</th>
                        <th class="">Project</th>
                        <th class="">Generational Text</th>
                        <th class="">Monthly Amount</th>
                        <th class="">Yearly Amount</th>
                        <!-- <th class="">Monthly Discount</th>
                        <th class="">Yearly Discount</th> -->
                        <th class="">Status</th>
                        <th class="">Action</th>
                    </tr>
                </thead>
                <!--Table head-->

                <!--Table body-->
                <tbody>
                    <tr *ngFor="let u of plans; let i = index">
                        <td class="text-capitalize">{{ u?.title }}</td>
                        <td>{{ isValidNumber(u.words) ? (u.words| number) : "Unlimited" }}</td>
                        <td>{{ u?.projects ? u.projects : '--'}}</td>
                        <td>{{ isValidNumber(u.generated_text) ? (u.generated_text| number) : '--' }}</td>
                        <td>{{ u?.monthly_amount | number }} </td>
                        <td class="text-capitalize">{{ u?.yearly_amount | number }}</td>
                        <td><span class="badge bg-success"
                                *ngIf="u?.status === 'active' else disabledcontent">Active</span>
                            <ng-template #disabledcontent>
                                <span class="badge bg-danger">Inactive</span>
                            </ng-template>
                        </td>
                        <td>
                            <button *ngIf="u?.status === 'inactive'"
                                class="btn btn-outline-success btn-sm me-2 btn-icon" data-bs-toggle="tooltip"
                                data-bs-placement="top" title="Change Status" (click)="change(u?._id, u?.status)">
                                <i class="fa fa-play"></i></button>

                            <button *ngIf="u?.status === 'active'" class="btn btn-outline-warning btn-sm me-2 btn-icon"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Change Status"
                                (click)="change(u?._id, u?.status)">
                                <i class="fa fa-pause"></i>
                            </button>
                            <button class="btn btn-outline-info btn-sm me-2 btn-icon" data-toggle="tooltip"
                                data-placement="top" data-backdrop="static" data-keyboard="false" data-bs-toggle="modal"
                                data-bs-target="#exampleModal1" (click)="editPackage(i)" title="Edit Package">
                                <i class="fa fa-edit"> </i>
                            </button>
                            <button class="btn btn-outline-danger btn-sm me-2 btn-icon" data-toggle="tooltip"
                                data-placement="top" title="Delete package" (click)="delete(u?._id)">
                                <i class="fa fa-trash"> </i>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <!--Table body-->
            </table>
            <!--Table-->
        </div>
    </div>
</div>

<!-- add company modal  -->
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Add Package</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" #closebutton
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="packageform" (ngSubmit)="onsubmitform()">
                    <div class="row">
                        <div class="col-12">
                            <div class="d-flex justify-content-end align-items-lg-baseline">
                                <div class="form-check form-switch">
                                    <input class="form-check-input me-2" type="checkbox" role="switch"
                                        id="flexSwitchCheckChecked" (change)="lifetime($event)" />
                                    <label class="form-label smaller" for="flexSwitchCheckChecked">Life time</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="!islife">
                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Package Name</label>
                                <input [ngClass]="{
                      'is-invalid': submittedform && f['title'].errors
                    }" formControlName="title" type="text" class="form-control" placeholder="title here" />
                                <div *ngIf="submittedform && f['title'].errors" class="invalid-feedback">
                                    <div *ngIf="f['title'].errors['required']">
                                        Package Name is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Words</label>
                                <input [ngClass]="{
                      'is-invalid': submittedform && f['words'].errors
                    }" formControlName="words" type="text" class="form-control" placeholder="Words here" />
                                <div *ngIf="submittedform && f['words'].errors" class="invalid-feedback">
                                    <div *ngIf="f['words'].errors['required']">
                                        Package words is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Monthly Amount</label>
                                <input formControlName="monthly_amount" type="text" class="form-control"
                                    placeholder="monthly amount here" [ngClass]="{
                      'is-invalid': submittedform && f['monthly_amount'].errors
                    }" />
                                <div *ngIf="submittedform && f['monthly_amount'].errors" class="invalid-feedback">
                                    <div *ngIf="f['monthly_amount'].errors['required']">
                                        Package amount is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Yearly Amount</label>
                                <input formControlName="yearly_amount" type="text" class="form-control"
                                    placeholder="yearly amount here" [ngClass]="{
                      'is-invalid': submittedform && f['yearly_amount'].errors
                    }" />
                                <div *ngIf="submittedform && f['yearly_amount'].errors" class="invalid-feedback">
                                    <div *ngIf="f['yearly_amount'].errors['required']">
                                        Package amount is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Monthly Discount</label>
                                <input formControlName="monthly_discount" type="text" class="form-control"
                                    placeholder="monthly discount here" [ngClass]="{
                      'is-invalid': submittedform && f['monthly_discount'].errors
                    }" />
                                <div *ngIf="submittedform && f['monthly_discount'].errors" class="invalid-feedback">
                                    <div *ngIf="f['monthly_discount'].errors['required']">
                                        Package amount is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Yearly Discount</label>
                                <input formControlName="yearly_discount" type="text" class="form-control"
                                    placeholder="yearly discount here" [ngClass]="{
                      'is-invalid': submittedform && f['yearly_discount'].errors
                    }" />
                                <div *ngIf="submittedform && f['yearly_discount'].errors" class="invalid-feedback">
                                    <div *ngIf="f['yearly_discount'].errors['required']">
                                        Package amount is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Monthly Discount Start Date</label>
                                <input formControlName="monthly_discount_start_date" type="date" class="form-control"
                                    placeholder="monthly discount start date here" [ngClass]="{
                      'is-invalid':
                        submittedform && f['monthly_discount_start_date'].errors
                    }" />
                                <div *ngIf="
                      submittedform && f['monthly_discount_start_date'].errors
                    " class="invalid-feedback">
                                    <div *ngIf="f['monthly_discount_start_date'].errors['required']">
                                        Package amount is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Monthly Discount End Date</label>
                                <input formControlName="monthly_discount_end_date" type="date" class="form-control"
                                    placeholder="yearly discount end date" [ngClass]="{
                      'is-invalid':
                        submittedform && f['monthly_discount_end_date'].errors
                    }" />
                                <div *ngIf="submittedform && f['monthly_discount_end_date'].errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f['monthly_discount_end_date'].errors['required']">
                                        Package amount is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Yearly Discount Start Date</label>
                                <input formControlName="yearly_discount_start_date" type="date" class="form-control"
                                    placeholder="yearly discount start date here" [ngClass]="{
                      'is-invalid':
                        submittedform && f['yearly_discount_start_date'].errors
                    }" />
                                <div *ngIf="
                      submittedform && f['yearly_discount_start_date'].errors
                    " class="invalid-feedback">
                                    <div *ngIf="f['yearly_discount_start_date'].errors['required']">
                                        Package amount is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Yearly Discount End Date</label>
                                <input formControlName="yearly_discount_end_date" type="date" class="form-control"
                                    placeholder="yearly discount end date" [ngClass]="{
                      'is-invalid':
                        submittedform && f['yearly_discount_end_date'].errors
                    }" />
                                <div *ngIf="submittedform && f['yearly_discount_end_date'].errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f['yearly_discount_end_date'].errors['required']">
                                        Package amount is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="mb-3">
                                <label>Description</label>
                                <ckeditor [editor]="Editor" [(ngModel)]="desc" [ngModelOptions]="{standalone: true}">
                                </ckeditor>
                                <!-- <angular-editor formControlName="description" [config]="config"></angular-editor> -->
                                <!-- <textarea
                    name=""
                    id=""
                    cols=""
                    rows="4"
                    class="form-control"
                    formControlName="description"
                    placeholder="Enter description"
                  >
                  </textarea> -->
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="islife">
                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Package Name</label>
                                <input [ngClass]="{
                      'is-invalid': submittedform && f['title'].errors
                    }" formControlName="title" type="text" class="form-control" placeholder="title here" />
                                <div *ngIf="submittedform && f['title'].errors" class="invalid-feedback">
                                    <div *ngIf="f['title'].errors['required']">
                                        Package Name is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Words</label>
                                <input [ngClass]="{
                      'is-invalid': submittedform && f['words'].errors
                    }" formControlName="words" type="text" class="form-control" placeholder="Words here" />
                                <div *ngIf="submittedform && f['words'].errors" class="invalid-feedback">
                                    <div *ngIf="f['words'].errors['required']">
                                        Package words is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Lifetime Amount</label>
                                <input formControlName="life_amount" type="text" class="form-control"
                                    placeholder="Life time subscription amount" [ngClass]="{
                        'is-invalid':
                          submittedform && f['life_amount'].errors
                      }" />
                                <div *ngIf="
                        submittedform && f['life_amount'].errors
                      " class="invalid-feedback">
                                    <div *ngIf="f['life_amount'].errors['required']">
                                        Package amount is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Lifetime Discount</label>
                                <input formControlName="life_discount" type="text" class="form-control"
                                    placeholder="Life time subscription discount" [ngClass]="{
                        'is-invalid':
                          submittedform && f['life_discount'].errors
                      }" />
                                <div *ngIf="submittedform && f['life_discount'].errors" class="invalid-feedback">
                                    <div *ngIf="f['life_discount'].errors['required']">
                                        Package amount is required
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-12">
                            <div class="mb-3">
                                <label>Description</label>
                                <ckeditor [editor]="Editor" [(ngModel)]="desc" [ngModelOptions]="{standalone: true}">
                                </ckeditor>
                                <!-- <angular-editor formControlName="description" [config]="config"></angular-editor> -->
                                <!-- <textarea name="" id="" cols="" rows="4" class="form-control" formControlName="description"
                    placeholder="Enter description">
                  </textarea> -->
                            </div>
                        </div>
                    </div>
                    <!-- <div class="mb-3" *ngIf="show">
                          <div class="d-flex justify-content-center">
                              <div class="spinner-grow text-warning spinner-css mt-1 mb-2" id="spinnerloading"
                                  role="status">
                                  <span class="visually-hidden">Loading...</span>
                              </div>
                          </div>
                      </div> -->
                    <div class="text-end">
                        <button type="submit"
                            class="btn btn-custom btn-sm py-1 text-light waves-effect waves-light float-end">
                            <i class="bx bx-save"></i> Save Details
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- edit modal  -->
<div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Edit Package</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" #closebutton1
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="editpackageform" (ngSubmit)="oneditform()">
                    <!-- <div class="row">
                        <div class="col-12">
                            <div class="d-flex justify-content-end align-items-lg-baseline">
                                <div class="form-check form-switch">
                                    <input class="form-check-input me-2" type="checkbox" role="switch"
                                        id="flexSwitchCheckChecked" (change)="lifetime($event)" />
                                    <label class="form-label smaller" for="flexSwitchCheckChecked">Life time</label>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="row" *ngIf="!islife">
                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Package Name</label>
                                <input [ngClass]="{
                      'is-invalid': submittedform && f1['title'].errors
                    }" formControlName="title" type="text" class="form-control" placeholder="title here" />
                                <div *ngIf="submittedform && f1['title'].errors" class="invalid-feedback">
                                    <div *ngIf="f1['title'].errors['required']">
                                        Package Name is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Words</label>
                                <input [ngClass]="{
                      'is-invalid': submittedform && f1['words'].errors
                    }" formControlName="words" type="text" class="form-control" placeholder="Words here" />
                                <div *ngIf="submittedform && f1['words'].errors" class="invalid-feedback">
                                    <div *ngIf="f1['words'].errors['required']">
                                        Package words is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label for="">Projects</label>
                                <input type="text" class="form-control" formControlName="projects">
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label for="">Generational Text</label>
                                <input type="text" class="form-control" formControlName="generated_text">
                            </div>
                        </div>
                        <!-- <div class="col-12">
                            <div class="mb-3">
                                <label for="">type</label>
                                <input type="text" class="form-control" formControlName="type" >
                            </div>
                        </div> -->
                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Monthly Amount</label>
                                <input formControlName="monthly_amount" readonly type="text" class="form-control"
                                    placeholder="monthly amount here" [ngClass]="{
                      'is-invalid': submittedform && f1['monthly_amount'].errors
                    }" />
                                <div *ngIf="submittedform && f1['monthly_amount'].errors" class="invalid-feedback">
                                    <div *ngIf="f1['monthly_amount'].errors['required']">
                                        Package amount is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Yearly Amount</label>
                                <input formControlName="yearly_amount" readonly type="text" class="form-control"
                                    placeholder="yearly amount here" [ngClass]="{
                      'is-invalid': submittedform && f1['yearly_amount'].errors
                    }" />
                                <div *ngIf="submittedform && f1['yearly_amount'].errors" class="invalid-feedback">
                                    <div *ngIf="f1['yearly_amount'].errors['required']">
                                        Package amount is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Recurly Plan ID</label>
                                <input formControlName="recurly_plan_id" type="text" class="form-control" placeholder="Recurly Plan ID"/>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Recurly Plan Code</label>
                                <input formControlName="recurly_plan_code" type="text" class="form-control" placeholder="Recurly Plan Code"/>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Recurly Plan Monthly Code</label>
                                <input formControlName="recurly_monthly_plan_code" type="text" class="form-control" placeholder="Recurly Monthly Code"/>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="mb-3">
                                <label>Recurly Plan Yearly Code</label>
                                <input formControlName="recurly_yearly_plan_code" type="text" class="form-control" placeholder="Recurly Yearly Code"/>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="mb-3">
                                <label>Description</label>
                                <ckeditor [editor]="Editor" [(ngModel)]="desc" [ngModelOptions]="{standalone: true}">
                                </ckeditor>
                            </div>
                        </div>
                    </div>


                    <div class="text-end">
                        <button type="submit" class="btn btn-custom text-light waves-effect waves-light float-end">
                            <i class="bx bx-save"></i> Save Details
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>