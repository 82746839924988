import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';
import { NgOptionTemplateDirective } from '@ng-select/ng-select';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';



@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit {

  addTemplateForm!: FormGroup;
  editTemplateForm!: FormGroup;
  submitted = false;
  foundTemplate: boolean = false
  btnload = false;
  loading: boolean = false;
  library_id: any;
  libraryId: any;
  templates: any;
  temp: any = [];
  prompt: any;
  id: any;
  category_id: any;
  categorySelected: any;
  cat_id: any;
  categories: any;
  labraries: any;
  token: any;
  max_tokens: any
  fieldarr: any[] = [];
  field_id: any;
  selectedFields: any[] = [];
  searchTemplate: any[] = [];
  searchTemp: any[] = [];
  fields: any;
  search: any;
  pag: number = 1;



  get f(): { [key: string]: AbstractControl } {
    return this.addTemplateForm.controls;
  }
  get f1(): { [key: string]: AbstractControl } {
    return this.editTemplateForm.controls;
  }
  @ViewChild('closebutton') closebutton: any;
  @ViewChild('editclosebutton') editclosebutton: any;


  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router) { }
  async ngOnInit() {
    // this.getTemplate()
    // this.getTemp()

    this.getField();
    this.addTemplateForm = this.fb.group(
      {
        library_id: [''],
        max_tokens: [''],
        field_id: [''],
        prompt: [''],
        category_id: ['']
      }
    );
    this.editTemplateForm = this.fb.group(
      {
        library_id: [''],
        max_tokens: [''],
        field_id: [''],
        prompt: [''],
        category_id: ['']
      });

    await this.getCategory();
    await this.getLibrary()
    this.getTemp()
    // this.getTemplate();
    // this.getField();
  }
  getField() {
    this.auth.get('field?action=all').subscribe({
      next: (res) => {
        this.fields = res.data.data;
      },
      error: (err) => {

      },
    });
  }

  addTemplate() {
    this.btnload = true;
    this.submitted = true;
    let endpoint = 'template';
    let body = {
      library_id: this.library_id,
      max_tokens: this.addTemplateForm.value.max_tokens,

      fields: this.fieldarr,
      prompt: this.addTemplateForm.value.prompt,
      category_id: this.category_id
    };
    if (this.addTemplateForm.invalid) {
      this.btnload = false;
      this.foundTemplate = false
    } else {
      this.auth.post(endpoint, body).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.addTemplateForm.reset();
            this.closebutton.nativeElement.click();
            Swal.fire({
              icon: 'success',
              title: 'Success.',
              text: res.data.message,
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 2000,
            });
            // this.getTemplate();
            this.getTemp()
          }
          this.foundTemplate = true
          this.submitted = false;
          this.btnload = false;
        },
        error: (error: any) => {
          this.submitted = false;
          this.btnload = false;
          Swal.fire({
            icon: 'error',
            position: 'top',
            toast: true,
            title: error.error.data.message,
            showConfirmButton: false,
            timer: 5000,
          });
          this.addTemplateForm.reset();
          this.closebutton.nativeElement.click();
        },
      });
    }
  }

  // getTemplate() {
  //   // this.auth.get('template?category' + this.cat_id.toString()).subscribe({
  //   this.auth.get('template').subscribe({
  //     next: (res) => {
  //       

  //       this.foundTemplate = true
  //       this.loading = true
  //       this.templates = res.data.data;
  //       this.searchTemplate = this.templates

  //     },
  //     error: (err) => {
  //       this.foundTemplate = false
  //       this.loading = false
  //     },
  //   });
  // }
  getTemp() {
    this.auth.get('template?category_id=' + this.category_id).subscribe({
      // this.auth.get('template').subscribe({
      next: (res) => {
        // this.foundTemplate = true
        this.loading = true
        this.temp = res.data.data;
        this.searchTemp = this.temp;
        // if (this.temp.length > 0) {
        //   this.tempData = this.temp.slice(0, 5)
        // }
        this.foundTemplate = this.temp.length > 0 ? true : false;
      },
      error: (err) => {
        this.foundTemplate = false
        this.loading = false
      },
    });
  }
  async getLibrary() {
    await new Promise((resolve) => {
      this.auth.get('library/librarydetail/' + this.category_id).subscribe({
        next: (res) => {
          this.labraries = res.data.data;
          resolve(true)
        },
        error: (err) => {
          resolve(true)
        },
      });
    })
  }
  tempData: any[] = [];
  showMore() {
    let tempLength = this.tempData.length + 5;
    if (tempLength > this.temp.length) {
      tempLength = this.temp.length;
    }
    this.tempData = this.temp.slice(0, tempLength)
  }
  onSearch() {
    if (!this.search.trim() && !this.category_id) {
      this.temp = this.searchTemp;
      this.foundTemplate = true;
    } else {
      let filteredTemplates = this.searchTemp;
      if (this.search.trim()) {
        filteredTemplates = filteredTemplates.filter((template) =>
          template.prompt.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      if (this.category_id) {
        filteredTemplates = filteredTemplates.filter((template) =>
          template.category_id === this.category_id
        );
      }
      this.temp = filteredTemplates;
      this.tempData = this.temp.slice(0, 5);
      this.foundTemplate = this.tempData.length > 0;
    }
  }
  async getCategory() {
    await new Promise((resolve) => {
      this.auth.get('category?action=all').subscribe({
        next: (res) => {
          this.categories = res.data.data;
          this.category_id = this.categories[0]._id;
          resolve(true)
        },
        error: (err) => {
          resolve(true)
        },
      });
    })
  }
  async onCategorySelected() {
    await this.getLibrary()
    this.getTemp();
  }
  editTemplate(index: any) {
    this.editTemplateForm.patchValue(this.temp[index])
    this.category_id = this.temp[index]?.category_id
    this.library_id = this.temp[index]?.library_id
    this.fieldarr = this.temp[index]?.fields
    this.id = this.temp[index]._id;
  }

  reset() {
    this.temp = []
    this.getTemp()

  }
  deleteTemplate(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete it?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.auth.del('template/' + id, '').subscribe({
          next: (res: any) => {
            if (res.status == 200) {
              // this.templates = this.templates.filter((member: any) => member._id !== id);
              Swal.fire({
                icon: 'success',
                position: 'top',
                toast: true,
                title: 'Success',
                text: res.data.message,
                showConfirmButton: false,
                timer: 2500,
              });
              this.reset()
            }
          },
          error: (err) => {
            this.foundTemplate = false
            Swal.fire({
              icon: 'error',
              position: 'top',
              toast: true,
              title: 'No Template Found',
              showConfirmButton: false,
              timer: 5000,
            });
            this.getTemp();
          },
        });
      }
    });
  }
  edit() {
    this.loading = true;
    // this.field_id = this.fieldarr;
    let body = {
      // library_id: this.editTemplateForm.value.library_id,
      // fields: this.editTemplateForm.value.field_id,
      // prompt: this.editTemplateForm.value.prompt,
      // category_id: this.editTemplateForm.value.category_id
      max_tokens: this.editTemplateForm.value.max_tokens,
      library_id: this.library_id,
      fields: this.fieldarr,
      prompt: this.editTemplateForm.value.prompt,
      category_id: this.category_id

    }
    this.auth.patch('template/' + this.id, body).subscribe({
      next: (res) => {


        if (res.status == 200) {
          this.editTemplateForm.reset();
          this.editclosebutton.nativeElement.click();
          Swal.fire({
            icon: 'success',
            title: 'Success.',
            text: res.data.message,
            position: 'top',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
          });
          // this.getTemplate();
          this.getTemp();
          this.loading = true;


        }

      },
      error: (err) => {
        this.loading = false;
      },
    });
  }
}













