import { Injectable, Input, inject } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';





@Injectable({
  providedIn: 'root'
})
class PermissionsService {



  constructor(private router: Router, private auth: AuthService, private route: ActivatedRoute) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    // const validrole = this.data
    let validrole = ""
    if (this.auth.isSuperAdmin()) {
      validrole = "superadmin"
    } else if (this.auth.isAdmin()) {
      validrole = "admin"
    }else{
      validrole = "user"
    }
    if (!this.auth.isLogin() || this.auth.userRole() !== validrole) {
      this.router.navigate(['/']);
      return false;
    }
    // this.auth.validateToken();
    return true;
  }
}
export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(PermissionsService).canActivate(next, state);
}

// export const AuthGuard: CanActivateFn = (route, state) => {
  
//   return true;
// };