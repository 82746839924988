<div class="">
    <div *ngIf="!chat.getpageLoader()">
        <div class="chat-detail-box min-vh-100">
            <p class="text-center color-animate mb-0">Welcome to Heidi</p>
            <p class="text-center text-sm mb-0">Your personalized AI-powered chatbot</p>
            <div role="button"
                class="my-3 d-flex justify-content-center flex-lg-row flex-column gap-2 text-center cursor-pointer align-items-center">
                <span class="alert alert-success border-0 text-blue bg-white py-1 px-3 rounded-pill text-sm"><span><i
                            class="bx bxl-chrome"></i></span> Get Heidi For Chrome & Microsoft Edge
                </span>
                <span class="alert alert-success border-0 bg-white py-1 rounded-pill px-3 text-black"><strong
                        class="text-md ">39.40 M</strong> Generations Until Now</span>
            </div>
            <div class="row mt-2 cards">
                <div class="col-xxl-3 col-lg-4 col-12 py-2">
                    <div class="text-center">
                        <span class="d-flex justify-content-center align-items-center"><i
                                class="bx bx-pencil icon me-2 text-blue"></i><span class="bold text-sm">Ask complex
                                questions</span>
                        </span>
                        <div class="bg-white shadow-sm px-3 py-3 my-2 rounded cursor-pointer border-hover box-height "
                            (click)="setClickedText(0)" role="button">
                            <p
                                class="text-xs d-flex justify-content-center align-items-center mb-0 text-start text-turncate ">
                                "What are the ethical implications of AI in healthcare, finance, and criminal
                                justice?"
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-3 col-lg-4 col-12 py-2">
                    <div class="text-center">
                        <span class="d-flex justify-content-center align-items-center"><i
                                class="bx bx-image-alt icon me-2 text-blue"></i><span class="bold text-sm">Create
                                digital
                                artwork</span>
                        </span>
                        <div class="bg-white shadow-sm px-3 py-3 my-2 rounded cursor-pointer border-hover box-height "
                            role="button" (click)="setClickedText(1)">
                            <p
                                class="text-xs d-flex justify-content-center align-items-center mb-0 text-start text-turncate">
                                "Hi Heidi, What are the key techniques and tools used to create captivating digital
                                artwork?"
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-3 col-lg-4 col-12 py-2">
                    <div class="text-center">
                        <span class="d-flex justify-content-center align-items-center"><i
                                class="bx bx-microphone icon me-2 text-blue"></i><span class="bold text-sm">Give
                                voice
                                commands</span>
                        </span>
                        <div class="bg-white shadow-sm px-3 py-3 my-2 rounded cursor-pointer border-hover box-height  "
                            (click)="setClickedText(2)" role="button">
                            <p
                                class="text-xs d-flex justify-content-center align-items-center mb-0 text-start text-turncate">
                                "What are the most effective voice command technologies?"
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-3 col-lg-4 col-12 py-2">
                    <div class="text-center">
                        <span class="d-flex justify-content-center align-items-center"><i
                                class="bx bx-message-square icon me-2 text-blue"></i><span
                                class="bold text-md text-nowrap text-ellipsis">Generate professional content</span>
                        </span>
                        <div class="bg-white shadow-sm px-3 py-3 my-2 rounded cursor-pointer border-hover box-height  "
                            (click)="setClickedText(3)" role="button">
                            <p
                                class="text-xs d-flex justify-content-center align-items-center mb-0 text-start text-turncate">
                                What are the implications and effectiveness of using AI to generate professional
                                content?"
                            </p>
                        </div>
                    </div>
                </div>
                <!-- <app-chatbox [childData]="id"></app-chatbox> -->
            </div>
        </div>
    </div>
    <div *ngIf="chat.getpageLoader()">
        <div class="chat-height pb-5  mt-5 mt-lg-1" #scroll [scrollTop]="scroll.scrollHeight">
            <div class="">
                <div class="    " *ngFor="let chat of chats; let i = index">
                    <div class="rounded-top  text-muted  px-md-2 px-1  d-flex flex-row-reverse gap-2 gap-lg-3 msg-color mb-3 mt-3 mt-lg-0"
                        *ngIf="i % 2 == 0" style="width: 90%; float: right;">
                        <div class="conversation-list d-flex">
                            <div class="dropdown">
                                <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <i class="bx bx-dots-vertical-rounded fs-3"></i>
                                </a>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item pointer" (click)="speech(chat?.message)">Speech
                                        <span class="icon rounded-circle ms-2 my-auto">
                                            <i class="bx bx-volume-full text-black me-1"></i>
                                        </span>

                                    </a>
                                    <a class="dropdown-item pointer" (click)="copy(chat?.message)">Copy
                                        <span class="icon rounded-circle ms-2 my-auto">
                                            <i class="bx bx-copy text-black me-1"></i>
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div class="ctext-wrap">
                                <div class="d-flex justify-content-end gap-2">
                                    <p class=" mb-0 py-2 text-justify " style="white-space: pre-line !important; font-size: 0.9rem; font-weight: 600;
                                    font-size: 0.9rem; font-weight: 600;">
                                        {{ chat?.message }}
                                    </p>
                                    <img src="{{this.chat.profilePic? this.auth.getapi() + this.chat.profilePic: profilePic}}"
                                        alt="" class="avatar-logo rounded-circle bg-transparent img-fluid me-2 objFit"
                                        style="height: 30px; width: 30px;" width="30px" />
                                </div>
                                <p class="chat-time small my-0 py-1 text-start">
                                    {{ chat?.time }}
                                </p>
                            </div>

                        </div>
                    </div>
                    <div class="rounded-top my-3 text-muted   px-md-2 px-1 d-flex gap-2 gap-lg-3 msg-color my-2"
                        *ngIf="i % 2 != 0" style="width: 90%;">
                        <!-- <img src="{{ this.auth.getapi() + profilePic }}" alt=""
                        class="avatar-logo rounded-circle bg-transparent img-fluid ms-md-5 ms-2"/> -->
                        <!-- <p class="my-auto">{{ chat?.message }}</p> -->
                        <div class="conversation-list d-flex">
                            <div class="stext-wrap ">
                                <div class="d-flex gap-2">
                                    <img src={{responsePic}} alt="" class="img-fluid mx-0 px-0 pb-2"
                                        style="height: 30px;">
                                    <div>
                                        <div *ngIf="chat?.code">
                                            <div *ngFor="let m of chat?.message, let i = index">
                                                <p class="mb-0  text-justify" *ngIf="i%2==0"
                                                    style="white-space: pre-line !important; font-size: 0.9rem; font-weight: 600;">
                                                    <!--Text message-->
                                                    {{ m }}
                                                </p>
                                                <div *ngIf="i%2!=0" class="bg-code p-2 my-2">
                                                    <code class="m-2 text-justify code">  <!--code message-->
                                                        {{ m }}
                                                        </code>
                                                </div>
                                            </div>
                                        </div>
                                        <p class=" mb-0  text-justify" *ngIf="!chat?.code"
                                            style="white-space: pre-line !important; font-size: 0.9rem; font-weight: 600;">
                                            {{ chat?.message }}
                                        </p>
                                    </div>
                                </div>
                                <div class="ml-30 search-links mt-3" *ngIf="chat?.links.length>0">
                                    <h6 class="f-w-600 text-decoration-underline" style="color: #7267ef;">Suggestions:
                                    </h6>
                                    <!-- <a target="_blank" [href]="google?.link" class="link shadow-sm mt-2"
                                        *ngFor="let google of chat?.links">
                                        <img class="link-favicon"
                                            [src]="'https://www.google.com/s2/favicons?domain=' + google?.displayLink">
                                        <span class="ms-3 f-w-600">{{ google.title }}</span>
                                    </a> -->

                                    <a target="_blank" [href]="google?.link" class="link my-1"
                                        title="{{ google.title }}" *ngFor="let google of chat?.links">
                                        <img class="link-favicon img-fluid"
                                            [src]="'https://www.google.com/s2/favicons?domain=' + google?.displayLink">
                                        <small class="ms-3 f-w-600">{{ google?.title }}</small>
                                    </a>
                                </div>
                                <p class="small chat-time small my-0 py-2 text-end">
                                    {{chat?.time}}
                                </p>
                            </div>
                            <div class="dropdown">
                                <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <i class="bx bx-dots-vertical-rounded fs-3"></i>
                                </a>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item pointer" (click)="speech(chat?.message)">Speech
                                        <span class="icon rounded-circle ms-2 my-auto">
                                            <i class="bx bx-volume-full text-black me-1"></i>
                                        </span>

                                    </a>
                                    <a class="dropdown-item pointer" (click)="copy(chat?.message)">Copy
                                        <span class="icon rounded-circle ms-2 my-auto">
                                            <i class="bx bx-copy text-black me-1"></i>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div class="d-inline-flex float-end justify-content-end w-100" *ngIf="this.chat.getLoader()">
                    <div
                        class="rounded-top float-end text-muted  px-md-2 px-1 ps-5 msg-color d-block p-l-50 gap-3 gap-lg-5 mb-3 ">
                        <div class="conversation-list d-flex">
                            <div class="dropdown">
                                <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <i class="bx bx-dots-vertical-rounded fs-3"></i>
                                </a>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item pointer" (click)="speech(this.chat.getMessage())">Speech
                                        <span class="icon rounded-circle ms-2 my-auto">
                                            <i class="bx bx-volume-full text-black me-1"></i>
                                        </span>

                                    </a>
                                    <a class="dropdown-item pointer" (click)="copy(this.chat.getMessage())">Copy
                                        <span class="icon rounded-circle ms-2 my-auto">
                                            <i class="bx bx-copy text-black me-1"></i>
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div class="ctext-wrap">
                                <div class="d-flex gap-2">
                                    <p class=" mb-0 py-1 text-justify"
                                        style="white-space: pre-line !important; font-size: 0.9rem; font-weight: 600;">
                                        {{ this.chat.getMessage() }}
                                    </p>
                                    <img src="{{ profilePic }}" alt=""
                                        class="avatar-logo rounded-circle bg-transparent img-fluid me-2 objFit"
                                        style="height: 30px; width: 30px;" width="30px" />
                                </div>
                                <small class="chat-time small my-0 py-1 text-end">
                                    {{ message_time }}
                                </small>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-blue bgResponse py-3 mx-3 pe-4 d-flex w-50" *ngIf="this.chat.getLoader()">
                    <img src={{responsePic}} alt=""
                        class="img-fluid ms-3 px-0 avatar-logo rounded-circle bg-transparent me-2 objFit"
                        style="height: 30px; width: 30px;">
                    <div class="d-flex gap-3  ps-2 py-3">
                        <div class="spinner-grow spinner-grow-sm " role="status" aria-hidden="true">
                            <span class="visually-hidden text-dark">Loading...</span>
                        </div>
                        <div class="spinner-grow spinner-grow-sm " role="status">
                            <span class="visually-hidden text-dark">Loading...</span>
                        </div>
                        <div class="spinner-grow spinner-grow-sm" role="status">
                            <span class="visually-hidden text-dark">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <app-chatbox [childData]="id"></app-chatbox>

</div>