import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplayoutComponent } from '../layout/applayout/applayout.component';
import { ChatlayoutComponent } from '../layout/chatlayout/chatlayout.component';
import { HeidichatComponent } from '../shared/chatpage/heidichat/heidichat.component';
import { HomeComponent } from '../shared/home/home.component';
import { SettingComponent } from '../shared/setting/setting.component';
import { ChatDetailsComponent } from '../shared/chatpage/chatdetails/chatdetails.component';
import { PromptsComponent } from '../shared/chatpage/prompts/prompts.component';
import { PhotochatComponent } from '../shared/photochat/photochat.component';
import { ProjectsComponent } from '../shared/history/projects/projects.component';
import { ProjectdetailsComponent } from '../shared/history/projectdetails/projectdetails.component';
import { LongTextComponent } from '../shared/history/longtext/longtext.component';
import { UpgradeComponent } from '../shared/upgrade/upgrade.component';

export const userroutes: Routes = [

  {
    path: '',
    component: ApplayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        data: { title: 'Home' },
      },
      {
        path: 'upgrade',
        component: UpgradeComponent,
        data: { title: 'Upgrade' },
      },
      {
        path: 'history',
        children: [
          {
            path: '',
            component: ProjectsComponent,
            data: { title: 'Projects' },
          },
          {
            path: ':id',
            component: ProjectdetailsComponent,
            data: { title: "Project Detalis" }
          },

        ]
      },


      {
        path: 'settings',
        component: SettingComponent,
        data: { title: 'Settings' },
      },
      {
        path: 'photochat',
        component: PhotochatComponent,
        data: { title: 'Photo Chat' },
      },
    ],
  },
  {
    path: 'template',
    children: [
      {
        path: '',
        component: LongTextComponent,
        data: { title: 'template' },
      },
      {
        path: ':id',
        component: LongTextComponent,
        data: { title: "template" }
      },
    ]
  },
  {
    path: 'heidi',
    component: ChatlayoutComponent,
    children: [
      {
        path: 'chat',
        children: [
          {
            path: '',
            component: HeidichatComponent,
            data: { title: 'Heidi Chat' },
          },
          {
            path: ':id',
            component: ChatDetailsComponent,
            data: { title: 'Chat Details' },
          },
        ],
      },
      {
        path: 'prompt',
        component: PromptsComponent,
        data: { title: 'Personal Prompt' },
      },
    ],
  },
  {
    path: 'prompt',
    children: [
      {
        path: ':id',
        component: LongTextComponent,
        data: { title: 'Long Text' },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(userroutes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
