

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
// import {
//   SocialAuthService,
//   GoogleLoginProvider,
//   SocialUser,
// } from '@abacritt/angularx-social-login';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { Customvalidators } from 'src/app/services/customvalidators.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']

})
export class RegisterComponent implements OnInit {

  loading = false;

  registerForm!: FormGroup;
  submitted = false;

  title = 'loginGoogle';
  loginForm!: FormGroup;
  // socialUser!: SocialUser;
  isLoggedin?: boolean;
  ipAddress: any;

  @ViewChild('loginRef', { static: true }) loginElement!: ElementRef;

  get f(): { [key: string]: AbstractControl } {
    return this.registerForm.controls;
  }

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    // private socialAuthService: SocialAuthService
  ) { }

  ngOnInit(): void {
    if (this.auth.isLogin()) {
      if (this.auth.isSuperAdmin()) {
        this.router.navigate(['superadmin']);
      } else if (this.auth.isAdmin()) {
        this.router.navigate(['admin']);
      } else if (this.auth.isUser()) {
        this.router.navigate(['user']);
      }
    }
    // this.socialAuthService.authState.subscribe((user) => {
    //   this.socialUser = user;
    //   this.isLoggedin = user != null;
    // });

    this.registerForm = this.fb.group(
      {
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(8)]],
        conpassword: ['', [Validators.required, Validators.minLength(8)]],
        key: ['admin'],
        type: ['normal'],
      },
      { validator: Customvalidators('password', 'conpassword') }
    );
  }

  loginWithGoogle(): void {
    // this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  onSubmit(): void {
    this.submitted = true;
    this.loading = true;

    if (this.registerForm.invalid) {
      this.loading = false;
    } else {
      this.registerForm.value.key = 'admin'
      this.registerForm.value.type = 'normal';
      this.registerUSer(this.registerForm.value);
    }
  }

  registerUSer(body: any) {
    this.auth.post('register', body).subscribe({
      next: (res: any) => {
        if (res.status == 200) {
          this.registerForm.reset();
          Swal.fire({
            icon: 'success',
            title: 'Congratulations',
            text: res.data.message,
            position: 'top',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
          });
          localStorage.setItem('heidiauthtoken', res.auth_token);
          localStorage.setItem('userdata', JSON.stringify(res.data.info));
          this.auth.setLoginStatus(true);
          this.auth.setuserRole(res.data.info.role_key);
          if (this.auth.isLogin()) {
            this.router.navigate(['admin']);

            // if (this.auth.isSuperAdmin()) {
            //   this.router.navigate(['superadmin']);
            // } else if (this.auth.isAdmin()) {
            //   this.router.navigate(['admin']);
            // } else if (this.auth.isUser()) {
            //   this.router.navigate(['user']);
            // }
          }
        }
        this.submitted = false;
        this.loading = false;
      },
      error: (error: any) => {
        this.submitted = false;
        this.loading = false;
        Swal.fire({
          icon: 'error',
          position: 'top',
          toast: true,
          title: error.error.data.message,
          showConfirmButton: false,
          timer: 5000,
        });
      },
    });
  }
}
