
import { Component, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent {
  pageName = 'History';
  search: any
  projects: any = []
  selected: any
  inputshow = false
  name: any
  btnload = false

  projectForm!: FormGroup
  submitted = false


  get f(): { [key: string]: AbstractControl } {
    return this.projectForm.controls;
  }

  @ViewChild('closebutton') closebutton: any;
  constructor(private auth: AuthService, private router: Router, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.projectForm = this.fb.group({
      name: ['', Validators.required]
    })
    this.getProjects()
  }

  onProjectSubmit() {
    this.btnload = true
    this.submitted = true;

    let endpoint = 'project';
    let body = this.projectForm.value;
    if (this.projectForm.invalid) {
      this.btnload = false
    } else {
      this.auth.post(endpoint, body).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.projectForm.reset();
            this.reset();
            this.closebutton.nativeElement.click();
            Swal.fire({
              icon: 'success',
              title: 'Success.',
              text: 'Project created successfully',
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 2000,
            });
          }
          this.submitted = false;
          this.btnload = false
        },
        error: (error: any) => {
          this.submitted = false;
          this.btnload = false
          Swal.fire({
            icon: 'error',
            position: 'top',
            toast: true,
            title: error.error.data.message,
            showConfirmButton: false,
            timer: 5000,
          });
        },
      });
    }
  }




  getProjects() {

    let q = ''
    if (this.search) {
      q = 'project?search=' + this.search
    } else {
      q = 'project'
    }
    this.auth.get(q).subscribe({
      next: (res) => {
        this.projects = res.data.data
      },
      error: (err) => {
      }
    })
  }

  nav(id: any) {
    if (this.auth.isAdmin()) {
      this.router.navigate(['/admin/history', id]);
    } else {
      this.router.navigate(['/user/history', id]);

    }
  }

  editProject(id: any, index: any) {
    this.name = id?.name
    this.inputshow = true
    this.selected = index
  }

  updateName(id: any) {
    let endpoint = 'project/' + id;
    let body = {
      name: this.name
    }
    this.auth.patch(endpoint, body).subscribe({
      next: (res: any) => {
        if (res.status == 200) {
          this.reset();
          Swal.fire({
            icon: 'success',
            title: 'Success.',
            text: 'Project edited successfully',
            position: 'top',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      },
      error: (error: any) => {
        Swal.fire({
          icon: 'error',
          position: 'top',
          toast: true,
          title: error.error.data.message,
          showConfirmButton: false,
          timer: 5000,
        });
      },
    });
  }

  deleteProject(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete the project? This cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.auth.del('project/' + id, '').subscribe({
          next: (res: any) => {
            if (res.status == 200) {
              this.reset();
              Swal.fire({
                icon: 'success',
                position: 'top',
                toast: true,
                title: 'Success',
                text: res.data.message,
                showConfirmButton: false,
                timer: 2500,
              });
            }
          },
          error: (err) => {
          },
        });
      }
    });
  }

  reset() {
    this.inputshow = false
    this.projects = []
    this.getProjects()
  }

  navigate() {
    if (this.auth.isAdmin()) {
      this.router.navigate(['/admin'])
    } else {
      this.router.navigate(['/user'])
    }
  }


}
