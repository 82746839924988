import { Component } from '@angular/core';
import {
  AbstractControl, FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { Customvalidators } from 'src/app/services/customvalidators.service';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';


@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})

export class ChangepasswordComponent {
  resetPasswordForm!: FormGroup;
  resetPasswordFormsubmitted = false;
  resetPasswordFormShowHide = false;
  loading: boolean = false
  code: any


  get f(): { [key: string]: AbstractControl } {
    return this.resetPasswordForm.controls;
  }

  constructor(private auth: AuthService, private fb: FormBuilder, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.code = this.route.snapshot.queryParamMap.get('code');

    this.resetPasswordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      conpassword: ['', [Validators.required, Validators.minLength(8)]],
    },
      { validator: Customvalidators('password', 'conpassword') }
    );
  }

  onChangePassword() {
    this.resetPasswordFormShowHide = true;
    this.resetPasswordFormsubmitted = true;
    if (this.resetPasswordForm.invalid) {
      this.resetPasswordFormShowHide = false;
    } else {
      var body = {
        action: 'forget_password',
        password: this.resetPasswordForm.value.password,
        code: this.code
      };
      this.loading = true;
      this.auth.post('resetpassword', body).subscribe({

        next: (res: any) => {
          if (res.status == 200) {
            Swal.fire({
              icon: 'success',
              title: 'Success.',
              text: res.data.message,
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 2000,
            });
            this.loading = false;
            this.router.navigate(['/login']);
          }
            this.resetPasswordFormsubmitted = false;
            this.resetPasswordFormShowHide = false;
        },
        error: () => {
          this.resetPasswordFormsubmitted = false;
          this.resetPasswordFormShowHide = false;
          this.loading = false;
        },
      });
    }
  }
}
