import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-appsetting',
  templateUrl: './appsetting.component.html',
  styleUrls: ['./appsetting.component.scss']
})
export class AppsettingComponent {
  apishowhide = false;
  apisubmitted = false
  apikeyform!: FormGroup;

  constructor(private auth: AuthService, private fb: FormBuilder) { }
  get f5(): { [key: string]: AbstractControl } {
    return this.apikeyform.controls;
  }
  ngOnInit() {
    if (this.auth.isSuperAdmin()) {
      this.apikeyform = this.fb.group({
        sendgridkey: ['', Validators.required],
        fromemail: ['', Validators.required],
        recurlykey: ['', Validators.required],
        openaikey: ['', Validators.required]
      });
      this.getAppsettings();
    }
  }
  getAppsettings() {
    this.auth.get('appsettings').subscribe({
      next: (res: any) => {
        let data = res.data.data;

        for (let i = 0; i < data.length; i++) {
          if (data[i].key == "sendgridkey") {
            this.apikeyform.controls['sendgridkey'].setValue(data[i].value);
          } else if (data[i].key == "s_key_1") {
            this.apikeyform.controls['openaikey'].setValue(data[i].value);
          } else if (data[i].key == "recurly") {
            this.apikeyform.controls['recurlykey'].setValue(data[i].value);
          } else if (data[i].key == "fromemail") {
            this.apikeyform.controls['fromemail'].setValue(data[i].value);
          }
        }
      }, error: () => { }
    })

  }

  onSubmitApikey() {
    this.apisubmitted = true;
    this.apishowhide = true;
    if (this.apikeyform.invalid) {
      this.apishowhide = false;
    } else {
      let person = {
        "appdata": [{
          "key": "sendgridkey",
          "value": this.apikeyform.value.sendgridkey,
        }, {
          "key": "openaikey",
          "value": this.apikeyform.value.openaikey,
        }, {
          "key": "recurly",
          "value": this.apikeyform.value.recurlykey,
        }, {
          "key": "fromemail",
          "value": this.apikeyform.value.fromemail,
        },]
      }
      this.auth.post('appsettings', person).subscribe({
        next: (res: any) => {
          if (res.status == 200) {

            Swal.fire({
              icon: "success",
              position: 'top',
              toast: true,
              title: "Success",
              text: res.data.message,
              showConfirmButton: false,
              timer: 2500
            })
          }
          this.apisubmitted = false;
          this.apishowhide = false;
        }, error: () => {
          this.apisubmitted = false;
          this.apishowhide = false;
        }
      })
    }
  }

}
