

import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';


import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  addCategoryForm!: FormGroup;
  editCategoryForm!: FormGroup;
  submitted = false;
  foundcategory: boolean = false
  searchCategories: any[] = [];
  btnload = false;
  loading: boolean = false;
  name: any;
  key: any;
  categories: any;
  id: any;
  search: string = '';
  pag: number = 1;



  get f(): { [key: string]: AbstractControl } {
    return this.addCategoryForm.controls;
  }
  get f1(): { [key: string]: AbstractControl } {
    return this.editCategoryForm.controls;
  }
  @ViewChild('closebutton') closebutton: any;
  @ViewChild('editclosebutton') editclosebutton: any;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router) { }
  ngOnInit() {

    this.addCategoryForm = this.fb.group(
      {
        name: ['', Validators.required],
        key: ['', Validators.required],
      }
    );
    this.editCategoryForm = this.fb.group(
      {
        name: ['', Validators.required],
        key: ['', Validators.required],
      });

    this.getCategory();


  }

  addCategory() {
    this.btnload = true;
    this.submitted = true;
    let endpoint = 'category';

    let body = {
      name: this.addCategoryForm.value.name,
      key: this.addCategoryForm.value.key,

    };
    if (this.addCategoryForm.invalid) {
      this.btnload = false;
      this.foundcategory = false

    } else {
      this.auth.post(endpoint, body).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.addCategoryForm.reset();
            this.getCategory();

            this.closebutton.nativeElement.click();
            Swal.fire({
              icon: 'success',
              title: 'Success.',
              text: res.data.message,
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 2000,
            });
          }
          this.foundcategory = true
          this.submitted = false;
          this.btnload = false;
        },
        error: (error: any) => {
          this.submitted = false;
          this.btnload = false;
          Swal.fire({
            icon: 'error',
            position: 'top',
            toast: true,
            // title: 'Please upgrade your Plan',
            showConfirmButton: false,
            timer: 5000,
          });
          this.addCategoryForm.reset();
          this.closebutton.nativeElement.click();
        },
      });
    }
  }
  getCategory() {
    this.auth.get('category?limit=50').subscribe({
      next: (res) => {
        this.foundcategory = true
        this.loading = true
        this.categories = res.data.data;
        this.categories.slice().sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.searchCategories = this.categories;
        // if (this.categories.length > 0) {
        //   this.categoryData = this.categories.slice(0, 6);
        // }
      },
      error: (err) => {
        this.foundcategory = false
        this.loading = false
      },
    });
  }
  // categoryData: any[] = [];

  // showMore() {
  //   let catlength = this.categoryData.length + 5;
  //   if (catlength > this.categories.length) {
  //     catlength = this.categories.length;
  //   }
  //   this.categoryData = this.categories.slice(0, catlength)
  // }
  onSearch() {
    if (!this.search.trim()) {
      this.categories = this.searchCategories;
      this.foundcategory = true
    } else {
      this.categories = this.searchCategories.filter((category) =>
        category.name.toLowerCase().includes(this.search.toLowerCase()));
      this.foundcategory = this.categories.length > 0;
    }
    // this.categoryData = this.categories.slice(0, 5);
    this.foundcategory = this.categories.length > 0;
  }

  editCategory(index: any) {
    this.editCategoryForm.patchValue(this.categories[index])
    this.id = this.categories[index]._id;
  }

  deleteCategory(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete it?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.auth.del('category/' + id, '').subscribe({
          next: (res: any) => {
            if (res.status == 200) {
              this.categories = this.categories.filter((member: any) => member._id !== id);
              this.foundcategory = this.categories.length > 0 ? true : false;
              Swal.fire({
                icon: 'success',
                position: 'top',
                toast: true,
                title: 'Success',
                text: res.data.message,
                showConfirmButton: false,
                timer: 2500,
              });
              this.getCategory();


            }
          },
          error: (err) => {
            this.foundcategory = false
            Swal.fire({
              icon: 'error',
              position: 'top',
              toast: true,
              title: 'No Category Found',
              showConfirmButton: false,
              timer: 5000,
            });

          },
        });
      }
    });
  }
  edit() {
    this.loading = true;
    let body = {
      name: this.editCategoryForm.value.name,
      key: this.editCategoryForm.value.key,
    }
    this.auth.patch('category/' + this.id, body).subscribe({
      next: (res) => {


        if (res.status == 200) {
          this.editCategoryForm.reset();
          this.editclosebutton.nativeElement.click();
          Swal.fire({
            icon: 'success',
            title: 'Success.',
            text: res.data.message,
            position: 'top',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
          });
          this.getCategory();

        }

      },
      error: (err) => {
        this.loading = false;
      },
    });
  }
}













