
import { Component, Input, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatService } from 'src/app/services/chat.service';
import Swal from 'sweetalert2';
interface Select {
  name: string;
}

@Component({
  selector: 'app-chatbox',
  templateUrl: './chatbox.component.html',
  styleUrls: ['./chatbox.component.scss'],
})
export class ChatBoxComponent {
  @Input() childData: any;
  @Input() promptData: any;

  isSwitchEnabled: boolean = false;
  isSwitchDisabled: boolean = false;
  isSending: boolean = false;


  mesg = '';
  pending = false;
  chats: any = [];
  categories: any = [];
  languages: any = [];
  plans: any;
  cid: any;
  promptSelect = true;
  promptid: any;
  record = false;
  timezone: any;
  promptForm!: FormGroup;
  submitted = false;
  btnload = false;
  textSubscription: any;
  selectedMood: string = 'Creative';
  selectedpersonality: string = 'General'

  tempmesg = ''

  get f(): { [key: string]: AbstractControl } {
    return this.promptForm.controls;
  }

  @ViewChild('closebutton') closebutton: any;
  constructor(
    public auth: AuthService,
    private router: Router,
    private chat: ChatService,
    private fb: FormBuilder
  ) {
    this.chat.getDetail();
    this.chat.clickedText$.subscribe((text) => (this.mesg = text));
  }

  personality: Select[] = [
    { name: 'General' },
    { name: 'Job Interviewer' },
    { name: 'Teacher' },
    { name: 'E-Commerce expert' },
    { name: 'Marketing expert' },
    { name: 'Motivational coach' },
    { name: 'Travel guide' },
    { name: 'Language translater' },
    { name: 'Personal trainer' },
    { name: 'Accountant' },
    { name: 'Career counselor' },
  ]
  moods: Select[] = [
    { name: 'Creative' },
    { name: 'Neutral' },
    { name: 'Friendly' },
    { name: 'Chill' },
    { name: 'Accurate' },
    { name: 'Sarcastic' },
    { name: 'Inspiring' },

  ];
  ngOnChanges() {
    if (this.promptData) {
      this.promptSelect = true;

    } else {
      if (this.chat.getPrompt()) {
        this.promptData = this.chat.getPrompt()
        this.promptSelect = true;
      } else {
        this.promptSelect = false;
      }
    }
  }

  ngOnInit(): void {
    // this.chat.init();
    this.textSubscription = this.chat.getText().subscribe((text) => {
      let t = JSON.parse(text)
      if(t.end){
        this.tempmesg = ''
        this.mesg += t.text + ' '
      } else {
        this.tempmesg = t.text
      }
    });

    if (this.chat.getPrompt()) {
      this.promptSelect = false;
      this.promptData = this.chat.getPrompt()
    } else {
      this.promptSelect = false;
    }

    this.promptForm = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      template: ['', Validators.required],
      category_id: ['', Validators.required],
      language_id: [''],
      check: [''],
    });

    this.getLanguages();
    this.getCategory();
    this.chat.setChatId(this.childData);
    // multiple get request
    const data = localStorage.getItem('userdata');
    if (data) {
      const userData = JSON.parse(data);
      this.cid = userData.company_id;
    }
  }

  ngOnDestroy() {
    this.textSubscription.unsubscribe();
    this.chat.stop();
    this.chat.destroy();
  }

  temp: any;
  t: any;
  pid: any;
  dataRecieved = false;

  getCompany() {
    this.auth.get('company').subscribe({
      next: (res) => {
        this.plans = res.data.data;
      },
      error: (err) => {
      },
    });
  }

  triggerFunction() {
    this.auth.trigger();
  }
  toggle = false
  googleToggle(e: any) {
    this.toggle = !this.toggle;
    this.toggle = (e.target.checked);
  }
  memory = true
  meomoryToggle(e: any) {
    this.memory = !this.memory;
    this.memory = (e.target.checked);
  }
  send() {
    this.chat.setLoader(true);
    this.chat.setPageLoader(true);
    this.isSending = true;
    if (this.chat.getChatId() != '') {
      this.pid = this.chat.getChatId();
    }
    this.temp = this.mesg;
    this.chat.setMessage(this.temp)
    this.mesg = '';

    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let body: any;
    if (this.promptSelect) {
      body = {
        message: this.temp,
        parent_id: this.pid,
        timezone: this.timezone,
        google_data: this.toggle,
        mood: this.selectedMood,
        prompt_id: this.promptData?._id,
        history: this.memory,
        personality: this.selectedpersonality
      };
    } else {
      body = {
        message: this.temp,
        parent_id: this.pid,
        // category_id: this.promptid,
        // category_id: "64b66498bd85bfb13f008220", //temorary
        google_data: this.toggle,
        timezone: this.timezone,
        personality: this.selectedpersonality,
        mood: this.selectedMood,
        history: this.memory,
      };
    }
    // let body = {
    //   message: this.temp,
    //   parent_id: this.pid,
    //   prompt_id: temp,
    //   history: this.isSwitchEnabled,
    // };
    this.auth.post('message', body).subscribe({
      next: (res) => {
        this.stop()

        if (res.data.message == 'AppSettings is not defined') {
          Swal.fire({
            icon: 'success',
            position: 'top',
            toast: true,
            title: 'Success',
            text: res.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
          this.mesg = '';
        }
        this.pending = false;
        let x = {
          message: res.data.Messages.message,
          response: res.data.Messages.response,
          // message_time: res.data.Messages.message_time,
          // response_time: res.data.Messages.response_time,
        };
        this.chats.push(x);
        this.temp = '';
        localStorage.setItem('words', res.data.Messages.words_remaining);
        this.chat.setWords(res.data.Messages.words_remaining);

        this.pid = res.data.Messages.parent_id;

        this.chat.setChatId(this.pid);
        this.dataRecieved = true;
        this.triggerFunction();
        this.isSending = false;
        this.chat.setClickedText('');
        // this.chat.setLoader(false);
        // this.chat.setPageLoader(false);



        // if (this.auth.isAdmin()) {
        // if (this.chat.getChatId() == '') {
        //   this.router.navigate([
        //     '/admin/heidi/chat'
        //   ]);
        // }
        //  else {
        //   this.router.navigate(['/admin/heidi/chat', this.chat.getChatId()]);
        // }
        // } else {
        // if (this.chat.getChatId() == '') {
        //   this.router.navigate([
        //     '/user/heidi/chat'
        //   ]);
        // } else {
        //   this.router.navigate(['/user/heidi/chat', this.chat.getChatId()]);
        // }
        // }

      },
      error: (err) => {
        this.isSending = false;
        this.chat.setLoader(false)
        this.chat.setPageLoader(false)

        this.pending = false;
        Swal.fire({
          icon: 'error',
          title: 'Error generating response.',
          text: err.error.data.message,
          position: 'top',
          toast: true,
          showConfirmButton: false,
          timer: 5000,
        });
      },
    });
  }



  pcheck = false;
  check(event: any): void {
    this.pcheck = event.target.checked;
  }

  submitForm() {
    this.btnload = true;
    this.promptForm.value.check = this.pcheck;
    this.submitted = true;
    let endpoint = 'prompt';
    let body = this.promptForm.value;
    if (this.promptForm.invalid) {
      this.btnload = false;
    } else {
      this.auth.post(endpoint, body).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.promptForm.reset();
            this.closebutton.nativeElement.click();
            Swal.fire({
              icon: 'success',
              title: 'Success.',
              text: 'Prompt added successfully',
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 2000,
            });
            // this.getPrompt();

            // this.router.navigate(['user/heidi/chat']);
          }
          this.submitted = false;
          this.btnload = false;
        },
        error: (error: any) => {
          this.submitted = false;
          this.btnload = false;
          Swal.fire({
            icon: 'error',
            position: 'top',
            toast: true,
            title: error.error.data.message,
            showConfirmButton: false,
            timer: 5000,
          });
        },
      });
    }
  }

  getCategory() {
    this.auth.get('category').subscribe({
      next: (res) => {
        this.categories = res.data.data;

        this.promptid = res.data.data[0]?._id;
      },
      error: (err) => {
        this.promptid = "64747be68774d4ec7ca95486";
      },
    });
  }

  getLanguages() {
    this.auth.get('language').subscribe({
      next: (res) => {
        this.languages = res.data.data;
      },
      error: (err) => {
      },
    });
  }

  start() {
    this.tempmesg = '';
    this.chat.start();
    this.record = true;
    // this.mesg=this.s2t.text
  }

  stop() {
    this.tempmesg = '';
    this.chat.stop();
    this.record = false;
    // this.mesg=this.s2t.getText()
  }
  removePrompt() {
    this.promptSelect = false;
    this.promptData = ''
  }
}