import { AfterViewInit, Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatService } from 'src/app/services/chat.service';
declare var google: any;

@Component({
  selector: 'app-applayout',
  templateUrl: './applayout.component.html',
  styleUrls: ['./applayout.component.scss']
})
export class ApplayoutComponent implements AfterViewInit {

  constructor(public auth: AuthService, private chat: ChatService) { }
  ngOnInit(): void {
    // setTimeout(() => {
    // new google.translate.TranslateElement(
    //   { pageLanguage: 'en' },
    //   'google_translate_element'
    // );
    this.chat.googleTranslate()
    // }, 2000);
  }

  ngAfterViewInit(): void {
    // this.chat.googleTranslate();
  }

}
