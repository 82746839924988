<div class="loader-container" *ngIf="loading">
    <div class="loader-logo"></div>
</div>

<div class="container-fluid p-0 scrollable-container bgWhite" [ngClass]="{ 'o-5': loading }">
    <div class="row g-0 align-items-center min-vh">
        <div class="col-xl-9 ">
            <div class="w-100">
                <img src="./assets/images/alpha5.png" class="img-fluid h-100 maskimg w-75" style="object-fit: contain"
                    alt="" />
            </div>
        </div>
        <div class="col-xl-3 mt-5 mt-lg-0 bg-white">
            <div class="px-lg-5 px-4 bg-white">
                <div class="w-100">
                    <div class="d-flex flex-column mt-4">
                        <div class="">
                            <a class="d-block">
                                <img src="./assets/images/logo1-trans.png" class="img-fluid" />

                            </a>
                        </div>
                        <div class="my-auto">
                            <h5 class="my-4 text-pink-custom">Register account</h5>

                            <h6 class=" mb-2">Get started with 7 days free trial for each plan.</h6>
                            <!-- <p class=" text-muted mb-1">No credit card required!</p> -->
                            <div class="mt-4">
                                <div class="tab-content" id="pills-tabContent">
                                    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                                        <div class=" row">
                                            <div class="form-group mb-3">
                                                <label for="first_name" class="form-label">First Name</label>
                                                <input type="text" class="form-control" formControlName="first_name"
                                                    placeholder="Enter First Name" [ngClass]="{
                                                        'is-invalid': submitted && f['first_name'].errors
                                                      }" />
                                                <div *ngIf="submitted && f['first_name'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f['first_name'].errors['required']">
                                                        First Name is required.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group  ">
                                                <label for="last_name " class="form-label">Last Name</label>
                                                <input type="text" class="form-control" formControlName="last_name"
                                                    placeholder="Enter Last Name" [ngClass]="{
                                                        'is-invalid': submitted && f['last_name'].errors
                                                      }" />
                                                <div *ngIf="submitted && f['last_name'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f['last_name'].errors['required']">
                                                        Last Name is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <div class="form-group">
                                                <label for="email" class="form-label">Business Email</label>
                                                <input type="email" formControlName="email" class="form-control"
                                                    placeholder="Enter Email"
                                                    [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" />
                                                <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                                                    <div *ngIf="f['email'].errors['required']">
                                                        Email address is required
                                                    </div>
                                                    <div *ngIf="f['email'].errors['email']">
                                                        Email address is not valid
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <div class="form-group">
                                                <label for="password" class="form-label">Password</label>
                                                <input type="password" placeholder="Password" formControlName="password"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
                                                <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                                                    <div *ngIf="f['password'].errors['required']">
                                                        Password is required
                                                    </div>
                                                    <div *ngIf="f['password'].errors['minlength']">
                                                        Password must be at least 8 characters long.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <div class="form-group">
                                                <label for="" class="form-label">Confirm Password</label>
                                                <input type="password" placeholder="Confirm Password"
                                                    formControlName="conpassword" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f['conpassword'].errors }" />
                                                <div *ngIf="submitted && f['conpassword'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f['conpassword'].errors['required']">
                                                        Confirm Password is required
                                                    </div>
                                                    <div *ngIf="f['conpassword'].errors['minlength']">
                                                        Password must be at least 8 characters long.
                                                    </div>
                                                    <div *ngIf="f['conpassword'].errors['confirmedValidator']">
                                                        Password and Confirm Password must be match.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button class="btn btn-custom w-100 btn-lg mb-2" type="submit">
                                            Register
                                        </button>
                                    </form>

                                    <p class=" text-muted hover mt-3 mb-2">
                                        Already have account?<a routerLink="/login"
                                            class="text-pink-custom text-hover pointer text-decoration-none">
                                            Login</a>
                                    </p>
                                    <p class="mb-5">
                                        <small class="small">
                                            By creating an account, you are agreeing to our
                                            <span class="text-pink-custom text-hover pointer">Terms of Service</span>
                                            and
                                            <span class="text-pink-custom text-hover pointer">Privacy Policy</span> .
                                            You also agree to receive product-related marketing emails from
                                            Heidi, which you can unsubscribe from at any time.
                                        </small>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>