
// import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']

})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  submitted = false;
  loginshowide = true;
  loading = false;

  constructor(private fb: FormBuilder, private router: Router, public auth: AuthService) { }

  get f1(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }
  ngOnInit(): void {
    if (this.auth.isLogin()) {
      if (this.auth.isSuperAdmin()) {
        this.router.navigate(['superadmin']);
      } else if (this.auth.isAdmin()) {
        this.router.navigate(['admin']);
      } else if (this.auth.isUser()) {
        this.router.navigate(['user']);
      }
    }
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      type: ['normal'],
    });
    // this.socialauth.authState.subscribe((user) => {
    //   let body = {
    //     username: user.name,
    //     email: user.email,
    //     type: 'social',
    //     meta: user,
    //   };
    //   this.loginshowide = true;
    //   this.callLoginAPI(body);
    // });
  }

  showPassword: boolean = false;
  password: string = '';
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  onSubmitLoginForm() {


    this.loading = true
    this.submitted = true;
    this.loginshowide = true;
    if (this.loginForm.invalid) {

      this.loginshowide = false;
      this.loading = false
    } else {
      this.loginForm.value.type = 'normal';
      this.callLoginAPI(this.loginForm.value);
    }
  }

  callLoginAPI(body: any) {
    this.auth.post('login', body).subscribe({
      next: (res: any) => {
        if (res.status == 200) {
          localStorage.setItem('heidiauthtoken', res.auth_token);
          localStorage.setItem('userdata', JSON.stringify(res.data.info));
          this.auth.setLoginStatus(true);
          this.auth.setuserRole(res.data.info.role_key);
          this.submitted = false;
          this.loginshowide = false;
          this.loading = false
          if (this.auth.isLogin()) {
            if (this.auth.isSuperAdmin()) {
              this.router.navigate(['superadmin']);
            } else if (this.auth.isAdmin()) {
              this.router.navigate(['admin']);
            } else if (this.auth.isUser()) {
              this.router.navigate(['user']);
            }
          }
        }
      },
      error: (err) => {
        Swal.fire({
          icon: 'error',
          text: err.error.data.message,
          position: 'top',
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
        this.submitted = false;
        this.loginshowide = false;
        this.loading = false
      },
    });
  }
}
