<div *ngIf="!chat.getpageLoader()">
    <h4 class="mobl-height">Personal prompts</h4>
    <div class="row pt-1 gap-0 g-2 ">
        <div class="col-lg-6">
            <label for="" class="mb-3 text-base">Search by</label>
            <div class="input-group input-group mb-3 input-search">
                <!-- <span class="input-group-text" id="inputGroup-sizing-sm"
          ><i class="bx bx-search"></i
        ></span> -->
                <input type="text" class="form-control form-control rounded" placeholder="Enter your search item"
                    aria-label="input" [(ngModel)]="search" (input)="reset()" aria-describedby="inputGroup-sizing" />
            </div>
        </div>
        <div class="col-lg-3 ">
            <label for="category" class="mb-2 text-base">Category</label>
            <select class="form-select form-select rounded" aria-label=".form-select example" id="category"
                [(ngModel)]="category_id" (change)="getPrompt()">
                <option selected>Please select category</option>
                <option *ngFor="let category of categories" [value]="category?._id">
                    {{ category?.name }}
                </option>
            </select>
            <div *ngIf="submitted && f['category_id'].errors" class="invalid-feedback">
                <div *ngIf="f['category_id'].errors['required']">
                    Category is required.
                </div>
            </div>
        </div>
        <div class="col-lg-3 d-flex align-items-center">
            <button type="button" class="btn btn-custom btn-sm w-100  mt-4 mt-lg-0  prompt-btn" data-bs-toggle="modal"
                data-bs-target="#examplecreateModal" data-bs-whatever="@getbootstrap">
                <i class="bx bx-plus-medical-circle fs-6 me-2"></i>Add new prompt
            </button>
        </div>
    </div>
    <div class="chat-detail-box ">
        <div class="row">
            <div class="d-flex align-items-center justify-content-center mt-5" *ngIf="!notFound">
                <h5 class="text-center">No Prompt Found</h5>
            </div>
            <div class="col-lg-4 mt-4" *ngFor="let prompt of prompts; let i = index">
                <div *ngIf="notFound">
                    <div class="card shadow-sm rounded-3 min-vh-100" style="min-height: 160px !important;"
                        (click)="selectPrompt(prompt, i)" [ngClass]="selected == i ? 'card-border' : 'hidden'">
                        <div class="card-body text-sm ">
                            <h5 class="card-title text-base fw-bold text-ellipsis">
                                {{ prompt?.title }}
                            </h5>
                            <p class="card-text cursor-pointer text-sm fw-light collapsible-content" role="button">
                                {{ prompt?.description }}
                            </p>
                        </div>
                        <div class="card-footer py-3 d-flex justify-content-between">
                            <small class="text-muted">{{ prompt?.createdAt | date : "dd-MM-yyyy" }}
                            </small>
                            <small class="text-muted d-flex justify-content-end gap-3" *ngIf="!prompt.check">
                                <!-- <i class="bx bx-bookmark-plus"></i> -->
                                <i class="bx bx-edit-alt ml-2 cursor-pointer " (click)="editPrompt(i, prompt)"></i>
                                <i class="bx bx-trash ms-2 cursor-pointer " (click)="delete(prompt)"></i>
                            </small>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<div *ngIf="chat.getpageLoader()">
    <div class="chat-height pb-5  mt-5 mt-lg-1" #scroll [scrollTop]="scroll.scrollHeight">
        <div class="">
            <div *ngFor="let chat of chats; let i = index">
                <div class="rounded-top  text-muted  px-md-2 px-1  d-flex flex-row-reverse gap-2 gap-lg-3 msg-color mb-3 mt-3 mt-lg-0"
                    *ngIf="i % 2 == 0" style="width: 90%; float: right;">
                    <div class="conversation-list d-flex">
                        <div class="dropdown">
                            <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <i class="bx bx-dots-vertical-rounded fs-3"></i>
                            </a>
                            <div class="dropdown-menu">
                                <a class="dropdown-item pointer" (click)="speech(chat?.message)">Speech
                                    <span class="icon rounded-circle ms-2 my-auto">
                                        <i class="bx bx-volume-full text-black me-1"></i>
                                    </span>

                                </a>
                                <a class="dropdown-item pointer" (click)="copy(chat?.message)">Copy
                                    <span class="icon rounded-circle ms-2 my-auto">
                                        <i class="bx bx-copy text-black me-1"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="ctext-wrap">
                            <div class="d-flex justify-content-end gap-2">
                                <p class=" mb-0 py-2 text-justify " style="white-space: pre-line !important; font-size: 0.9rem; font-weight: 600;
                                font-size: 0.9rem; font-weight: 600;">
                                    {{ chat?.message }}
                                </p>
                                <img src="{{this.chat.profilePic? this.auth.getapi() + this.chat.profilePic: profilePic}}"
                                    alt="" class="avatar-logo rounded-circle bg-transparent img-fluid me-2 objFit"
                                    style="height: 30px; width: 30px;" width="30px" />
                            </div>
                            <p class="chat-time small my-0 py-1 text-start">
                                {{ chat?.time }}
                            </p>
                        </div>

                    </div>
                </div>
                <div class="rounded-top my-3 text-muted   px-md-2 px-1 d-flex gap-2 gap-lg-3 msg-color my-2"
                    *ngIf="i % 2 != 0" style="width: 90%;">
                    <!-- <img src="{{ this.auth.getapi() + profilePic }}" alt=""
                    class="avatar-logo rounded-circle bg-transparent img-fluid ms-md-5 ms-2"/> -->
                    <!-- <p class="my-auto">{{ chat?.message }}</p> -->
                    <div class="conversation-list d-flex">
                        <div class="stext-wrap ">
                            <div class="d-flex gap-2">
                                <img src={{responsePic}} alt="" class="img-fluid mx-0 px-0 pb-2" style="height: 30px;">
                                <div>
                                    <div *ngIf="chat?.code">
                                        <div *ngFor="let m of chat?.message, let i = index">
                                            <p class="mb-0  text-justify" *ngIf="i%2==0"
                                                style="white-space: pre-line !important; font-size: 0.9rem; font-weight: 600;">
                                                <!--Text message-->
                                                {{ m }}
                                            </p>
                                            <div *ngIf="i%2!=0" class="bg-code p-2 my-2">
                                                <code class="m-2 text-justify code">  <!--code message-->
                                                {{ m }}
                                                </code>
                                            </div>
                                        </div>
                                    </div>
                                    <p class=" mb-0  text-justify" *ngIf="!chat?.code"
                                        style="white-space: pre-line !important; font-size: 0.9rem; font-weight: 600;">
                                        {{ chat?.message }}
                                    </p>
                                </div>
                            </div>
                            <div class="ml-30 search-links mt-3" *ngIf="chat?.links.length>0">
                                <h6 class="f-w-600 text-decoration-underline" style="color: #7267ef;">Suggestions:</h6>
                                <!-- <a target="_blank" [href]="google?.link" class="link shadow-sm mt-2"
                                    *ngFor="let google of chat?.links">
                                    <img class="link-favicon"
                                        [src]="'https://www.google.com/s2/favicons?domain=' + google?.displayLink">
                                    <span class="ms-3 f-w-600">{{ google.title }}</span>
                                </a> -->

                                <a target="_blank" [href]="google?.link" class="link my-1" title="{{ google.title }}"
                                    *ngFor="let google of chat?.links">
                                    <img class="link-favicon img-fluid"
                                        [src]="'https://www.google.com/s2/favicons?domain=' + google?.displayLink">
                                    <small class="ms-3 f-w-600">{{ google?.title }}</small>
                                </a>
                            </div>
                            <p class="small chat-time small my-0 py-2 text-end">
                                {{chat?.time}}
                            </p>
                        </div>
                        <div class="dropdown">
                            <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <i class="bx bx-dots-vertical-rounded fs-3"></i>
                            </a>
                            <div class="dropdown-menu">
                                <a class="dropdown-item pointer" (click)="speech(chat?.message)">Speech
                                    <span class="icon rounded-circle ms-2 my-auto">
                                        <i class="bx bx-volume-full text-black me-1"></i>
                                    </span>
                                </a>
                                <a class="dropdown-item pointer" (click)="copy(chat?.message)">Copy
                                    <span class="icon rounded-circle ms-2 my-auto">
                                        <i class="bx bx-copy text-black me-1"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-inline-flex float-end justify-content-end w-100" *ngIf="this.chat.getLoader()">
                <div
                    class="rounded-top float-end text-muted  px-md-2 px-1 ps-5 msg-color d-block p-l-50 gap-3 gap-lg-5 mb-3 ">
                    <div class="conversation-list d-flex">
                        <div class="dropdown">
                            <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <i class="bx bx-dots-vertical-rounded fs-3"></i>
                            </a>
                            <div class="dropdown-menu">
                                <a class="dropdown-item pointer" (click)="speech(this.chat.getMessage())">Speech
                                    <span class="icon rounded-circle ms-2 my-auto">
                                        <i class="bx bx-volume-full text-black me-1"></i>
                                    </span>

                                </a>
                                <a class="dropdown-item pointer" (click)="copy(this.chat.getMessage())">Copy
                                    <span class="icon rounded-circle ms-2 my-auto">
                                        <i class="bx bx-copy text-black me-1"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="ctext-wrap">
                            <div class="d-flex gap-2">
                                <!-- <img src="{{this.chat.profilePic? this.auth.getapi() + this.chat.profilePic: profilePic}}"
                            alt="" class="avatar-logo rounded-circle bg-transparent img-fluid me-2 objFit"
                            style="height: 30px; width: 30px;" width="30px" /> -->
                                <p class=" mb-0 py-1 text-justify"
                                    style="white-space: pre-line !important; font-size: 0.9rem; font-weight: 600;">
                                    {{ this.chat.getMessage() }}
                                </p>
                                <img src="{{ profilePic }}" alt=""
                                    class="avatar-logo rounded-circle bg-transparent img-fluid me-2 objFit"
                                    style="height: 30px; width: 30px;" width="30px" />
                            </div>
                            <small class="chat-time small my-0 py-1 text-end">
                                {{ message_time }}
                            </small>

                        </div>
                    </div>
                </div>
            </div>
            <div class="text-blue bgResponse py-3 mx-3 pe-4 d-flex w-50" *ngIf="this.chat.getLoader()">
                <img src={{responsePic}} alt=""
                    class="img-fluid ms-3 px-0 avatar-logo rounded-circle bg-transparent me-2 objFit"
                    style="height: 30px; width: 30px;">
                <div class="d-flex gap-3  ps-2 py-3">
                    <div class="spinner-grow spinner-grow-sm " role="status" aria-hidden="true">
                        <span class="visually-hidden text-dark">Loading...</span>
                    </div>
                    <div class="spinner-grow spinner-grow-sm " role="status">
                        <span class="visually-hidden text-dark">Loading...</span>
                    </div>
                    <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="visually-hidden text-dark">Loading...</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<app-chatbox [childData]="id" [promptData]="promptId"></app-chatbox>


<!-- prompt modal  -->
<div class="modal fade" id="examplecreateModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Create a new prompt</h5>
                <button #closebutton type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="promptForm" (ngSubmit)="submitForm()">
                    <div class="mb-3">
                        <label for="title" class="col-form-label">Prompt title</label>
                        <input type="text" class="form-control" formControlName="title" id="title" [ngClass]="{
                'is-invalid': submitted && f['title'].errors
              }" placeholder="What is prompt about? E.g.. Facebook ad copy" />
                        <div *ngIf="submitted && f['title'].errors" class="invalid-feedback">
                            <div *ngIf="f['title'].errors['required']">
                                Prompt title is required.
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="description" class="col-form-label">Prompt description</label>
                        <input type="text" class="form-control" id="description" formControlName="description"
                            placeholder="Describe what your prompt does, e.g., Compelling ads for FB…" [ngClass]="{
                'is-invalid': submitted && f['description'].errors
              }" />
                        <div *ngIf="submitted && f['description'].errors" class="invalid-feedback">
                            <div *ngIf="f['description'].errors['required']">
                                Prompt description is required.
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="d-flex justify-content-between">
                            <label for="template" class="col-form-label">Prompt template</label>
                            <p class="text-muted mb-2">1000 characters left</p>
                        </div>
                        <textarea class="form-control input-search"
                            placeholder="Write compelling Facebook ad copy for [target audience] with the goal to [your purpose] for [your product name and description]"
                            id="template" formControlName="template" [ngClass]="{
                'is-invalid': submitted && f['template'].errors
              }"></textarea>
                        <div *ngIf="submitted && f['template'].errors" class="invalid-feedback">
                            <div *ngIf="f['template'].errors['required']">
                                Prompt template is required.
                            </div>
                        </div>
                        <p class="fw-bold mt-2 text-black text-sm">
                            <a class="ml-1 mr-1" target="_blank" href="#"> Click here </a>
                            to learn more about how to create a prompt.
                        </p>

                        <div class="row">
                            <div class="col-md-6">
                                <label for="category" class="">Category</label>
                                <select class="form-select form-select-sm" aria-label=".form-select-sm example"
                                    id="category" formControlName="category_id" [ngClass]="{
                    'is-invalid': submitted && f['category_id'].errors
                  }">
                                    <option selected>Please select category</option>
                                    <option *ngFor="let category of categories" [value]="category?._id">
                                        {{ category?.name }}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f['category_id'].errors" class="invalid-feedback">
                                    <div *ngIf="f['category_id'].errors['required']">
                                        Category is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="" class="">Language</label>
                                <select formControlName="language_id" class="form-select form-select-sm"
                                    aria-label=".form-select-sm example" id="language_id" [ngClass]="{
                    'is-invalid': submitted && f['language_id'].errors
                  }">
                                    <option value="">Select a language</option>
                                    <option *ngFor="let language of languages" [value]="language?._id">
                                        {{ language.name }}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f['language_id'].errors" class="invalid-feedback">
                                    <div *ngIf="f['language_id'].errors['required']">
                                        Language is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-check mb-4 d-none">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                            (change)="check($event)" />
                        <label class="form-check-label text-sm fw-bold text-black" for="flexCheckDefault">
                            Share prompt template publicly
                        </label>
                    </div>
                    <button class="w-100 mb-4 btn btn-custom btn-sm py-2 loader-button" type="submit">
                        <span class="btn-loader" *ngIf="btnload"></span>
                        <i class="bx bx-plus-medical-circle fs-6 me-2"></i>Submit new prompt
                    </button>
                    <!-- <button type="submit" class="btn btn-custom mb-4 w-100">
            Submit new prompt
          </button> -->
                </form>

                <p class="text-sm">
                    <span class="pe-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9 11.25C13.1421 11.25 16.5 9.73896 16.5 7.875C16.5 6.01104 13.1421 4.5 9 4.5C4.85786 4.5 1.5 6.01104 1.5 7.875C1.5 9.73896 4.85786 11.25 9 11.25Z"
                                stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round">
                            </path>
                            <path
                                d="M1.5 7.875V11.625C1.5 13.4906 4.85625 15 9 15C13.1438 15 16.5 13.4906 16.5 11.625V7.875"
                                stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round">
                            </path>
                            <path d="M6 10.9688V14.7188" stroke="currentColor" stroke-width="1.4" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                            <path
                                d="M16.5 9.06543C19.9219 9.38418 22.5 10.7436 22.5 12.3748C22.5 14.2404 19.1438 15.7498 15 15.7498C13.1625 15.7498 11.475 15.4498 10.1719 14.9623"
                                stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round">
                            </path>
                            <path
                                d="M7.5 14.9344V16.125C7.5 17.9906 10.8562 19.5 15 19.5C19.1438 19.5 22.5 17.9906 22.5 16.125V12.375"
                                stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round">
                            </path>
                            <path d="M18 15.4688V19.2188" stroke="currentColor" stroke-width="1.4"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M12 10.9688V19.2188" stroke="currentColor" stroke-width="1.4"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </span>
                    Selected prompts for the editor's choice will receive 20k credits
                </p>
            </div>
        </div>
    </div>
</div>

<button type="button" #openprompt data-bs-toggle="modal" data-bs-target="#exampleModal1" hidden></button>

<!--Edit  prompt modal  -->
<div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Edit prompt</h5>
                <button #closebutton1 type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="promptEditForm" (ngSubmit)="oneditform()">
                    <div class="mb-3">
                        <label for="title" class="col-form-label">Prompt title</label>
                        <input type="text" class="form-control" formControlName="title" id="title" [ngClass]="{
                'is-invalid': submitted && f1['title'].errors
              }" placeholder="What is prompt about? E.g.. Facebook ad copy" />
                        <div *ngIf="submitted && f1['title'].errors" class="invalid-feedback">
                            <div *ngIf="f1['title'].errors['required']">
                                Prompt title is required.
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="description" class="col-form-label">Prompt description</label>
                        <input type="text" class="form-control" id="description" formControlName="description"
                            placeholder="Describe what your prompt does, e.g., Compelling ads for FB…" [ngClass]="{
                'is-invalid': submitted && f1['description'].errors
              }" />
                        <div *ngIf="submitted && f1['description'].errors" class="invalid-feedback">
                            <div *ngIf="f1['description'].errors['required']">
                                Prompt description is required.
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="d-flex justify-content-between">
                            <label for="template" class="col-form-label">Prompt template</label>
                            <p class="text-muted mt-2">1000 characters left</p>
                        </div>
                        <textarea class="form-control input-search"
                            placeholder="Write compelling Facebook ad copy for [target audience] with the goal to [your purpose] for [your product name and description]"
                            id="template" formControlName="template" [ngClass]="{
                'is-invalid': submitted && f1['template'].errors
              }"></textarea>
                        <div *ngIf="submitted && f1['template'].errors" class="invalid-feedback">
                            <div *ngIf="f1['template'].errors['required']">
                                Prompt template is required.
                            </div>
                        </div>
                        <p class="fw-bold mt-2 text-black text-sm">
                            <a class="ml-1 mr-1" target="_blank" href="#"> Click here </a>
                            to learn more about how to create a prompt.
                        </p>

                        <div class="row">
                            <div class="col-md-6">
                                <label for="category" class="mb-3">Category</label>
                                <select class="form-select form-select-sm" aria-label=".form-select-sm example"
                                    id="category" formControlName="category_id" [ngClass]="{
                    'is-invalid': submitted && f1['category_id'].errors
                  }">
                                    <option selected>Please select category</option>
                                    <option *ngFor="let category of categories" [value]="category?._id">
                                        {{ category?.name }}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f1['category_id'].errors" class="invalid-feedback">
                                    <div *ngIf="f1['category_id'].errors['required']">
                                        Category is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="" class="mb-3">Language</label>
                                <select formControlName="language_id" class="form-select form-select-sm"
                                    aria-label=".form-select-sm example" id="language_id" [ngClass]="{
                    'is-invalid': submitted && f1['language_id'].errors
                  }">
                                    <option value="">Select a language</option>
                                    <option *ngFor="let language of languages" [value]="language?._id">
                                        {{ language.name }}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f1['language_id'].errors" class="invalid-feedback">
                                    <div *ngIf="f1['language_id'].errors['required']">
                                        Language is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-check mb-4 d-none">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                            (change)="check($event)" />
                        <label class="form-check-label text-sm fw-bold text-black" for="flexCheckDefault">
                            Share prompt template publicly
                        </label>
                    </div>
                    <button class="w-100 mb-4 loader-button btn btn-custom" type="submit">
                        <span class="btn-loader" *ngIf="btnload"></span>
                        <i class="bx bx-edit fs-6 me-2"></i>Edit prompt
                    </button>
                    <!-- <button type="submit" class="btn btn-custom mb-4 w-100">
            Submit new prompt
          </button> -->
                </form>

                <p class="text-sm">
                    <span class="pe-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9 11.25C13.1421 11.25 16.5 9.73896 16.5 7.875C16.5 6.01104 13.1421 4.5 9 4.5C4.85786 4.5 1.5 6.01104 1.5 7.875C1.5 9.73896 4.85786 11.25 9 11.25Z"
                                stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round">
                            </path>
                            <path
                                d="M1.5 7.875V11.625C1.5 13.4906 4.85625 15 9 15C13.1438 15 16.5 13.4906 16.5 11.625V7.875"
                                stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round">
                            </path>
                            <path d="M6 10.9688V14.7188" stroke="currentColor" stroke-width="1.4" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                            <path
                                d="M16.5 9.06543C19.9219 9.38418 22.5 10.7436 22.5 12.3748C22.5 14.2404 19.1438 15.7498 15 15.7498C13.1625 15.7498 11.475 15.4498 10.1719 14.9623"
                                stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round">
                            </path>
                            <path
                                d="M7.5 14.9344V16.125C7.5 17.9906 10.8562 19.5 15 19.5C19.1438 19.5 22.5 17.9906 22.5 16.125V12.375"
                                stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round">
                            </path>
                            <path d="M18 15.4688V19.2188" stroke="currentColor" stroke-width="1.4"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M12 10.9688V19.2188" stroke="currentColor" stroke-width="1.4"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </span>
                    Selected prompts for the editor's choice will receive 20k credits
                </p>
            </div>
        </div>
    </div>
</div>