import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss']
})
export class UserlistComponent {
  // users : any = new Array(5)
  search = '';
  users: any = [];
  id: any;
  p = 0;
  l = 200;
  total = 0;
  loaded = 0;
  found = false;
  ld = true;
  lm = true;
  loading = false;
  pag: number = 1;
  constructor(private auth: AuthService) { }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers() {
    // this.loading = true
    this.ld = true;
    this.lm = false;
    this.found = false;
    this.p++;
    let q = '';
    if (this.search) {
      q =
        'user?page=' +
        this.p +
        '&limit=' +
        this.l +
        '&action=list' +
        '&search=' +
        this.search;
    } else {
      q = 'user?page=' + this.p + '&limit=' + this.l + '&action=list';
    }
    this.auth.get(q).subscribe({
      next: (res) => {
        if (res.status == 200) {

          if (this.p == 1) {
            this.users = res.data.users;
            this.loaded = parseInt(res.data.limit);
          } else {
            for (var i = 0; i < res.data.users.length; i++) {
              this.users.push(res.data.users[i]);
              this.loaded++;
            }
          }
          this.total = parseInt(res.data.total_count);
          this.ld = false;
          this.lm = true;
          if (this.loaded >= this.total) {
            this.lm = this.ld = false;
          }

          // this.loading = false;
        }
        if (this.users.length > 0) {
          this.userData = this.users.slice(0, 5);
        }
      },
      error: (err) => {
        this.lm = this.ld = false;
        this.found = true;
        this.users = []
        this.userData = []
      },
    });
  }

  userData: any[] = [];

  showMore() {
    let libLength = this.userData.length + 5;
    if (libLength > this.users.length) {
      libLength = this.users.length;
    }
    this.userData = this.users.slice(0, libLength);
  }

  delete(ind: any) {
    let id = ind;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete the user? This cannot be undo.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;

        this.auth.del('user/' + id, '').subscribe({
          next: (res: any) => {
            if (res.status == 200) {
              this.reset();
              Swal.fire({
                icon: 'success',
                position: 'top',
                toast: true,
                title: 'Success',
                text: res.data.message,
                showConfirmButton: false,
                timer: 2500,
              });
              this.loading = false;
            }
          },
          error: (err) => {
            this.loading = false;

          },
        });
      }
    });
  }

  reset() {
    this.found = false;
    this.p = 0;
    this.l = 50;
    this.total = 0;
    this.loaded = 0;
    // this.loading = false;
    this.users = [];
    this.getUsers();
  }
}
