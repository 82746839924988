<div class="mt-2 mb-3">
    <div class="d-flex justify-content-between">
        <p class="h3">Personal Information</p>
        <!-- <div class="text-end">
            <button class="btn btn-danger btn-sm rounded">
                <i class="bx bx-trash"></i> Delete Account
            </button>
        </div> -->
    </div>

    <div>
        <div class="mb-3 row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="" class="form-label small">First Name</label>
                    <input type="text" class="form-control" placeholder="First Name" [(ngModel)]="fname" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="" class="form-label small">Last Name</label>
                    <input type="text" class="form-control" placeholder="Last Name" [(ngModel)]="lname" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="phone" class="form-label small">Phone Number</label>
                    <input type="text" class="form-control" placeholder="Phone Number" [(ngModel)]="phone" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="age" class="form-label small">Age</label>
                    <input type="text" class="form-control" placeholder="Age" [(ngModel)]="age" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="country" class="form-label small">Country</label>
                    <input type="text" class="form-control" placeholder="Country" [(ngModel)]="country" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="address" class="form-label small">Address</label>
                    <input type="text" class="form-control" placeholder="Address" [(ngModel)]="address" />
                </div>
            </div>

            <div class="row align-items-end" *ngIf="!this.auth.isSuperAdmin()">
                <p class="h3 my-2 mb-2">Other Information</p>
                <div [ngClass]="subtextfield ? 'col-6' : 'col-12'">
                    <label for="" class="form-label text-muted small">Why did you subscribe to us?</label>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm example" id="subscribe"
                        [(ngModel)]="subscribeSelect" (ngModelChange)="onText()" [ngModelOptions]="{standalone: true}">
                        <option value="" selected>Select Any</option>
                        <option *ngFor=" let subscribe of subscription" [value]="subscribe?.name">
                            {{ subscribe?.name }}
                        </option>
                    </select>

                </div>
                <div class="col-6" *ngIf="subtextfield">
                    <div class="form-group">
                        <input type="text" class="form-control" [(ngModel)]="sub" placeholder="Write you text here"
                            [ngModelOptions]="{standalone: true}">
                    </div>
                </div>
                <div [ngClass]="exptextfield ? 'col-6' : 'col-12'">
                    <label for="" class="form-label text-muted small">What are your expectations?</label>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm example" id="expect"
                        [ngModelOptions]="{standalone: true}" [(ngModel)]="expectSelect" (ngModelChange)="onText()">
                        <option value="" selected>Select Any</option>
                        <option *ngFor="let expect of expectation" [value]="expect?.name">
                            {{ expect?.name }}
                        </option>
                    </select>
                </div>
                <div class="col-6" *ngIf="exptextfield">
                    <div class="form-group">
                        <input type="text" class="form-control" [(ngModel)]="exp" placeholder="Write your text here"
                            [ngModelOptions]="{standalone: true}">
                    </div>
                </div>
                <div class="col-12">
                    <div class="">
                        <label for="" class="form-label text-muted small">Who are you?</label>
                        <select class="form-select form-select-sm" aria-label=".form-select-sm example" id="who"
                            [ngModelOptions]="{standalone: true}" [(ngModel)]="whoSelect">
                            <option value="" selected>Select Any</option>
                            <option *ngFor="let who of who_are_you" [value]="who?.name">
                                {{ who?.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div [ngClass]="fieldtextfield ? 'col-6' : 'col-12'">
                    <label for="" class="form-label text-muted small">Field of activity?</label>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm example" id="domain"
                        [(ngModel)]="domainSelect" [ngModelOptions]="{standalone: true}" (ngModelChange)="onText()">
                        <option value="" selected>Select Any</option>
                        <option *ngFor="let domain of domain" [value]="domain?.name">
                            {{ domain?.name }}
                        </option>
                    </select>
                </div>
                <div class="col-6" *ngIf="fieldtextfield">
                    <div class="form-group">
                        <input type="text" class="form-control" [(ngModel)]="fie" placeholder="Write Your text here"
                            [ngModelOptions]="{standalone: true}">
                    </div>
                </div>
                <div [ngClass]="heardtextfield ? 'col-6' : 'col-12'">
                    <label for="" class="form-label text-muted small">How did you hear about us?</label>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm example" id="heard_about"
                        [(ngModel)]="heardAboutSelect" (ngModelChange)="onText()" [ngModelOptions]="{standalone: true}">
                        <option value="" selected>Select Any</option>
                        <option *ngFor="let heard_about of heard_about" [value]="heard_about?.name">
                            {{ heard_about?.name }}
                        </option>
                    </select>
                </div>
                <div class="col-6" *ngIf="heardtextfield">
                    <div class="form-group">
                        <input type="text" class="form-control" [(ngModel)]="hea" placeholder="Write your text here"
                            [ngModelOptions]="{standalone: true}">
                    </div>
                </div>

                <div class="col-12">
                    <div class="">
                        <label for="" class="form-label text-muted small">Size of the company?</label>
                        <select class="form-select form-select-sm" aria-label=".form-select-sm example" id="size"
                            [(ngModel)]="sizeSelect" [ngModelOptions]="{standalone: true}">
                            <option value="" selected>Select Any</option>
                            <option *ngFor="let size of size" [value]="size?.name">
                                {{ size?.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div [ngClass]="postextfield ? 'col-6' : 'col-12'">
                    <label for="" class="form-label text-muted small">Position?</label>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm example" id="position"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="onText()" [(ngModel)]="positionSelect">
                        <option value="" selected>Select Any</option>
                        <option *ngFor="let position of positions" [value]="position?.name">
                            {{ position?.name }}
                        </option>
                    </select>

                </div>
                <div class="col-6" *ngIf="postextfield">
                    <div class="form-group">
                        <input type="text" class="form-control" [(ngModel)]="pos" placeholder="Write your text here"
                            [ngModelOptions]="{standalone: true}">
                    </div>
                </div>
            </div>
        </div>
        <div class="text-end">
            <button class="btn btn-custom d-inline-flex gap-1 " (click)="updateInfo()">
                <i class="bx bx-edit my-auto fs-6"></i> Save Information
            </button>
        </div>
    </div>
</div>

<div class="loader-container" *ngIf="loading">
    <div class="loader-logo"></div>
</div>

<!-- <hr class="my-4" /> -->
<!-- <div class="my-2 row">
        <div class="col-12">
          <p class="h3 float-start">Email Address</p>
        </div>
      </div> -->
<!-- <div class="row mb-3">
        <div class="col-12">
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              [(ngModel)]="email"
              readonly
              name=""
              id=""
            />
          </div>
        </div>
      </div>
      <div class="text-end d-none">
        <button class="btn btn-custom">Update Email</button>
      </div> -->