import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject, tap, catchError } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
declare var webkitSpeechRecognition: any;
declare var google: any;


@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private textSubject = new Subject<string>();
  recognition = new webkitSpeechRecognition() ;
  selectedLanguage: any;
  stopped = false;
  text = '';
  temp = '';
  userId: any;
  chatId: any = '';
  first_name: any;
  last_name: any;
  full_name: any;
  profilePic: any;
  email: any;
  phone: any
  transcript = ''
  words: string | number = '';

  triggerFunction!: () => void;

  constructor(private auth: AuthService, private http: HttpClient) {
    // this.init()
  }

  destroy() {
    this.recognition = new webkitSpeechRecognition()
  }

  ngOnChanges(): void {
    this.getData()
  }

  private clickedTextSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  clickedText$: Observable<string> = this.clickedTextSubject.asObservable();

  setClickedText(text: string): void {
    this.clickedTextSubject.next(text);
  }


  formatMessage(msg: any) {
    let backtickRegex = /```([^`]+)```/g;
    let responseParts = msg.split('```');
    let code = [];
    let text = [];
    for (let i = 0; i < responseParts.length; i++) {
      if (i % 2 === 0) {
        text.push(responseParts[i].trim());
      } else {
        code.push(responseParts[i].trim());
      }
    }

    return {
      code: code,
      text: text
    };
  }


  googleTranslate() {
    // document.getElementsByTagName("h1")[0].innerHTML='<div id="google_translate_element"></div>';
    new google.translate.TranslateElement(
      { pageLanguage: 'en' },
      'google_translate_element'
    );

  }

  init() {
    this.recognition.interimResults = true;
    // this.recognition.continuous = true;
    this.recognition.lang = this.selectedLanguage;
    this.recognition.addEventListener(
      'result',
      (e:any) => {
        if(e.results[0].isFinal) {
          this.transcript = e.results[0][0].transcript
          this.temp = this.transcript;
          // this.words=transcript
          this.setText(this.transcript, true);
        } else {
          this.setText(e.results[0][0].transcript);
        }
      }
    );
    
  }
  getDetail() {
    this.auth.get('user?action=self').subscribe({
      next: (res) => {
        this.selectedLanguage = res.data.user?.recognition;
        this.init();
      },
      error: (err) => {
      },
    });
  }
  
  setText(text: string, end = false) {
    let b = JSON.stringify({
      "text": text,
      "end": end
    })
    this.textSubject.next(b);
  }

  getText(): Observable<string> {
    return this.textSubject.asObservable();
  }

  start() {
    this.text = '';
    this.transcript = ''
    this.temp = ''
    this.stopped = false;
    this.recognition.start();

    this.recognition.addEventListener('end', (res:any) => {
      if (this.stopped) {
        this.recognition.stop();
        this.transcript = ''
      } else {
        //this.wordConcat();
        this.recognition.start();
      }
    });
  }

  stop() {
    this.stopped = true;
    this.transcript = ''
    this.temp = ''
    //this.wordConcat();
    this.recognition.stop();
    // this.recognition.removeEventListener('result', null)
    // this.recognition.removeEventListener('end', null)
  }

  wordConcat() {
    this.text = this.text + ' ' + this.temp + '.';
    this.words = this.text;
    this.temp = '';

  }

  gettext() {
    return this.text;
  }

  //speech to text ends here

  getData() {

    // this.auth.get('user?action=self').subscribe({
    //   next: (res) => {
    //     this.first_name = res.data.user?.first_name;
    //     this.last_name = res.data.user?.last_name;
    //     this.full_name = this.first_name + " " + this.last_name;
    //     this.email = res.data.user?.email;
    //     this.phone = res.data.user.meta?.phone;
    //     this.userId = res.data.user?._id;
    //     this.profilePic = res.data.user?.picture
    //       ? this.auth.getapi() + res.data.user.picture
    //       : this.profilePic;
    //   },
    //   error: (err) => { },
    // });
    const data = localStorage.getItem('userdata');
    if (data) {
      const userData = JSON.parse(data);
      this.userId = userData.id;
      this.email = userData.email;
      this.first_name = userData.first_name;
      this.last_name = userData.last_name;
      this.full_name = this.first_name + ' ' + this.last_name;
      this.profilePic = userData?.picture;
      if (!this.profilePic) {
        this.profilePic = './assets/images/user.png'
      }
    }
  }



  setUserId(userId: string) {
    this.userId = userId;
  }
  getUserId() {
    return this.userId

  }
  setChatId(text: any) {
    this.chatId = text;
  }

  getChatId() {
    return this.chatId;
  }

  setWords(w: any) {
    this.words = w;
  }
  getWords() {
    return this.words;
  }

  fields: any;
  setFields(obj: any) {
    this.fields = obj;
  }

  getFields() {
    return this.fields;
  }

  prompt: any;
  setPrompt(x: any) {
    this.prompt = x;
  }

  getPrompt() {
    return this.prompt;
  }

  lastmessage: any;
  setMessage(text: any) {
    this.lastmessage = text;
  }

  getMessage() {
    return this.lastmessage;
  }

  speechtext = false
  setSpeechText(x: any) {
    if (x == true) {
      this.speechtext = true;
    } else {
      this.speechtext = false;
    }
  }

  getSpeechText() {
    return this.speechtext;
  }

  loader = false
  setLoader(x: any) {
    if (x == true) {
      this.loader = true;
    } else {
      this.loader = false;
    }
  }
  getLoader() {
    return this.loader;
  }

  pageLoader = false;
  setPageLoader(load: boolean) {
    this.pageLoader = load;
  }

  getpageLoader() {
    return this.pageLoader;
  }
}