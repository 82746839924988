import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { DatePipe } from '@angular/common';

import { environment } from 'src/environments/environment';

import { NgProgressModule, NG_PROGRESS_CONFIG } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';


import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';


import { SitelayoutComponent } from './layout/sitelayout/sitelayout.component';
import { ApplayoutComponent } from './layout/applayout/applayout.component';
import { ChatlayoutComponent } from './layout/chatlayout/chatlayout.component';

import { AppheaderComponent } from './modules/appheader/appheader.component';
import { AppsidebarComponent } from './modules/appsidebar/appsidebar.component';
import { ChatHeaderComponent } from './modules/chatheader/chatheader.component';
import { ChatsidebarComponentr } from './modules/chatsidebar/chatsidebar.component';


import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotPasswordComponent } from './pages/forgotpassword/forgotpassword.component';

import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthService } from './auth/auth.service'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { SuperadminModule } from './superadmin/superadmin.module';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ChangepasswordComponent } from './pages/changepassword/changepassword.component';



export function initConfiguration(auth: AuthService) {
  return () =>
    auth.validateToken().then((status: any) => {
      if (status) {
        return true;
      } else {
        return false;
      }
    });
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  declarations: [
    AppComponent,
    SitelayoutComponent,
    ApplayoutComponent,
    ChatlayoutComponent,
    AppheaderComponent,
    AppsidebarComponent,
    AppsidebarComponent,
    ChatHeaderComponent,
    ChatsidebarComponentr,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ChangepasswordComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule,
    NgProgressModule,
    NgProgressRouterModule,
    NgProgressHttpModule,
    CKEditorModule,
    SuperadminModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json'),
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    Title,
    DatePipe,
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfiguration,
      multi: true,
      deps: [AuthService],
    },
    // AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
