<!-- <app-chatsidebar></app-chatsidebar>
<app-chatheader></app-chatheader>
<div class="pc-container">
    <div class="pcoded-content">
        <router-outlet></router-outlet>
    </div>
</div> -->


<app-chatheader></app-chatheader>
<app-chatsidebar></app-chatsidebar>

<div class="bg-white pc-container" [ngClass]="this.auth.getsidebarstate() ? 'zero2' : ''">
    <div class="pcoded-content bg-white">
        <router-outlet></router-outlet>
    </div>
</div>