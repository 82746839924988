import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettingComponent } from '../shared/setting/setting.component';
import { PackagesComponent } from './packages/packages.component';
import { RegisterCompanyComponent } from './register-company/register-company.component';
import { UserlistComponent } from './userlist/userlist.component';
import { ApplayoutComponent } from '../layout/applayout/applayout.component';
import { CategoryComponent } from './category/category.component';
import { LibraryComponent } from './library/library.component';
import { TemplateComponent } from './template/template.component';
import { FieldsComponent } from './fields/fields.component';
import { SuperadminUserComponent } from './superadmin-user/superadmin-user.component';


const routes: Routes = [
  {
    path: '',
    component: ApplayoutComponent,
    children: [
      {
        path: '',
        component: UserlistComponent,
        data: { title: 'User Listing' }
      },
      {
        path: 'userlist',
        component: UserlistComponent,
        data: { title: 'User Listing' },
      },
      {
        path: 'users',
        component: SuperadminUserComponent,
        data: { title: 'SuperAdmin User' },
      },
      {
        path: 'companylist',
        component: RegisterCompanyComponent,
        data: { title: 'Company Listing' },
      },
      {
        path: 'settings',
        component: SettingComponent,
        data: { title: 'Settings' },
      },
      {
        path: 'packages',
        component: PackagesComponent,
        data: { title: 'Packages' },
      },
      {
        path: 'category',
        component: CategoryComponent,
        data: { title: 'Categories' },
      },
      {
        path: 'library',
        component: LibraryComponent,
        data: { title: 'Libraries' },
      },
      {
        path: 'template',
        component: TemplateComponent,
        data: { title: 'Templates' },
      },
      {
        path: 'field',
        component: FieldsComponent,
        data: { title: 'Fields' },
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SuperadminRoutingModule { }
