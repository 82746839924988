import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, map, Observable, retry, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import Swal from 'sweetalert2';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private route: Router, private auth: AuthService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retry(0),
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        let errorrstatus = '';
        if (error.error instanceof ErrorEvent) {
          errorrstatus = `Error Code: ${error.status}`;
          errorMsg = `Message: ${error.error.message}`;
        } else {
          if (error.status == 500) {
            errorrstatus = `Error Code: 500`;
            errorMsg = `Message: Internal Server Error`;
            Swal.fire({
              icon: 'error',
              title: errorrstatus,
              text: errorMsg,
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 5000
            });
          } else if (error.status == 0) {
            errorrstatus = `Error sending request`;
            errorMsg = `You are sending an invalid request.`;
            Swal.fire({
              icon: 'error',
              title: errorrstatus,
              text: errorMsg,
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 5000
            });
          } else if (error.status == 401) {
            errorrstatus = `Error Code: ${error.status}`;
            errorMsg = `${error.error.data.message}`;
            Swal.fire({
              icon: 'error',
              title: errorrstatus,
              text: errorMsg,
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 5000
            });
            localStorage.clear();
            this.auth.setLoginStatus(false);
            setTimeout(() => {
              this.route.navigate(['']);
              // window.location.reload();
            }, 2000);
          }
        }
        return throwError(() => error);
      })
    );;
  }
}
