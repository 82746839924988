<!-- <app-header [title]="pageName"></app-header> -->
<div class="px-3 px-lg-4 pb-5 pt-5 pt-md-3 mt-5 mt-md-0 mobl-height h-100">
    <h4 class="bold  mb-2">Photo Chat</h4>

    <a onclick="history.back()" class="btn px-0 d-flex align-items-start fw-semibold">
        <span><i class="bx bx-chevron-left me-1 fs-4"></i></span>Back</a>
    <div class="p-4 rounded shadow-sm bg-white">
        <div class="mb-2">
            <p class="mb-0 text-sm">
                Start with a detailed description
                <span class="ms-2">
                    <button class="btn btn-outline-primary btn-sm rounded-pill border-1 border py-1 px-2">Try an
                        example</button>
                </span>
            </p>
        </div>
        <div class="search-box mb-3 flex-grow-1 me-1 p-0">
            <form>
                <div class="position-relative d-flex form-control rounded py-0">
                    <input type="text" class="w-100 border-0 ps-2" placeholder=" What do you want to create?" />
                    <button class="btn btn-custom btn-sm rounded py-1" type="submit">Generate</button>
                </div>
            </form>
        </div>
        <p class="text-blue smaller">1 Generation (2 images) = 100 premium words</p>
        <p class="text-blue mt-1 smaller">
            Square (512 x 512) <span class="text-muted">result</span> = 2
            <span class="text-muted">number of images</span>
        </p>
    </div>
    <div class="card mt-5 pt-2 pb-0 mb-0 shadow">
        <div class="card-body">
            <!-- Nav tabs -->
            <ul class="nav nav-pills nav-justified" role="tablist">
                <li class="nav-item waves-effect waves-light" role="presentation">
                    <a class="nav-link active" data-bs-toggle="tab" href="#home-1" role="tab" aria-selected="true">
                        <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                        <span class="d-none d-sm-block"> Best Generations</span>
                    </a>
                </li>
                <li class="nav-item waves-effect waves-light" role="presentation">
                    <a class="nav-link" data-bs-toggle="tab" href="#profile-1" role="tab" aria-selected="false"
                        tabindex="-1">
                        <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                        <span class="d-none d-sm-block"> Recent Generations</span>
                    </a>
                </li>

            </ul>
            <!-- Tab panes -->
            <div class="tab-content py-4 text-muted">
                <div class="tab-pane active" id="home-1" role="tabpanel">
                    <div class="row">
                        <div class="col-12">
                            <div class="grid-container">
                                <div class="column mb-2" *ngFor="let image of imageFiles">
                                    <img src="{{ image }}" class="rounded" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="profile-1" role="tabpanel">
                    <p class="mb-0">
                        Food truck fixie locavore, accusamus mcsweeney's marfa nulla
                        single-origin coffee squid. Exercitation +1 labore velit, blog
                        sartorial PBR leggings next level wes anderson artisan four loko
                        farm-to-table craft beer twee. Qui photo booth letterpress,
                        commodo enim craft beer mlkshk aliquip jean shorts ullamco ad
                        vinyl cillum PBR. Homo nostrud organic, assumenda labore
                        aesthetic magna 8-bit.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>