
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatService } from 'src/app/services/chat.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  pageName = 'Library';

  str = 'Premium quality, up to 3000-word SEO-friendly blog articles in 3 steps. Choose keywords, tone, CTAs, POV, and length with this new upgrade.';
  library: any = [];
  categories: any = [];
  projects: any = [];
  favorate: any[] = [];
  projectfound = false;
  btnload = false;
  noTemp = false;
  cards1: any = new Array(3);
  search: any;
  category_id: any;

  projectForm!: FormGroup;
  submitted = false;
  template: any;
  // isCheck: any;
  plan: any;


  get f(): { [key: string]: AbstractControl } {
    return this.projectForm.controls;
  }

  @ViewChild('closebutton') closebutton: any;
  constructor(public auth: AuthService, private fb: FormBuilder, private chat: ChatService, private router: Router) { }

  ngOnInit(): void {
    sessionStorage.removeItem('longtext');
    this.projectForm = this.fb.group({
      name: ['', Validators.required],
    });
    this.packageInfo()
    // const favorateItems = localStorage.getItem('favorateItems');

    // if (favorateItems) {
    //   this.favorate = JSON.parse(favorateItems);

    //   this.favorate.forEach(item => {
    //     const isCheckedValue = item.isChecked;
    //     this.isCheck = isCheckedValue;
    //   });
    // }
    this.chat.getData();
    this.getLibrary();
    this.getCategory();
    this.getProjects();
  }
  packageInfo() {
    this.auth.get('form?action=latest_plan').subscribe({
      next: (res) => {
        this.auth.plan = res.data.plan.plan_type;
      },
      error: (err) => {
      },
    });
  }


  getLibrary() {
    let q = '';
    if (this.search) {
      q = 'library?search=' + this.search + '&limit=100';
    } else if (this.category_id) {
      q = 'library?category_id=' + this.category_id;
    } else {
      q = 'library?limit=100';
    }
    this.auth.get(q).subscribe({
      next: (res) => {
        this.noTemp = true;
        this.library = res.data.data;
        // this.library.forEach((item: any) => {
        //   item.isChecked = false;
        //   if (this.bookmarkedLibrary.some(bookmarkedItem => bookmarkedItem._id === item._id)) {
        //     item.isChecked = true;
        //   }
        // });

        this.library.sort((a: any, b: any) => a.name.localeCompare(b.name));
      },
      error: (err) => {
        this.noTemp = false;
      },
    });
  }

  // toggleFavorite(item: any): void {
  //   item.isChecked = !item.isChecked;
  //   const favorateIndex = this.favorate.findIndex(fav => fav._id === item._id);
  //   if (item.isChecked && favorateIndex === -1) {
  //     this.favorate.push({ ...item, isChecked: true });
  //   } else if (!item.isChecked && favorateIndex !== -1) {
  //     this.favorate.splice(favorateIndex, 1);
  //   }
  //   const favorateItemsWithChecked = this.favorate.map(fav => ({ ...fav }));
  //   localStorage.setItem('favorateItems', JSON.stringify(this.favorate));

  //   const bookmarkedIndex = this.bookmarkedLibrary.findIndex(bookmarkedItem => bookmarkedItem._id === item._id);

  //   if (item.isChecked && bookmarkedIndex === -1) {
  //     this.bookmarkedLibrary.push({ ...item, isChecked: true });
  //   } else if (!item.isChecked && bookmarkedIndex !== -1) {
  //     this.bookmarkedLibrary.splice(bookmarkedIndex, 1);
  //   }
  //   if (!item.isChecked) {
  //     const libraryIndex = this.library.findIndex((libItem: { _id: any; }) => libItem._id === item._id);
  //     if (libraryIndex !== -1) {
  //       this.library.splice(libraryIndex, 1);
  //     }
  //   }
  // }




  getCategory() {
    this.auth.get('category?action=all').subscribe({
      next: (res) => {
        this.categories = res.data.data;
        this.categories.sort((a: any, b: any) => a.name.localeCompare(b.name));
      },
      error: (err) => { },
    });
  }
  getProjects() {
    let q = '';
    if (this.search) {
      q = 'project?search=' + this.search;
    } else {
      q = 'project';
    }
    this.auth.get(q).subscribe({
      next: (res) => {
        this.projects = res.data.data;
        this.projectfound = true;
      },
      error: (err) => {
        this.projectfound = false;
      },
    });
  }

  field(id: any) {
    if (this.auth.isAdmin()) {
      this.router.navigate(['/admin/template', id])
    } else {
      this.router.navigate(['/user/template', id])
    }
  }
  onSubmit() {
    this.btnload = true;
    this.submitted = true;

    let endpoint = 'project';
    let body = this.projectForm.value;
    if (this.projectForm.invalid) {
      this.btnload = false;
    } else {
      this.auth.post(endpoint, body).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.projectForm.reset();
            this.reset();
            this.closebutton.nativeElement.click();
            Swal.fire({
              icon: 'success',
              title: 'Success.',
              text: 'Project created successfully',
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 2000,
            });
          }
          this.submitted = false;
          this.btnload = false;

        },
        error: (error: any) => {
          this.submitted = false;
          this.btnload = false;
          Swal.fire({
            icon: 'error',
            position: 'top',
            toast: true,
            title: error.error.data.message,
            showConfirmButton: false,
            timer: 5000,
          });
        },
      });
    }
  }
  reset() {
    this.projectfound = true;
    this.getProjects();
  }
  resetlibrary() {
    this.getLibrary()
    this.library = []
  }
  active(id: any) {
    this.category_id = id
    this.resetlibrary();
    const navLinks = document.querySelectorAll('.link');
    navLinks.forEach((link) => {
      link.addEventListener('click', (event: any) => {
        event.preventDefault(); // prevent default navigation behavior
        navLinks.forEach((link) => link.classList.remove('active'));
        link.classList.add('active');
        // window.location.href = this.href; // navigate to the link URL
      });
    });
  }

  isSpeaking = false;
  speech() {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(this.str);
    utterance.lang = 'en';
    synth.speak(utterance);
  }
}
