
import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatService } from 'src/app/services/chat.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-appsidebar',
  templateUrl: './appsidebar.component.html',
  styleUrls: ['./appsidebar.component.scss']
})
export class AppsidebarComponent implements OnInit {
  isSidebarOpen2 = false;

  menu = false;
  btnload = false;
  chats: any = new Array(2);
  plan: any;
  data: any;
  search: any;
  name: any;

  projectForm!: FormGroup;
  editprojectForm!: FormGroup;

  submitted = false;
  projects: any = [];
  projectfound = false;
  projectId: any;
  selectedProject: any;
  projectdetail: any;

  get f(): { [key: string]: AbstractControl } {
    return this.projectForm.controls;
  }

  get f1(): { [key: string]: AbstractControl } {
    return this.editprojectForm.controls;
  }

  @ViewChild('closebutton') closebutton: any;
  @ViewChild('closebutton1') closebutton1: any;

  constructor(
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public chat: ChatService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    // this.auth.sidebarStateChange.subscribe((state) => {
    //   this.isSidebarOpen = state
    //   // this.auth.getsidebarstate()
    //   this.cdr.detectChanges();

    // });
  }

  ngOnInit(): void {
    this.isSidebarOpen2 = this.auth.getsidebarstate()
    this.auth.sidebarStateChange.subscribe((state) => {
      this.isSidebarOpen2 = state
      // if(state){

      //   this.auth.toggleSidebarState()
      // }else{

      // }
      this.cdr.detectChanges();

    });

    this.projectdetail = JSON.parse(sessionStorage.getItem('project') || '{}');
    this.selectedProject = this.projectdetail


    this.projectForm = this.fb.group({
      name: ['', Validators.required],
    });

    this.editprojectForm = this.fb.group({
      name: ['', Validators.required],
    });

    this.data = JSON.parse(localStorage.getItem('userdata') || '{}');
    if (this.auth.isUser() || this.auth.isAdmin()) {
      this.packageInfo();
    }

    if (!this.auth.isSuperAdmin()) {
      this.getWords();
      this.getProjects();
    }
  }
  closeSidebar(): void {
    this.isSidebarOpen2 = false;
  }

  tabactive(index: any) {
    this.auth.setTab(index);
  }
  // getProjects() {
  //   let q = '';
  //   if (this.search) {
  //     q = 'project?search=' + this.search;
  //   } else {
  //     q = 'project';
  //   }
  //   this.auth.get(q).subscribe({
  //     next: (res) => {
  //       this.projects = res.data.data;

  //       // if (this.projectdetail !== null && this.projectdetail !== undefined && this.projectdetail !== "" && this.projectdetail !== '{}') {
  //       //   this.selectedProject = this.projectdetail
  //       //   sessionStorage.setItem('project', JSON.stringify(this.projectdetail));
  //       //   this.auth.setProjectId(this.selectedProject)
  //       //   this.projectfound = true
  //       // } else {
  //       //   this.selectedProject = this.projects[0]
  //       //   sessionStorage.setItem('project', JSON.stringify(this.projects[0]));
  //       //   this.auth.setProjectId(this.selectedProject)
  //       //   this.projectfound = true
  //       // }

  //       if (!this.projectdetail) {
  //         sessionStorage.setItem('project', JSON.stringify(this.projects[0]));
  //         this.selectedProject = this.projects[0];

  //       }
  //       this.auth.setProjectId(this.selectedProject);
  //       this.projectfound = true;
  //       // },
  //     },
  //     error: (err) => {
  //       this.projectfound = false;
  //     },
  //   });
  // }

  getProjects() {
    let q = '';
    if (this.search) {
      q = 'project?search=' + this.search;
    } else {
      q = 'project';
    }
    this.auth.get(q).subscribe({
      next: (res) => {
        const newProjects = res.data.data;
        this.projects = newProjects;
        const storedProject = sessionStorage.getItem('project');
        if (storedProject) {
          const selectedProjectId = JSON.parse(storedProject).id;
          const selectedProject = newProjects.find((project: { id: number; }) => project.id === selectedProjectId);
          if (selectedProject) {
            this.selectedProject = selectedProject;
          } else {
            this.selectedProject = newProjects[0];
            sessionStorage.setItem('project', JSON.stringify(this.selectedProject));
          }
        } else {
          this.selectedProject = newProjects[0];
          sessionStorage.setItem('project', JSON.stringify(this.selectedProject));
        }
        this.auth.setProjectId(this.selectedProject);
        this.projectfound = true;
      },
      error: (err) => {
        this.projectfound = false;
      },
    });
  }



  setProject(id: any) {
    sessionStorage.setItem('project', JSON.stringify(id));
    this.auth.setProjectId(id);
    this.selectedProject = id;
    if (this.auth.isAdmin()) {
      this.router.navigate(['/admin']);
    } else {
      this.router.navigate(['/user']);
    }
  }

  getWords() {
    this.auth.get('company/' + this.data?.company_id).subscribe({
      next: (res) => {
        this.chat.setWords(res.data?.data?.words);
      },
      error: (err) => { },
    });
  }

  openChat() {
    this.auth.setBar(true);
  }

  packageInfo() {
    this.auth.get('form?action=latest_plan').subscribe({
      next: (res) => {
        // if(res.data.plan?.status=='cancelled'){
        //   this.plan='Cancelled'
        // }else{
        if (res.data.plan?.plan[0]?.title) {
          this.plan = res.data.plan?.plan[0];
        } else {
          let x = {
            title: 'Trial',
            type: 'Trial',
          };
          this.plan = x;
        }
      },
      error: (err) => {
        let x = {
          title: 'Trial',
          type: 'Trial',
        };
        this.plan = x;
      },
    });
  }

  toggle1() {
    const element = document.getElementsByClassName(
      'vertical-menu'
    )[0] as HTMLElement;
    if (element.style.display == 'block') {
      element.style.display = 'none';
      this.menu = false;
      localStorage.setItem('side', '0');
    } else {
      element.style.display = 'block';
      this.menu = true;
      localStorage.setItem('side', '1');
    }
  }

  // @HostListener('click')
  close() {
    const sidebar = document.getElementById('side') as HTMLElement;
    const toggleButton = document.getElementById('toggle') as HTMLElement;
    if (this.menu) {
      document.addEventListener('click', (event: any) => {
        // if the clicked element is not inside the sidebar
        if (
          !sidebar.contains(event.target) &&
          !toggleButton.contains(event.target)
        ) {
          // hide the sidebar
          sidebar.style.display = 'none';
        }
      });
    }
  }

  toggle() {
    const sidebar = document.getElementById('side') as HTMLElement;
    const toggleButton = document.getElementById('toggle') as HTMLElement;
    // document.addEventListener('click', (event:any) => {
    //   // if the clicked element is not inside the sidebar
    //   if (!sidebar.contains(event.target) && !toggleButton.contains(event.target)) {
    //     // hide the sidebar
    //     sidebar.style.display = 'none';
    //   }
    // });

    // toggleButton.addEventListener('click', () => {
    if (sidebar.style.display == 'block') {
      sidebar.style.display = 'none';
      this.menu = false;
      localStorage.setItem('side', '0');
    } else {
      sidebar.style.display = 'block';
      this.menu = true;
      localStorage.setItem('side', '1');
    }
    // });
  }
  closeModal() {
    this.closebutton.nativeElement.click();

  }

  logout() {
    this.auth.setLoginStatus(false);
    this.auth.logout();
    this.router.navigate(['/']);
  }

  onProjectSubmit() {
    this.btnload = true;
    this.submitted = true;

    let endpoint = 'project';
    let body = this.projectForm.value;
    if (this.projectForm.invalid) {
      this.btnload = false;
    } else {
      this.auth.post(endpoint, body).subscribe({
        next: (res: any) => {

          if (res.status == 200) {
            this.closebutton.nativeElement.click();
            this.projectForm.reset();
            this.reset();
            Swal.fire({
              icon: 'success',
              title: 'Success.',
              text: 'Project created successfully',
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 2000,
            });
            if (this.auth.isAdmin()) {
              this.router.navigate(['/admin']);
            } else {
              this.router.navigate(['/user']);
            }
          }
          this.submitted = false;
          this.btnload = false;
        },
        error: (error: any) => {
          this.closebutton.nativeElement.click();

          this.submitted = false;
          this.btnload = false;
          Swal.fire({
            icon: 'error',
            position: 'top',
            toast: true,
            title: error.error.data.message,
            showConfirmButton: false,
            timer: 5000,
          });
        },
      });
    }
  }

  onProjectEdit() {
    this.btnload = true;
    this.submitted = true;

    let endpoint = 'project/' + this.projectId;
    let body = this.projectForm.value;
    if (this.projectForm.invalid) {
      this.btnload = false;
    } else {
      this.auth.post(endpoint, body).subscribe({
        next: (res: any) => {
          this.closebutton1.nativeElement.click();
          (res);
          if (res.status == 200) {
            this.editprojectForm.reset();
            this.reset();
            Swal.fire({
              icon: 'success',
              title: 'Success.',
              text: 'Project edited successfully',
              position: 'top',
              toast: true,
              showConfirmButton: false,
              timer: 2000,
            });
          }
          this.submitted = false;
          this.btnload = false;
        },
        error: (error: any) => {
          this.submitted = false;
          this.btnload = false;
          Swal.fire({
            icon: 'error',
            position: 'top',
            toast: true,
            title: error.error.data.message,
            showConfirmButton: false,
            timer: 5000,
          });
        },
      });
    }
  }

  editName() {
    let endpoint = 'project/' + this.projectId;
    let body = {
      name: this.name,
    };
    this.auth.patch(endpoint, body).subscribe({
      next: (res: any) => {
        if (res.status == 200) {
          this.reset();
          Swal.fire({
            icon: 'success',
            title: 'Success.',
            text: 'Project edited successfully',
            position: 'top',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      },
      error: (error: any) => {
        Swal.fire({
          icon: 'error',
          position: 'top',
          toast: true,
          title: error.error.data.message,
          showConfirmButton: false,
          timer: 5000,
        });
      },
    });
  }

  deleteProject(id: any) {
    // Swal.fire({
    //   title: 'Are you sure?',
    //   text: 'Do you want to delete the project? This cannot be undo.',
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Yes, Delete it!',
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     this.auth.del('project/' + id, '').subscribe({
    //       next: (res: any) => {
    //         if (res.status == 200) {
    //           this.reset();
    //           Swal.fire({
    //             icon: 'success',
    //             position: 'top',
    //             toast: true,
    //             title: 'Success',
    //             text: res.data.message,
    //             showConfirmButton: false,
    //             timer: 2500,
    //           });
    //         }
    //       },
    //       error: (err) => {
    //          (err);
    //       },
    //     });
    //   }
    // });

    this.auth.del('project/' + id, '').subscribe({
      next: (res) => {
        this.reset();
        Swal.fire({
          icon: 'success',
          position: 'top',
          toast: true,
          title: 'Success',
          text: res.data.message,
          showConfirmButton: false,
          timer: 2500,
        });
        this.reset();
      },
      error: (err) => {
        //  (err)
      },
    });
  }

  inputshow = false;
  selected: any;
  editProject(id: any, index: any) {
    this.inputshow = true;
    this.selected = index;
    this.projectId = id;
  }

  navigate() {
    let x = this.auth.getProjectId();
    if (this.auth.isAdmin()) {
      this.router.navigate(['/admin/history', x?._id]);
    } else {
      this.router.navigate(['/user/history', x?._id]);

    }
  }
  navigatetoSetting() {
    this.router.navigate(['/admin/upgrade']);
  }

  reset() {
    this.inputshow = false;
    this.projects = [];
    this.getProjects();
  }

  showButton(index: any) { }

  hideButton(index: any) { }
}
