import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { AuthService } from 'src/app/auth/auth.service';

import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-heidichat',
  templateUrl: './heidichat.component.html',
  styleUrls: ['./heidichat.component.scss']
})
export class HeidichatComponent implements OnInit, OnDestroy {
  @ViewChild('chatContainer') chatContainer: ElementRef | any;

  responsePic = './assets/images/logo.ico';
  profilePic: any = '';
  mesg: any;
  id: any;
  chats: any = [];
  message_time: any;
  response_time: any;
  accent_name: any;
  language: any;
  systemLanguage: any;
  private synth: SpeechSynthesis;
  isTabActive = true;
  record = false

  newMessage: any = '';

  pending = false;
  paragraphText: any;
  text: any = '';

  p1 = 'What are the ethical implications of AI in healthcare, finance, and criminal justice?'
  p2 = 'Hey Heidi, What are the key techniques and tools used to create captivating digital artwork?'
  p3 = 'What are the most effective voice command technologies?'
  p4 = ' What are the implications and effectiveness of using AI to generate professional content?'
  elementRef: any;

  constructor(public chat: ChatService, public auth: AuthService, private activateRoute: ActivatedRoute, private renderer: Renderer2, private route: Router) {
    this.auth.getTriggerFunction().subscribe(() => {
      this.getMessages();
    });
    if(auth.plan == 'trial'){
      this.route.navigate(['/'])
    }
    this.synth = window.speechSynthesis;
  }

  ngOnInit(): void {
    // this.getDetail()
    // this.auth.setPromt(false);
    // this.chat.getData();
    // this.chat.getLoader();
    // // this.chat.setPageLoader(false);
    // this.chat.getpageLoader();



    this.scrolling = false;
    this.profilePic = this.chat.profilePic;

    this.auth.setPromt(false);
    this.activateRoute.params.subscribe((params) => {
      this.mesg = '';
      this.id = params['id'];
      this.chat.setChatId(this.id);
      //this.getMessages();
      this.getDetail()
      this.auth.setPromt(false);
      this.chat.getData();
      this.chat.getLoader();
      this.chat.setPageLoader(false);
      this.chat.getpageLoader();
    });

  }
  ngOnDestroy() {
    this.stopSpeech();
    // this.chat.stop();
  }

  hide = false;
  selected: any;
  showButton(index: any): void {
    this.selected = index;
    this.hide = true;
  }
  scrolling = false;

  scrollOver() {
    this.scrolling = false;
  }
  hideButton(index: any): void {
    this.selected = index;
    this.hide = false;
  }
  isSpeaking = false;
  speech(str: any) {
    // const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(str);
    utterance.lang = 'en';
    utterance.lang = this.accent_name;
    this.synth.speak(utterance);
  }
  stopSpeech() {
    window.speechSynthesis.cancel();
  }
  @HostListener('document:visibilitychange', ['$event'])
  onVisibilityChange(event: Event): void {
    if (document.visibilityState === 'hidden') {
      this.isTabActive = false;
      this.stopSpeech();
    }
  }
  copy(str: any) {
    navigator.clipboard.writeText(str).then(
      () => {
        Swal.fire({
          icon: 'success',
          text: 'Copied To Clipboard ',
          position: 'top',
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
        // this.closebutton.nativeElement.click();
      },
      (err) => {
        Swal.fire({
          icon: 'warning',
          text: 'Failed to copy to clipboard: ',
          position: 'top',
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    );
  }


  getMessages() {
    this.auth.get('message?action=chat_detail&parent_id=' + (this.chat.getChatId() ? this.chat.getChatId() : '')).subscribe({
      next: (res) => {
        if (res.status == 200) {
          let body = {
            message: res.data.messages[0]?.message,
            time: res.data.messages[0]?.message_time,
          };
          this.chats = [];
          this.chats.push(body);
          for (
            var i = 0;
            i < res.data.messages[0]?.message_detail.length;
            i++
          ) {
            const fm = this.chat.formatMessage(res.data.messages[0]?.message_detail[i]?.message)
            let temp:any=[]
            let code=false
            if(fm.code.length>0){
              code=true
              const min_length = Math.min(fm.code.length, fm.text.length);
              for (let i = 0; i < min_length; i++) {
                temp.push(fm.text[i]);
                temp.push(fm.code[i]);
              }

              if (fm.text.length > fm.code.length) {
                temp.push(...fm.text.slice(min_length));
              }
              console.log(temp)
            }
            let x = {
              message: code ? temp : res.data.messages[0]?.message_detail[i]?.message,
              // message: res.data.messages[0]?.message_detail[i]?.message,
              time: res.data.messages[0]?.message_detail[i]?.message_time,
              links: res.data.messages[0]?.message_detail[i]?.response_links,
              code:code
            };
            // this.message_time = res.data.messages[0].message_time;
            // this.response_time = res.data.messages[0].message_detail[i].message_time;
            this.chats.push(x);
          }
          this.chat.setPageLoader(true);
          this.chat.stop()
          this.record = false;


          this.chat.setLoader(false)

        }
      },
      error: (err) => {
        this.chat.setLoader(false);
        this.chat.setPageLoader(false);
        // this.loading = false;
      }
    });
  }

  getDetail() {
    this.auth.get('user?action=self').subscribe({
      next: (res) => {
        this.profilePic = res.data.user?.picture
          ? this.auth.getapi() + res.data.user.picture
          : this.profilePic;
      },
      error: (err) => { },
    });
  }

  setClickedText(index: any): void {
    if (index == 0) {
      this.chat.setClickedText(this.p1);
    }
    else if (index == 1) {
      this.chat.setClickedText(this.p2);
    }
    else if (index == 2) {
      this.chat.setClickedText(this.p3);
    }
    else {
      this.chat.setClickedText(this.p4);
    }
  }
}