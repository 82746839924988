<div class=" shadow-none mt-5 mt-md-0 pt-5 pt-md-0 mobl-height">
    <div *ngIf="projectfound">
        <h4 class="bold mb-2 py-3">Template</h4>
        <div class="">
            <div class="card-body card">
                <input type="search" placeholder="What do you want to create" [(ngModel)]="search"
                    (input)="resetlibrary()" class="form-control w-100 rounded"
                    placeholder="What do you want to create?">
                <ul class="nav nav-pills nav-justified mt-4 flex-nowrap overflow-scroll" role=" tablist">
                    <li class="nav-item waves-effect waves-light" role="presentation"
                        *ngFor="let c of categories; let i = index">
                        <a class="nav-link border border-1 pointer " role="tab" aria-selected="false"
                            (click)="active(c?._id)" tabindex="-1">
                            <span class="text-truncate">{{ c?.name }}</span>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="d-flex justify-content-center align-items-center box pointer my" *ngIf="!noTemp">
                <div>
                    <div class="avatar-sm mx-auto text-center py-3 mt-5">
                        <img src="./assets/images/logo.png" alt="" class="img-fluid" style="height: 100px;" />
                    </div>
                    <p class="h4 fw-light text-center">
                        No template found
                    </p>
                </div>

            </div>
            <div class="tab-content py-3 text-muted" *ngFor="let l of library">
                <div class="tab-pane active show" id="home-1" role="tabpanel" *ngIf="noTemp">
                    <div class="py-2 ">
                        <h4 class="card-title me-2 text-uppercase">{{l.name}}</h4>
                        <div class="row">
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-12" *ngFor="let lb of l?.library">
                                <div class="p-3 card lCard pointer" (click)="field(lb?._id)">
                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex align-itenter ">
                                            <img *ngIf="auth.getapi() && lb?.logo"
                                                src="{{this.auth.getapi() + lb?.logo}}" height="35px" alt="" style="padding: 5px;
                                            background: #e7e7f2ba;border-radius: 5px;
                                        ">
                                        </div>
                                    </div>
                                    <div class="text-muted mt-3">
                                        <h5 data-toggle="tooltip" data-placement="top" title={{lb?.template}}>{{
                                            lb?.template }}</h5>
                                        <small data-toggle="tooltip" data-placement="top" title={{lb?.description}}> {{
                                            lb?.description }}
                                        </small>
                                        <div class="text-end">
                                            <span class="badge bg-primary rounded-pill">
                                                <i class="bx bx-star"></i> {{ lb?.tag }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="px-2 pb-5" *ngIf="!projectfound">
        <div class="d-flex justify-content-center align-items-center text-center center-div" *ngIf="auth.plan!='trial'">
            <div>
                <div class=" avatar-img mx-auto">
                    <img src="./assets/images/logo.png" alt="" class="img-fluid" style="height: 200px;" />
                </div>
                <p class="h4 fw-light">You don't have any projects yet.</p>
                <p class="h4 fw-light text-center">Please create one.</p>
                <button class="btn btn-custom w-100 d-flex justify-content-center my-3" style="align-items: center"
                    data-bs-toggle="modal" data-bs-target="#createNew">
                    <i class="bx bx-plus-medical-circle my-auto me-1"></i>Create new
                </button>
            </div>
        </div>
        <div *ngIf="auth.plan=='trial'">
            <app-bill></app-bill>
        </div>
    </div>

    <!-- create Modal -->
    <div class="modal fade" id="createNew" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="createNewLable" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="p-3 d-flex justify-content-between border-bottom">
                    <h1 class="modal-title fs-5 bold" id="createNewLable">New Project</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" #closebutton
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form action="" [formGroup]="projectForm" (ngSubmit)="onSubmit()">
                        <label for="" class="form-label fw-semibold">Project name </label>
                        <input type="text" class="form-control border w-100 rounded" formControlName="name"
                            [ngClass]="{ 'is-invalid': submitted && f['name'].errors }" />
                        <div *ngIf="submitted && f['name'].errors" class="invalid-feedback">
                            <div *ngIf="f['name'].errors['required']">
                                Project name is required.
                            </div>
                        </div>
                        <div class="mt-3 d-flex justify-content-end">
                            <button class="mb-4 btn btn-custom position-relative w-50" [disabled]="btnload"
                                type="submit">
                                <span class="btn-loader me-2" *ngIf="btnload"></span>
                                <span>
                                    <i class="bx bx-save me-1"></i>
                                    Continue
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- Nav tabs -->
</div>