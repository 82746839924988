import { Component, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatService } from 'src/app/services/chat.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  fname: any;
  lname: any;
  phone: any;
  address: any;
  email: any;
  age: number | undefined;
  country: string | undefined;
  subscribeSelect: any = '';
  expectSelect: any = '';
  heardAboutSelect: any = '';
  whoSelect: any = '';
  domainSelect: any = '';
  sizeSelect: any = '';
  positionSelect: any = '';
  id: any;
  accountCode: any
  loading: boolean = false
  @Input() name: any = '';
  profilePic: any = '';
  mydata: any;
  profileForm!: FormGroup;
  submitted = false
  subtextfield = false
  sub: any
  exptextfield = false
  exp: any
  fieldtextfield = false
  fie: any
  heardtextfield = false
  hea: any
  postextfield = false
  pos: any




  constructor(public auth: AuthService, private chat: ChatService, private fb: FormBuilder,) { }

  subscription = [
    { name: 'To generate quality content quickly and easily' },
    { name: 'To benefit from artificial intelligence and various templates' },
    { name: 'To optimize my marketing, communication and customer service strategy' },
    { name: 'To test the features and performance of the SaaS' },
    { name: 'Other' }
  ]
  expectation = [
    { name: 'Increase my traffic, visibility and reputation' },
    { name: 'Improve my natural referencing and conversion' },
    { name: 'Create original, relevant and adapted content for my audience' },
    { name: 'Save time, money and productivity' },
    { name: 'Learn how to use artificial intelligence for content' },
    { name: 'Other' }
  ]

  heard_about = [
    { name: 'By a search engine (Google, Bing, etc.)' },
    { name: 'By a social network (Facebook, Twitter, Instagram, etc.)' },
    { name: 'By a website or a blog' },
    { name: 'By an online or offline advertisement' },
    { name: 'By a friend, a colleague or a relative' },
    { name: 'By an event, a fair or a conference' },
    { name: 'Other' }

  ]

  who_are_you = [
    { name: 'An individual' },
    { name: 'A self-employed professional' },
    { name: 'An employee of a company' },
    { name: 'A leader of a company' },
    { name: 'A student or a teacher' },
  ]

  domain = [
    { name: 'E-commerce' },
    { name: 'Blogging' },
    { name: 'Media / Press' },
    { name: 'Education / Training' },
    { name: 'Health / Wellness' },
    { name: 'Tourism / Travel' },
    { name: 'Art / Culture' },
    { name: 'Sport / Leisure' },
    { name: 'Finance / Insurance' },
    { name: 'Real estate / Construction' },
    { name: 'Industry / Technology' },
    { name: 'Public service / Association' },
    { name: 'Other' }
  ]
  positions = [
    { name: 'Writer / Journalist / Copywriter' },
    { name: 'Marketer / Communicator / Community manager' },
    { name: 'Project manager / Editorial manager / Content manager' },
    { name: 'Webmaster / Developer / Designer' },
    { name: 'Consultant / Coach / Trainer' },
    { name: 'Salesperson / Seller / Customer relationship' },
    { name: 'Manager / Director / Business owner' },
    { name: 'Student / Teacher / Researcher' },
    { name: 'Other' },

  ]
  size = [
    { name: 'Micro-enterprise (less than 10 employees)' },
    { name: 'Small business (10 to 49 employees)' },
    { name: 'Medium business (50 to 249 employees)' },
    { name: 'Large business (250 employees or more)' }
  ]
  get f(): { [key: string]: AbstractControl } {
    return this.profileForm.controls;
  }
  ngOnInit(): void {
    this.getDetail();
    this.profileForm = this.fb.group(
      {
        fname: new FormControl(),
        lname: new FormControl(),
        phone: new FormControl(),
        age: new FormControl(),
        country: new FormControl(),
        address: new FormControl(),
        subscribeSelect: new FormControl(),
        expectSelect: new FormControl(),
        domainSelect: new FormControl(),
        heardAboutSelect: new FormControl(),
        whoSelect: new FormControl(),
        sizeSelect: new FormControl(),
        positionSelect: new FormControl(),
      }
    );
  }

  onText() {
    if (this.subscribeSelect == 'Other') {
      this.subtextfield = true
    } else {
      this.subtextfield = false
    }

    if (this.expectSelect == 'Other') {
      this.exptextfield = true
    } else {
      this.exptextfield = false
    }

    if (this.domainSelect == 'Other') {
      this.fieldtextfield = true
    } else {
      this.fieldtextfield = false
    }

    if (this.heardAboutSelect == 'Other') {
      this.heardtextfield = true
    } else {
      this.heardtextfield = false
    }

    if (this.positionSelect == 'Other') {
      this.postextfield = true
    } else {
      this.postextfield = false
    }
  }

  onPicChange(event: any) {
    let q = '';
    if (event.target.files.length > 0) {
      var file = event.target.files[0];
      var filesize = 0;
      myNumber: filesize = Number(file.size);
      filesize = Number((filesize / 1024 / 1024).toFixed(4));
      if (filesize < 10) {
        this.readFile(file, (e: any) => {
          this.profilePic = e.target.result;
          var profileData = new FormData();
          profileData.append('file', file, file.name);
          profileData.append('action', 'profile');
          if (this.auth.isSuperAdmin()) {
            q = 'user/' + this.id;
          } else {
            q = 'user/' + this.id;
          }
          this.auth.patch(q, profileData).subscribe({
            next: (res: any) => {
              if (res.status == 200) {
                this.auth.validateToken();
                Swal.fire({
                  icon: 'success',
                  title: 'Success.',
                  text: res.data.message,
                  position: 'top',
                  toast: true,
                  showConfirmButton: false,
                  timer: 2000,
                });
              }
            },
            error: () => { },
          });
        });
      } else {
        Swal.fire({
          title: 'Warning',
          text: 'File must be less than 10 MB.',
          icon: 'warning',
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  }
  readFile(file: any, onLoadCallback: any) {
    var reader = new FileReader();
    reader.onload = onLoadCallback;
    reader.readAsDataURL(file);
  }

  inArray(arr: any, value: any): boolean {
    return arr.some((obj: any) => obj.name.toLowerCase() === value.toLowerCase());
  }

  getDetail() {
    this.auth.get('user?action=self').subscribe({
      next: (res) => {
        this.id = res.data.user?._id;
        this.fname = res.data.user?.first_name;
        this.lname = res.data.user?.last_name;
        this.phone = res.data.user?.meta?.phone;
        this.address = res.data.user?.meta?.address;
        this.email = res.data.user?.email;
        this.country = res.data.user?.meta?.country;
        this.age = res.data.user?.meta?.age;
        this.sizeSelect = res.data.user?.meta?.size ? res.data.user.meta.size : '';
        this.domainSelect = res.data.user?.meta?.domain ? res.data.user.meta.domain : '';
        if (this.domainSelect && !this.inArray(this.domain, this.domainSelect)) {
          this.fie = this.domainSelect
          this.domainSelect = 'Other'
          this.fieldtextfield = true
        }
        this.subscribeSelect = res.data.user?.meta?.subscription ? res.data.user.meta.subscription : '';
        if (this.subscribeSelect && !this.inArray(this.subscription, this.subscribeSelect)) {
          this.sub = this.subscribeSelect
          this.subscribeSelect = 'Other'
          this.subtextfield = true
        }
        this.whoSelect = res.data.user?.meta?.who_are_you ? res.data.user.meta.who_are_you : '';
        this.expectSelect = res.data.user?.meta?.expectation ? res.data.user.meta.expectation : '';
        if (this.expectSelect && !this.inArray(this.expectation, this.expectSelect)) {
          this.exp = this.expectSelect,
            this.expectSelect = 'Other'
          this.exptextfield = true
        }
        this.positionSelect = res.data.user?.meta?.positions ? res.data.user?.meta?.positions : '';
        if (this.positionSelect && !this.inArray(this.positions, this.positionSelect)) {
          this.pos = this.positionSelect
          this.positionSelect = 'Other'
          this.postextfield = true
        }
        this.heardAboutSelect = res.data.user?.meta?.heard_about ? res.data.user.meta.heard_about : '';
        if (this.heardAboutSelect && !this.inArray(this.heard_about, this.heardAboutSelect)) {
          this.hea = this.heardAboutSelect
          this.heardAboutSelect = 'Other'
          this.heardtextfield = true
        }
        this.profilePic = res.data.user?.picture
          ? this.auth.getapi() + res.data.user.picture
          : this.profilePic;
      },
      error: (err) => { },
    });
  }

  updateInfo() {
    this.loading = true;
    this.submitted = true;

    let body = {
      first_name: this.fname,
      last_name: this.lname,
      meta: {
        phone: this.phone,
        address: this.address,
        subscription: this.subscribeSelect == 'Other' ? this.sub : this.subscribeSelect,
        expectation: this.expectSelect == 'Other' ? this.exp : this.expectSelect,
        domain: this.domainSelect == 'Other' ? this.fie : this.domainSelect,
        heard_about: this.heardAboutSelect == 'Other' ? this.hea : this.heardAboutSelect,
        who_are_you: this.whoSelect,
        positions: this.positionSelect == 'Other' ? this.pos : this.positionSelect,
        size: this.sizeSelect,
        country: this.country,
        age: this.age,
      },
      email: this.email,
      action: 'edit',
    };
    this.auth.patch('user/' + this.id, body).subscribe({
      next: (res) => {

        Swal.fire({
          icon: 'success',
          title: 'Success.',
          text: res.data.message,
          position: 'top',
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });

        this.loading = false;
        this.reset();
        this.submitted = false;
      },
      error: (err) => {
        this.submitted = false;

        this.loading = false;
      },
    });
  }

  updateSession() {
    let data = {
      accountcode: this.accountCode
    };
    this.auth.patch('payment/' + this.id, data).subscribe({
      next: (res: any) => {
        this.auth.validateToken();
        this.chat.setWords(res.data.plan?.words);
        Swal.fire({
          icon: 'success',
          title: 'Success.',
          text: res.data.message,
          position: 'top',
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
      },
      error: (error: any) => {
        Swal.fire({
          icon: 'error',
          position: 'top',
          toast: true,
          title: error.error.data.message,
          showConfirmButton: false,
          timer: 5000,
        });
      },
    });
  }

  reset() {
    this.getDetail();
  }
}
