<div class="loader-container" *ngIf="loading">
    <div class="loader-logo"></div>
</div>

<div class="row  mobl-height" [ngClass]="{ 'o-5': loading }">
    <div class="col-xl-4">
        <div class="card overflow-hidden">
            <div class="bg-primary bg-soft">
                <div class="row">
                    <div class="col-7">
                        <div class="text-white p-3">
                            <h5 class="text-white">Welcome Back !</h5>
                        </div>
                    </div>
                    <!-- <div class="col-5 align-self-end">
                        <img src="/assets/images/profile-bg.png" alt="" class="img-fluid">
                    </div> -->
                </div>
            </div>
            <div class="card-body pt-0">
                <div class="row">
                    <div class="col-sm-7">
                        <div class="avatar-md profile-user-wid mb-4">
                            <div class="d-flex justify-content-center">
                                <div class="avatar-upload">
                                    <div class="avatar-preview bg-white rounded-circle">
                                        <input type="file" class="profile-pic-input" id="imageUpload"
                                            (change)="onPicChange($event)" accept=" .png, .jpg, .jpeg" />
                                        <label for="imageUpload">
                                            <i class="bx bx-camera fs-3 text-secondary"></i>
                                        </label>
                                        <img [src]="profilePic || 'assets/images/user.png'" alt=""
                                            class="img-fluid rounded-circle" style="object-position: top" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 class="font-size-15 text-truncate" data-toggle="tooltip" data-placement="top"
                            title={{name}}>{{name}}</h5>
                    </div>
                    <div class="col-sm-5">
                        <div class="pt-4">
                            <p class="text-muted my-2 text-truncate" data-toggle="tooltip" data-placement="top"
                                title={{email}}><span class=" h5">Email</span> <br> {{email}}
                            </p>
                            <p class="text-muted my-2" data-toggle="tooltip" data-placement="top" title={{phone}}><span
                                    class="h5">Phone Number</span><br> {{phone}}</p>
                            <!-- <div class="mt-4">
                                        <a href="javascript: void(0);"
                                            class="btn btn-primary waves-effect waves-light btn-sm">View Profile <i
                                                class="mdi mdi-arrow-right ms-1"></i></a>
                                    </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                            aria-orientation="vertical">
                            <a class="nav-link d-flex justify-content-between  active mb-2" id="v-pills-profile-tab"
                                data-bs-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile"
                                aria-selected="true" tabindex="-1"> <span><i class='bx bx-user me-2'></i>Profile
                                    Details</span><span><span><i
                                            class='bx bx-right-arrow-alt fs-4'></i></span></span></a>

                            <a class="nav-link d-flex justify-content-between mb-2" id="v-pills-app-tab"
                                data-bs-toggle="pill" href="#v-pills-app" role="tab" aria-controls="v-pills-app"
                                aria-selected="true" tabindex="-1" *ngIf="this.auth.isSuperAdmin()"> <span><i class='bx bx-cog me-2'></i>App Setting</span><span><span><i
                                            class='bx bx-right-arrow-alt fs-4'></i></span></span></a>
                            <a class="nav-link d-flex justify-content-between  mb-2" id="v-pills-preferences-tab"
                                data-bs-toggle="pill" href="#v-pills-preferences" role="tab"
                                aria-controls="v-pills-preferences" aria-selected="false" tabindex="-1"
                                *ngIf="this.auth.isAdmin() || this.auth.isUser()"><span><i
                                        class='bx bx-globe me-2'></i>Preferences</span><span><span><i
                                            class='bx bx-right-arrow-alt fs-4'></i></span></span></a>
                            <!-- <a class="nav-link d-flex justify-content-between mb-2" id="v-pills-bill-tab"
                                data-bs-toggle="pill" href="#v-pills-bill" role="tab" aria-controls="v-pills-bill"
                                aria-selected="false" tabindex="-1" *ngIf="this.auth.isAdmin()">
                                <span><i class='bx bx-dollar-circle me-2'></i> Plans & Billing</span>
                                <span><i class='bx bx-right-arrow-alt fs-4'></i></span>
                            </a> -->
                            <a class="nav-link d-flex justify-content-between " id="v-pills-team-tab"
                                data-bs-toggle="pill" href="#v-pills-team" role="tab" aria-controls="v-pills-team"
                                aria-selected="false" *ngIf="this.auth.isAdmin()"> <span><i
                                        class='bx bx-group me-2'></i> Team</span><span><span>
                                        <i class='bx bx-right-arrow-alt fs-4'></i></span></span></a>
                            <a class="nav-link d-flex justify-content-between " id="v-pills-password-tab"
                                data-bs-toggle="pill" href="#v-pills-password" role="tab"
                                aria-controls="v-pills-password" aria-selected="false"> <span><i
                                        class='bx bx-lock-alt me-2'></i>Change
                                    Password</span><span><span><i
                                            class='bx bx-right-arrow-alt fs-4'></i></span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- end card -->
    </div>

    <div class="col-xl-8">
        <div class="card">
            <div class="card-body">
                <div class="tab-content text-muted mt-4 mt-md-0" id="v-pills-tabContent">
                    <div class="tab-pane fade" [ngClass]="{'active': profileActive, 'show': profileActive}" id="v-pills-profile" role="tabpanel"
                        aria-labelledby="v-pills-profile-tab">
                        <app-profile></app-profile>
                    </div>
                    <div class="tab-pane fade" id="v-pills-app" role="tabpanel"
                    aria-labelledby="v-pills-app-tab">
                   <app-appsetting></app-appsetting>
                </div>
                    <div class="tab-pane fade" id="v-pills-preferences" role="tabpanel"
                        aria-labelledby="v-pills-preferences-tab">
                        <app-preferences></app-preferences>
                    </div>
                    <div class="tab-pane fade" [ngClass]="{'active': !profileActive, 'show': !profileActive}" id="v-pills-bill" role="tabpanel" aria-labelledby="v-pills-bill-tab">
                        <app-bill></app-bill>
                    </div>
                    <div class="tab-pane fade " id="v-pills-team" role="tabpanel" aria-labelledby="v-pills-team-tab">
                        <app-team></app-team>
                    </div>
                    <div class="tab-pane fade " id="v-pills-password" role="tabpanel"
                        aria-labelledby="v-pills-password-tab">
                        <app-change-password></app-change-password>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>