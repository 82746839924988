<div class="loader-container" *ngIf="!loading">
    <div class="loader-logo"></div>
</div>
<div *ngIf="loading">
    <div class="p-2 pb-3 bg-white rounded">
        <div class="row my-2 px-2">
            <p class="h3"><strong>Libraries</strong></p>
        </div>
        <div class="my-2 px-2">
            <div class="d-flex  justify-content-center">
                <div class="col-md-8">
                    <div class="search-box  me-1">
                        <div class="position-relative">
                            <input type="text" class="form-control" [(ngModel)]="search" (input)="onSearch()"
                                placeholder="Search library here..." />
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group  ">
                        <!-- <label for="category" class="mb-2 text-base">Category</label> -->
                        <select class="form-select form-select rounded" aria-label=".form-select example" id="category"
                            (change)="onSearch()" (change)="onCategorySelected()" [(ngModel)]="category_id"
                            [ngModelOptions]="{standalone: true}">
                            <option value="">Select any</option>
                            <option *ngFor="let category of categories" [value]="category?._id">
                                {{ category?.name }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f['category_id'].errors" class="invalid-feedback">
                            <div *ngIf="f['category_id'].errors['required']">
                                Category is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 ms-lg-1 ">
                    <a href="javascript:void(0)"
                        class="btn btn-custom d-flex justify-content-center align-items-center btn-sm  text-nowrap"
                        style="border-radius: 5px !important; padding:6px 0 !important; " data-bs-toggle="modal"
                        data-bs-target=".bs-library-modal-center">
                        <i class="fa fa-plus my-auto fs-6 me-1"></i>Add New library</a>
                </div>
            </div>
        </div>
        <div class="my-4 p-2 rounded bg-white ">
            <div class="d-flex flex-column justify-content-center align-items-center py-2" *ngIf="!foundLibrary">
                <p class="h4"><strong>Library not found</strong></p>
                <span class="d-flex justify-content-center mt-2">

                </span>
            </div>
            <table class="table table-hover table-fixed" *ngIf="foundLibrary">
                <!--Table head-->
                <thead>
                    <tr>
                        <th class="">Logo</th>
                        <th class="">Template</th>
                        <th class="">Description</th>
                        <th class="">Tag</th>
                        <th class="d-flex justify-content-end pe-5">Action</th>
                    </tr>
                </thead>
                <!--Table head-->

                <!--Table body-->
                <tbody *ngFor="let l of labraries; let i=index">
                    <tr>
                        <!-- <th scope=" row">1</th> -->

                        <td><img src="{{this.auth.getapi() + l?.logo}}" height="35px" alt=""></td>
                        <td class="text-truncate" data-toggle="tooltip" data-placement="top" title={{l?.template}}>
                            {{l?.template}}</td>
                        <td class="text-ellipsis" data-toggle="tooltip" data-placement="top" title={{l?.description}}>
                            {{l?.description}}</td>
                        <td>{{l?.tag}}</td>
                        <td class="d-flex justify-content-end pe-4">

                            <button class="btn btn-info btn-sm me-2 btn-icon" data-toggle="tooltip" data-placement="top"
                                data-backdrop="static" data-description board="false" data-bs-toggle="modal"
                                data-bs-target=".bs-edit-modal-center" title="Edit library" (click)="editLibrary(i)">
                                <!-- (click)="editlibrary(i)" -->
                                <i class="fa fa-edit"> </i>
                            </button>
                            <button class="btn btn-danger btn-sm me-2 btn-icon" data-toggle="tooltip"
                                data-placement="top" title="Delete library" (click)="deleteLibrary(l?._id)">
                                <i class="fa fa-trash-alt"> </i>
                            </button>
                        </td>

                    </tr>

                </tbody>

            </table>
        </div>
    </div>
</div>

<!-- Add library -->
<div class="modal fade bs-library-modal-center" tabindex="-1" aria-hidden="true" style="display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add library</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    #closebutton></button>
            </div>
            <div class="modal-body">
                <form action="" [formGroup]="addLibraryForm" (ngSubmit)="addLibrary()">
                    <div class="row">



                        <div class="col-12">
                            <div class="form-group">
                                <label for="template" class="form-label">Library Template</label>
                                <input type="text" class="form-control" formControlName="template"
                                    placeholder="Enter library template" [ngClass]="{
                                                            'is-invalid': submitted && f['template'].errors
                                                          }" />
                                <div *ngIf="submitted && f['template'].errors" class="invalid-feedback">
                                    <div *ngIf="f['template'].errors['required']">
                                        Library template is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group ">
                                <label for="description " class="form-label">Description</label>
                                <input type="text" class="form-control" formControlName="description"
                                    placeholder="Enter description"
                                    [ngClass]="{'is-invalid': submitted && f['description'].errors}" />
                                <div *ngIf="submitted && f['description'].errors" class="invalid-feedback">
                                    <div *ngIf="f['description'].errors['required']">
                                        library description is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">

                            <div class="form-group  ">
                                <label for="category" class="mb-2 text-base">Category</label>
                                <select class="form-select form-select rounded" aria-label=".form-select example"
                                    id="category" [(ngModel)]="category_id" [ngModelOptions]="{standalone: true}">
                                    <option *ngFor="let category of categories" [value]="category?._id">
                                        {{ category?.name }}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f['category_id'].errors" class="invalid-feedback">
                                    <div *ngIf="f['category_id'].errors['required']">
                                        Category is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group  ">
                                <label for="tag " class="form-label">Tag</label>
                                <input type="text" class="form-control" formControlName="tag" placeholder="Enter tag"
                                    [ngClass]="{'is-invalid': submitted && f['tag'].errors}" />
                                <div *ngIf="submitted && f['tag'].errors" class="invalid-feedback">
                                    <div *ngIf="f['tag'].errors['required']">
                                        library tag is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" mt-3 d-flex justify-content-end">
                        <div class="text-end">
                            <button class="btn btn-custom d-inline-flex gap-1 " type="submit">
                                <i class="bx bx-plus-medical my-auto fs-6"></i> Add library</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>

<!-- edit library -->
<div class="modal fade bs-edit-modal-center" tabindex="-1" aria-hidden="true" style="display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Update library</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    #editclosebutton></button>
            </div>
            <div class="modal-body">
                <form action="" [formGroup]="editlibraryForm" (ngSubmit)="edit()" enctype="multipart/form-data">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <!-- <label for="template" class="form-label d-block">Upload Logo</label>
                                <input (change)="onImageSelected($event.target)" type="file" id="img" name="img"
                                    accept="image/*" formControlName="image"> -->
                                <!-- <input type="file" id="image" accept="image/*" (change)="onImageSelected($event)"
                                    formControlName="image">-->
                                <label class="form-label ">Upload Logo</label>
                                <input #inputfileforaddtrademark accept="image/*" (change)="onLogoChange($event)"
                                    type='file' class="form-control" />
                                <!-- <img *ngIf="file" [src]="file" alt="Selected Image"> -->

                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="template" class="form-label">library name</label>
                                <input type="text" class="form-control" formControlName="template" placeholder=""
                                    [ngClass]="{'is-invalid': submitted && f1['template'].errors}" />
                                <div *ngIf="submitted && f1['template'].errors" class="invalid-feedback">
                                    <div *ngIf="f1['template'].errors['required']">
                                        library name is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group  ">
                                <label for="description " class="form-label">Description</label>
                                <input type="text" class="form-control" formControlName="description" placeholder=""
                                    [ngClass]="{'is-invalid': submitted && f1['description'].errors}" />
                                <div *ngIf="submitted && f1['description'].errors" class="invalid-feedback">
                                    <div *ngIf="f1['description'].errors['required']">
                                        library description is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group  ">
                                <label for="category" class="mb-2 text-base">Category</label>
                                <select class="form-select form-select rounded" aria-label=".form-select example"
                                    id="category" [(ngModel)]="category_id" [ngModelOptions]="{standalone: true}">
                                    <option *ngFor="let category of categories" [value]="category?._id">
                                        {{ category?.name }}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f['category_id'].errors" class="invalid-feedback">
                                    <div *ngIf="f['category_id'].errors['required']">
                                        Category is required.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-group  ">
                                <label for="tag" class="form-label">Tag</label>
                                <input type="text" class="form-control" formControlName="tag" placeholder="Enter tag"
                                    [ngClass]="{'is-invalid': submitted && f['tag'].errors}" />
                                <div *ngIf="submitted && f['tag'].errors" class="invalid-feedback">
                                    <div *ngIf="f['tag'].errors['required']">
                                        library tag is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-12">
                            <div class="form-group  ">
                                <label for="tag " class="form-label">Tag</label>
                                <input type="text" class="form-control" formControlName="tag" placeholder="Enter tag"
                                    [ngClass]="{'is-invalid': submitted && f['tag'].errors}" />
                                <div *ngIf="submitted && f['tag'].errors" class="invalid-feedback">
                                    <div *ngIf="f['tag'].errors['required']">
                                        library tag is required.
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>

                    <div class=" mt-3 d-flex justify-content-end">
                        <div class="text-end">
                            <button class="btn btn-custom d-inline-flex gap-1 " type="submit">
                                <i class="bx bx-plus-medical my-auto fs-6"></i> Update library</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>