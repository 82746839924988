<nav class="pc-sidebar light-sidebar pb-5 " style="z-index: 1111;" [class.open]="this.auth.getsidebarstate()">
    <div class="navbar-wrapper">
        <div class="m-header" *ngIf="this.auth.isAdmin()">
            <a class="b-brand" routerLink="/admin">
                <img src="/assets/images/logo1-trans.png" height="43px" alt="logo" class="logo logo-lg">
            </a>
        </div>
        <div class="m-header" *ngIf="this.auth.isUser()">
            <a class="b-brand" routerLink="/user">
                <img src="/assets/images/logo1-trans.png" height="43px" alt="logo" class="logo logo-lg">
            </a>
        </div>
        <div class="navbar-content scrolling">
            <ul class="pc-navbar mx-3 ">
                <a *ngIf="this.auth.isAdmin()"
                    class="text-blue mb-2 w-100 d-flex btn-custom justify-content-center text-md text-center py-2  btn dashboard-border"
                    style="align-items: center" routerLink="/admin">
                    <i class='bx bx-left-arrow-circle me-2 mt-1 fs-6 '></i>Back To Dashboard</a>
                <a *ngIf="this.auth.isUser()"
                    class="text-blue mb-2 w-100 d-flex btn-custom justify-content-center text-md text-center py-2  btn dashboard-border"
                    style="align-items: center" routerLink="/user"><i
                        class='bx bx-left-arrow-circle me-2 mt-1 fs-6 '></i>Back To Dashboard
                </a>
                <li class="pc-item pc-hasmenu">
                    <button class="btn btn-outline-primary w-100 d-flex justify-content-center mt-1 mb-3 py-2"
                        style="align-items: center" (click)="newChat()">
                        <i class="bx bx-plus-medical me-1"></i>New Chat
                    </button>
                </li>
                <li class="pc-item pc-hasmenu mt-3">
                    <div class="w-100">
                        <p class="small text-start text-muted mb-3">Chat history</p>
                        <div class="chats p-0 scrolling">
                            <div class="mb-1" *ngFor="let c of chats; let i = index" (mouseover)="showButton(i)"
                                (mouseleave)="hideButton(i)" style="max-height: 100%">
                                <a class="boundry py-2 mx-0" [routerLink]="['/user/heidi/chat', c?._id]"
                                    *ngIf="this.auth.isUser()">
                                    <i class="bx bx-message-rounded-dots text-white mx-2"></i>
                                    <p class="text-white flex-grow-1 wp mb-0 text-ellipsis text-md">
                                        {{ c?.message }}
                                    </p>
                                    <span class="me-2" [ngClass]="hide && selected === i ? 'd-flex' : 'hidden'">
                                        <!-- <i class="bx bx-edit-alt text-white me-1"></i> -->
                                        <i class="bx bxs-trash text-white" (click)="deleteChat(c?._id)"></i>
                                        <!-- <i class="bx bxs-message-rounded-minus text-black"></i> -->
                                    </span>
                                </a>
                                <a class="boundry py-2 mx-0" *ngIf="this.auth.isAdmin()"
                                    [routerLink]="['/admin/heidi/chat', c?._id]">
                                    <i class="bx bx-message-rounded-dots text-white mx-2"></i>
                                    <p class="text-white flex-grow-1 wp mb-0 text-ellipsis text-md">
                                        {{ c?.message }}
                                    </p>
                                    <span class="me-2" [ngClass]="hide && selected === i ? 'd-flex' : 'hidden'">
                                        <!-- <i class="bx bx-edit-alt text-white me-1"></i> -->
                                        <i class="bx bxs-trash text-white" (click)="deleteChat(c?._id)"></i>
                                        <!-- <i class="bx bxs-message-rounded-minus text-black"></i> -->
                                    </span>
                                </a>
                            </div>
                        </div>
                        <p class="small text-start text-muted my-2 pb-3 border-bottom pointer text-hover"
                            (click)="deleteAll()">
                            Clear conversations
                        </p>
                    </div>
                </li>
            </ul>
            <div class="pc-item pc-hasmenu px-3 upgrade w-100">

                <!-- <div class="my-1 w-100 hide border"> -->
                <!-- <div class="my-2 d-flex justify-content-between align-items-baseline">
                        <p class="small text-start text-muted mb-0">Search result</p>
                        <select class="form-select form-select-sm w-50 small" aria-label=".form-select-sm example">
                            <option selected></option>
                            <option value="1">Concise</option>
                            <option value="2">Detailed</option>
                        </select>
                    </div> -->
                <!-- <div class="my-1 d-flex justify-content-between align-items-baseline">
                        <p class="small text-start text-muted mb-0">Location</p>
                        <select class="form-select form-select-sm w-50 small" aria-label=".form-select-sm example">
                            <option selected></option>
                            <option value="1">Australia</option>
                            <option value="2">America</option>
                        </select>
                    </div> -->
                <!-- </div> -->

                <div class=" w-100 my-auto pt-3">
                    <div class="packageBox text-muted">
                        <div class="pt-2 d-flex justify-content-between">
                            <div class="text">
                                <p class="small p-0 text-capitalize">Plan: {{ plan?.title }}</p>
                            </div>
                            <!-- <div class="text">
                                <p class="small p-0 text-capitalize">Quality: {{
                                    plan?.plan_type }}</p>
                            </div> -->
                        </div>
                        <div class="">
                            <div class="text-center">
                                <p>
                                    Words . {{ this.chat.getWords() === "unlimited" ? "Unlimited" :
                                    this.chat.getWords() }}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div class=" w-100" *ngIf="this.auth.isAdmin()">
                    <div class="mt-2 w-100">
                        <a class="btn gradient-button w-100 mb-3 py-2" role="button" (click)="navigatetoSetting()">
                            <i class="bx bxs-zap me-2"></i>Upgrade
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>