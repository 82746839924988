<div class="fixed-bottom" style="background-color: #f8f8fb;">
    <div class="row mx-2 box-container mx-lg-4 pe-xl-0 ps-xl-4 mb-3 chatbox"
        [ngClass]="this.auth.getsidebarstate() ? 'zero' : 'box-container'">
        <div class="col">
            <div class="d-flex justify-content-between align-items-end bg-white rounded px-2">
                <div class="form-check form-switch p-0 d-flex align-items-center gap-5 justify-content-start">
                    <label class="form-check-label smaller text-xs " for="google">
                        <span class="d-lg-inline-flex d-none">Include latest</span> Google
                        data</label>

                    <input class="form-check-input mt-0 me-2" type="checkbox" [checked]="toggle" role="switch"
                        id="google" (change)="googleToggle($event)" />
                </div>
                <small class="text-italic" *ngIf="tempmesg">"{{ tempmesg }}"</small>
                <div class="form-check form-switch d-flex align-items-end gap-5 ">
                    <label class="form-check-label smaller text-xs" for="memory"><span
                            class="d-lg-inline-flex d-none">Enable</span> Memory
                    </label>
                    <input class="form-check-input mt-0 me-2 " type="checkbox" [checked]="memory" role=" switch"
                        id="memory" (change)="meomoryToggle($event)" />
                </div>
            </div>
            <div class="shadow-sm bg-white rounded mt-2">
                <div class="head rounded px-2 m-0 pt-1 d-block d-lg-flex">
                    <div class="my-1 d-flex align-items-baseline">
                        <div class="d-flex align-items-baseline justify-content-center">
                            <div *ngIf="promptSelect">
                                <button type="button"
                                    class="btn px-3 py-0 btn-custom-outline prompts-lib fw text-ellipsis">
                                    <span class="fst-normal">{{ promptData?.title }} </span>
                                </button>
                                <span class="position-absolute remove-icon pointer" (click)="removePrompt()"><i
                                        class="bx bx-x-circle text-blue fs-6"></i></span>
                            </div>
                        </div>
                        <div class="d-flex align-items-baseline justify-content-center a" *ngIf="!promptSelect">

                            <p class="me-2 d-flex justify-content-center align-items-center smaller">Personality:
                            </p>
                            <select class="form-select form-select-sm" aria-label=".form-select-sm example"
                                [(ngModel)]="selectedpersonality">
                                <!-- [(ngModel)]="promptid"> -->

                                <!-- <option value="" selected></option> -->
                                <option *ngFor="let pr of personality">
                                    {{ pr?.name }}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex align-items-baseline justify-content-center a">
                            <p class="ms-1 ms-md-5 me-2 justify-content-center align-items-center smaller">Mood:</p>
                            <select class=" w-100 form-select form-select-sm" aria-label=".form-select-sm example"
                                [(ngModel)]="selectedMood">
                                <option *ngFor="let mood of moods" [value]="mood?.name">
                                    {{ mood?.name }}
                                </option>
                            </select>
                        </div>
                    </div>


                    <div class="my-1 d-flex align-items-center justify-content-between">
                        <p class="me-2 text-primary smaller bold pointer" data-bs-toggle="modal"
                            data-bs-target="#examplecreateModalp">
                            + New prompt
                        </p>
                        <p class="smaller">
                            Charge:
                            <span class="bold text-sm">2<sup> * </sup> output words</span>
                        </p>
                    </div>
                </div>
                <div class="d-flex align-items-baseline w-100 form-control send-msg-input">
                    <span class="m-auto">
                        <i class="bx bx-plus-medical text-blue"></i>
                    </span>
                    <input class="w-100 m-0 border-0 form-control" (keydown.enter)="send()" [(ngModel)]="mesg"
                        placeholder="Write a Facebook ad for Heidi " type="text" id="sendbutton" />
                    <span class="m-auto d-flex align-items-center justify-content-center">
                        <!-- <button class="btn border-0" [disabled]="isSending || mesg == ''"> -->
                        <button class="btn p-0 border-0" [disabled]="isSending || mesg == ''">
                            <i class="bx bxs-send text-blue chat-icon" (click)="send()"></i>
                        </button>
                        <i class="bx bx-microphone ms-3 text-blue chat-icon pointer" (click)="start()"
                            *ngIf="!record; else recording"></i>
                        <ng-template #recording>
                            <i class="bx bx-microphone ms-3 text-danger chat-icon pointer" (click)="stop()"></i>
                        </ng-template>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- prompt modal  -->
<div class="modal fade" id="examplecreateModalp" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Create a new prompt</h5>
                <button #closebutton type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="promptForm" (ngSubmit)="submitForm()">
                    <div class="mb-3">
                        <label for="title" class="col-form-label">Prompt title</label>
                        <input type="text" class="form-control" formControlName="title" id="title" [ngClass]="{
                'is-invalid': submitted && f['title'].errors
              }" placeholder="What is prompt about? E.g.. Facebook ad copy" />
                        <div *ngIf="submitted && f['title'].errors" class="invalid-feedback">
                            <div *ngIf="f['title'].errors['required']">
                                Prompt title is required.
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="description" class="col-form-label">Prompt description</label>
                        <input type="text" class="form-control" id="description" formControlName="description"
                            placeholder="Describe what your prompt does, e.g., Compelling ads for FB…" [ngClass]="{
                'is-invalid': submitted && f['description'].errors
              }" />
                        <div *ngIf="submitted && f['description'].errors" class="invalid-feedback">
                            <div *ngIf="f['description'].errors['required']">
                                Prompt description is required.
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="d-flex justify-content-between">
                            <label for="template" class="col-form-label">Prompt template</label>
                            <p class="text-muted mb-2">1000 characters left</p>
                        </div>
                        <textarea class="form-control input-search"
                            placeholder="Write compelling Facebook ad copy for [target audience] with the goal to [your purpose] for [your product name and description]"
                            id="template" formControlName="template" [ngClass]="{
                'is-invalid': submitted && f['template'].errors
              }"></textarea>
                        <div *ngIf="submitted && f['template'].errors" class="invalid-feedback">
                            <div *ngIf="f['template'].errors['required']">
                                Prompt template is required.
                            </div>
                        </div>
                        <p class="fw-bold mt-2 text-black text-sm">
                            <a class="ml-1 mr-1" target="_blank" href="#"> Click here </a>
                            to learn more about how to create a prompt.
                        </p>

                        <div class="row">
                            <div class="col-md-6">
                                <label for="category" class="">Category</label>
                                <select class="form-select form-select-sm" aria-label=".form-select-sm example"
                                    id="category" formControlName="category_id" [ngClass]="{
                    'is-invalid': submitted && f['category_id'].errors
                  }">
                                    <option selected>Please select category</option>
                                    <option *ngFor="let category of categories" [value]="category?._id">
                                        {{ category?.name }}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f['category_id'].errors" class="invalid-feedback">
                                    <div *ngIf="f['category_id'].errors['required']">
                                        Category is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="" class="">Language</label>
                                <select formControlName="language_id" class="form-select form-select-sm"
                                    aria-label=".form-select-sm example" id="language_id" [ngClass]="{
                    'is-invalid': submitted && f['language_id'].errors
                  }">
                                    <option value="">Select a language</option>
                                    <option *ngFor="let language of languages" [value]="language?._id">
                                        {{ language.name }}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f['language_id'].errors" class="invalid-feedback">
                                    <div *ngIf="f['language_id'].errors['required']">
                                        Language is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-check mb-4">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                            (change)="check($event)" />
                        <label class="form-check-label text-sm fw-bold text-black" for="flexCheckDefault">
                            Share prompt template publicly
                        </label>
                    </div> -->
                    <button class="w-100 mb-4 btn btn-custom btn-sm py-2 loader-button" type="submit">
                        <span class="btn-loader" *ngIf="btnload"></span>
                        <i class="bx bx-plus-medical-circle fs-6 me-2"></i>Submit new prompt
                    </button>
                    <!-- <button type="submit" class="btn btn-custom mb-4 w-100">
            Submit new prompt
          </button> -->
                </form>

                <p class="text-sm">
                    <span class="pe-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9 11.25C13.1421 11.25 16.5 9.73896 16.5 7.875C16.5 6.01104 13.1421 4.5 9 4.5C4.85786 4.5 1.5 6.01104 1.5 7.875C1.5 9.73896 4.85786 11.25 9 11.25Z"
                                stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round">
                            </path>
                            <path
                                d="M1.5 7.875V11.625C1.5 13.4906 4.85625 15 9 15C13.1438 15 16.5 13.4906 16.5 11.625V7.875"
                                stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round">
                            </path>
                            <path d="M6 10.9688V14.7188" stroke="currentColor" stroke-width="1.4" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                            <path
                                d="M16.5 9.06543C19.9219 9.38418 22.5 10.7436 22.5 12.3748C22.5 14.2404 19.1438 15.7498 15 15.7498C13.1625 15.7498 11.475 15.4498 10.1719 14.9623"
                                stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round">
                            </path>
                            <path
                                d="M7.5 14.9344V16.125C7.5 17.9906 10.8562 19.5 15 19.5C19.1438 19.5 22.5 17.9906 22.5 16.125V12.375"
                                stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round">
                            </path>
                            <path d="M18 15.4688V19.2188" stroke="currentColor" stroke-width="1.4"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M12 10.9688V19.2188" stroke="currentColor" stroke-width="1.4"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </span>
                    Selected prompts for the editor's choice will receive 20k credits
                </p>
            </div>
        </div>
    </div>
</div>