<div style="overflow-x: hidden;">
  <header class="position-fixed w-100 topheight" style="z-index: 100;">
    <div class="shadow-sm px-4 d-flex justify-content-between align-items-center bg-white py-3 photosonic-header">
      <div class="d-flex justify-content-center align-items-center">
        <span class="ml-2">
          <i class="bx bx-menu-alt-left fs-2" type="button" data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
          </i>
        </span>
        <div class="d-flex ms-1 gap-1 justify-content-center align-items-center">
          <img *ngIf=" auth.getapi() && logo" src="{{this.auth.getapi() + logo}}" height="30px" alt=""
            style="padding: 5px;">
          <p class="text-base text-black  mb-0">{{ libraryDetail }}</p>
        </div>
      </div>
      <div class="d-flex gap-5 ">
        <a role="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Share your document">
          <i class="bx bx-upload fs-4 text-blue d-none"></i>
        </a>
        <button (click)="closeBtn()" type="button" class="btn-close" aria-label="Close"></button>
      </div>
    </div>
  </header>
  <div class="container-fluid pagecontentforlongtext">
    <div class="row" *ngIf="!noTempFound">
      <div class="col-md-4 col-12 col-sm-12">
        <div class=" scroll-form pe-lg-3">
          <form action="" [formGroup]="fieldForm" *ngIf="fieldsName">
            <div class="form-group">
              <input type="text" class="form-control text-capitalize" placeholder="Untitled Document" [(ngModel)]="name"
                [ngModelOptions]=" 
              {standalone: true}">
            </div>
            <div *ngFor="let field of fieldsName">


              <div class="form-group"
                *ngIf=" field === 'customer_notice' || field === 'scenario'||field === 'joke_style'||field === 'question'|| field === 'reduce_or_develop'||field === 'content' ||field === 'subject_of_the_paragraph'||  field === 'excerpt_from_the_text'|| field === 'feature_list'|| field === 'tweet_subject'|| field === 'post_subject'|| field==='viewpoint'|| field==='project_details'|| field==='topic'|| field==='apply_for_job_or_school' || field==='school_or_diploma' || field==='short_or_details' || field==='subject_or_project'  || field==='brand_style' || field==='company_type' || field==='size' || field==='page_size' || field==='book_name' ||field==='diplome'  || field==='quality' || field==='location'  || field==='title' || field==='plateform' ||  field==='video_subject' || field==='rank_keyword' || field==='music_style' || field==='explicit_subject' || field==='email_purpose' || field==='goal' || field==='hearing' || field==='audience' || field==='email_subject' || field==='benefit'  || field==='brand_name' || field==='key_benefit' || field==='keyword' || field==='number_of_word' || field==='Product_name' || field==='subject' || field==='company_name' || field==='discount' || field==='Domain_or_activity'">
                <label class="form-label text-capitalize">{{formatLabel(field)}} <span *ngIf="isRequiredField(field)"
                    class="text-danger">*</span>
                </label>
                <input type="text" class="form-control" [formControlName]="field"
                  [required]="(field !== 'tone')||isRequiredField(field)" [placeholder]="
                  field==='Brand_name' || field==='Product_name' || field==='company_name' ? 'Heidi' :
                  formatLabel(field)" />
                <div *ngIf="field === 'company_name' && fieldForm.get(field)?.hasError('required') && submitted"
                  class="text-danger text-capitalize">
                  {{ formatLabel(field) }} is required.
                </div>
              </div>

              <div class="form-group"
                *ngIf="field==='element_to_include_in_the_response' ||  field==='personal_info' ||  field==='content' ||  field==='objective' || field==='housing_info'|| field==='brand_info' ||field === 'company_info' || field === 'accommodation_info' || field === 'context_description' || field==='text' || field==='Scenario' || field==='Product_Info' || field==='description' || field === 'email_received' || field==='info'|| field === 'company_description' ">
                <label class="form-label text-capitalize">{{formatLabel(field)}}
                  <span *ngIf="isRequiredField(field)" class="text-danger">*</span>
                </label>
                <textarea [required]="(field !== 'tone') || isRequiredField(field)" class="form-control"
                  [formControlName]="field" rows="5"
                  [placeholder]="field === 'Product_Info' || field === 'description' || field === 'company_name' ? 'Heidi makes it super easy and fast for you to compose high-performing landing pages, product descriptions, ads, and blog posts in seconds.' :  formatLabel(field) + '....'"></textarea>
                <div *ngIf="(field !== 'Product_Info') && hasError(field, 'required') && submitted"
                  class="text-danger text-capitalize">
                  {{ formatLabel(field) }} is required.
                </div>
              </div>
              <div class="form-group" *ngIf="field==='language'">
                <label class="form-label text-capitalize">
                  {{field}}
                  <span *ngIf=" field==='language' " class="text-danger">*</span></label>
                <select required class="form-select" [formControlName]="field">
                  <option disabled selected aria-selected="true">Select Language</option>
                  <option *ngFor="let language of languages; let i=index" [value]="language?.name">
                    {{ language?.name }}
                  </option>
                </select>
                <div *ngIf="hasError('language', 'required') && submitted" class="text-danger">
                  Language is required.
                </div>
              </div>
              <div class="form-group" *ngIf="field==='tone'">
                <label class="form-label text-capitalize">{{field}}</label>
                <select class="form-select" [formControlName]="field">
                  <option disabled [selected]="field === 'tone'" aria-selected="true">Select Tone
                  </option>
                  <option *ngFor="let tone of tons let i=index;" [selected]="tone.name === 'Formal'"
                    [value]="tone?.name ">
                    {{ tone?.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row py-3 form-btn">
              <hr />
              <div class="col-md-6">
              </div>

              <div class="col-12 pill">
                <!-- <button type="button" *ngIf="!isUpdating" class="btn btn-custom btn-lg position-relative mb-4 w-100"
                  (click)="sendData()">
                  <span class="btn-loader" *ngIf="btnload"></span>
                  Generate
                </button>

                <button type="button" *ngIf="isUpdating" class="btn btn-custom btn-lg position-relative mb-4 w-100"
                  (click)="updateData()" [disabled]="btnload">
                  <span class="btn-loader" *ngIf="btnload"></span>
                  Update
                </button> -->
                <button *ngIf="!isUpdating" type="button" class="btn btn-custom btn-lg position-relative mb-4 w-100"
                  (click)="sendData()">
                  <span class="btn-loader" *ngIf="btnload"></span>
                  Generate
                </button>
                <button *ngIf="isUpdating" type="button" class="btn btn-custom btn-lg position-relative mb-4 w-100"
                  (click)="updateData()" [disabled]="btnload">
                  <span class="btn-loader" *ngIf="btnload"></span>
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>

      </div>
      <div class="col-md-8 col-12 col-sm-12">
        <div class="d-flex justify-content-center align-items-center box pointer" *ngIf="!response"
          style="height: calc(100vh - 150px);">
          <div>
            <div class="avatar-sm mx-auto text-center py-3">
              <img src="./assets/images/logo.png" alt="" class="img-fluid" style="height: 100px;" />
            </div>
            <p class="h4 fw-light">
              Your copies created by Heidi
            </p>
            <p class="h4 fw-light text-center">will appear here.</p>
          </div>
        </div>

        <div *ngIf="response">
          <div *ngFor="let lt of longText; let i = index; ">
            <div class="px-3 py-3 my-2 shadow-sm" style="border-radius: 8px;" (click)="showIcons(i)"
              [ngClass]="hide && selected === i ? 'border-blue' : 'border'">
              <div class="NgxEditor__Wrapper border-0">

                <!--  [ngModel]="lt?.ai_response" -->
                <!-- {{geteditor(lt)}} -->
                <ngx-editor-menu [editor]="editorarray[i]"> </ngx-editor-menu>
                <ngx-editor class="" [editor]="editorarray[i]" [(ngModel)]="tempresponse[i]" [disabled]="false"
                  style="white-space: pre-line !important; " [placeholder]="'Type here...'"></ngx-editor>
              </div>

              <!-- <pre class="mb-0 text-justify " style="white-space: pre-line;">
                {{lt?.ai_response}}
              </pre> -->
              <div class="d-flex justify-content-between  mt-2">
                <p class="text-muted pb-0 mb-1">
                  {{lt?.wordcount}} words / {{lt?.charactorcount}} characters
                  <!-- wor/ 605 characters, 8 hours 16 minutes ago -->
                </p>
                <span class="mt-2" [ngClass]="hide && selected === i ? 'd-flex' : 'hidden'">
                  <!-- <i class="bx bx-copy me-2" title="Copy Respone" (click)="copy(lt?.ai_response)"></i> -->
                  <i class="bx bx-copy me-2" title="Copy Respone" (click)="copy(tempresponse[i])"></i>
                  <i class="bx bx-trash me-2" title="Delete Response" (click)="deleteLongText(i)"></i>
                  <!-- <i class="bx bxs-edit me-2"></i>
                <i class="bx bx-bookmark me-2"></i> -->
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12" style="border-left: 1px solid #f1f1f1;" *ngIf="noTempFound">
        <div class="d-flex justify-content-center align-items-center box pointer" style="height: calc(100vh - 150px);">
          <div>
            <div class="avatar-sm mx-auto text-center py-3">
              <img src="./assets/images/logo.png" alt="" class="img-fluid" style="height: 100px;" />
            </div>
            <p class="h4 fw-light text-center">
              No template found
            </p>
            <p class="h5 fw-light text-center">Please create a new one</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- offcanvas -->
  <div class="offcanvas offcanvas-start sidebar" style="z-index: 9999 !important;" data-bs-scroll="true" tabindex="-1"
    id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
    <div class="offcanvas-header">
      <div>
        <h4>Heidi</h4>
      </div>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div class="input-group mb-3">
        <span class="input-group-text" id="basic-addon1">
          <i class="bx bx-search"></i>
        </span>
        <input type="text" class="form-control" placeholder="Search" aria-label="Search" [(ngModel)]="search"
          (input)="searchLibrary()" aria-describedby="basic-addon1" />
      </div>
      <div class="accordion " id="accordionExample">
        <div class="accordion-item border-0"
          *ngFor="let item of (isSearchEmpty ? library : filteredLibrary); let i = index"
          [ngClass]="{ open: isItemOpen(i) }">
          <h2 class="accordion-header" [attr.id]="'heading' + i">
            <div class="accordion-button border-0 justify-content-between d-flex" type="button"
              (click)="toggleCollapse(i)" [attr.aria-expanded]="isItemOpen(i) ? 'true' : 'false'"
              [attr.aria-controls]="'collapse' + i">
              {{ item?.name }}
              <!-- <i class="accordion-icon" *ngIf="!open"></i> -->
              <span class="d-flex justify-content-end">
                <i class="bx bx-chevron-up fs-2" *ngIf="(open && select != i) || !open"></i>
                <i class="bx bx-chevron-down fs-2" *ngIf="open && select === i"></i>
              </span>
            </div>
          </h2>
          <div *ngIf="isItemOpen(i)" [id]="'collapse' + i" class="accordion-collapse">
            <div class="accordion-body px-2 py-0" *ngFor="let lb of item?.library">
              <button class="btn btn-custom my-1 w-100" data-bs-dismiss="offcanvas" (click)="fiel(lb?._id)">
                {{lb?.template}}
              </button>
            </div>
          </div>
        </div>
        <!-- <div class="d-flex align-items-center justify-content-center mt-5" *ngIf="notFound">
          <h5 class="text-center">No Library Found</h5>
        </div> -->
      </div>
    </div>
  </div>
</div>