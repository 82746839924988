import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Renderer2,
  OnDestroy,
  HostListener,

} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatService } from 'src/app/services/chat.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-chatdetails',
  templateUrl: './chatdetails.component.html',
  styleUrls: ['./chatdetails.component.scss']
})
export class ChatDetailsComponent implements OnInit, OnDestroy {
  mesg: any;
  id: any;
  chats: any = [];
  loading: boolean = false;
  isTabActive = true;
  temp: any;
  responsePic = './assets/images/logo.ico';
  profilePic: any = '';
  message_time: any
  response_time: any;
  accent_name: any;
  language: any;
  systemLanguage: any;
  private synth: SpeechSynthesis;
  newMessage: any = '';
  response_link: any



  constructor(
    public auth: AuthService,
    private activateRoute: ActivatedRoute,
    private renderer: Renderer2,
    public chat: ChatService,
    private route: Router
  ) {
    this.auth.getTriggerFunction().subscribe(() => {
      this.getMessages();
    });
    this.synth = window.speechSynthesis;
  }
  ngOnInit(): void {
    if(this.auth.plan == 'trial'){
      this.route.navigate(['/'])
    }
    this.getDetail();
    this.chat.getData();
    this.chat.getLoader()



    this.scrolling = false;
    this.profilePic = this.chat.profilePic;

    this.auth.setPromt(false);
    this.activateRoute.params.subscribe((params) => {
      this.mesg = '';
      this.id = params['id'];
      this.chat.setChatId(this.id);
      this.getMessages();
    });
  }

  ngOnDestroy() {
    this.stopSpeech();
    // this.chat.stop();

  }
  getMessages() {
    this.loading = true;
    this.auth.get('message?action=chat_detail&parent_id=' + this.id).subscribe({
      next: (res) => {
        if (res.status == 200) {
          let body = {
            message: res.data.messages[0]?.message,
            time: res.data.messages[0]?.message_time,
          };
          this.chats = [];
          this.chats.push(body);
          for (
            var i = 0;
            i < res.data.messages[0]?.message_detail.length;
            i++
          ) {
            const fm = this.chat.formatMessage(res.data.messages[0]?.message_detail[i]?.message)
            let temp:any=[]
            let code=false
            if(fm.code.length>0){
              code=true
              const min_length = Math.min(fm.code.length, fm.text.length);
              for (let i = 0; i < min_length; i++) {
                temp.push(fm.text[i]);
                temp.push(fm.code[i]);
              }

              if (fm.text.length > fm.code.length) {
                temp.push(...fm.text.slice(min_length));
              }
              console.log(temp)
            }
            let x = {
              message: code ? temp : res.data.messages[0]?.message_detail[i]?.message,
              // message: res.data.messages[0]?.message_detail[i]?.message,
              time: res.data.messages[0]?.message_detail[i]?.message_time,
              links: res.data.messages[0]?.message_detail[i]?.response_links,
              code:code
            };
        
            // this.message_time = res.data.messages[0].message_time;
            // this.response_time = res.data.messages[0].message_detail[i].message_time;

            this.chats.push(x);
          }

        }

        this.loading = false;
        this.chat.setLoader(false)
        if (this.chat.getSpeechText()) {
          this.speech(this.chats[this.chats.length - 1]?.message)
        }
      },
      error: (err) => {
        this.loading = false;
        this.chat.setLoader(false)
      },
    });
  }
  hide = false;
  selected: any;
  showButton(index: any): void {
    this.selected = index;
    this.hide = true;
  }
  scrolling = false;
  scrollOver() {
    this.scrolling = false;
  }
  hideButton(index: any): void {
    this.selected = index;
    this.hide = false;
  }

  isSpeaking = false;
  speech(str: any) {
    // const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(str);
    utterance.lang = 'en';
    utterance.lang = this.accent_name;
    this.synth.speak(utterance);
  }
  stopSpeech() {
    window.speechSynthesis.cancel();
  }
  @HostListener('document:visibilitychange', ['$event'])
  onVisibilityChange(event: Event): void {
    if (document.visibilityState === 'hidden') {
      this.isTabActive = false;
      this.stopSpeech();
    }
  }
  copy(str: any) {
    navigator.clipboard.writeText(str).then(
      () => {
        Swal.fire({
          icon: 'success',
          text: 'Copied To Clipboard ',
          position: 'top',
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
        // this.closebutton.nativeElement.click();
      },
      (err) => {
        Swal.fire({
          icon: 'warning',
          text: 'Failed to copy to clipboard: ',
          position: 'top',
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    );
  }


  getDetail() {
    this.auth.get('user?action=self').subscribe({
      next: (res) => {
        this.accent_name = res.data.user?.accent;
        this.profilePic = res.data.user?.picture
          ? this.auth.getapi() + res.data.user.picture
          : this.profilePic;
      },
      error: (err) => { },
    });
  }

}