<div *ngIf="auth.plan !== 'trial' || auth.userRole() == 'superadmin'">
  <app-appheader></app-appheader>
  <app-appsidebar></app-appsidebar>
  <div
  class="bg-white pc-container"
  [ngClass]="this.auth.getsidebarstate() ? 'zero2' : ''"
  >
  <div class="pcoded-content bg-white">
    <router-outlet></router-outlet>
  </div>
</div>
</div>
<div *ngIf="auth.plan === 'trial'">
  <app-appheader></app-appheader>
  <div
    [ngClass]="this.auth.getsidebarstate() ? 'zero2' : ''"
  >
    <div class="pcoded-content bg-white p-4">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>