<div class="loader-container" *ngIf="loading">
    <div class="loader-logo"></div>
</div>

<div class="container-fluid p-0 scrollable-container bgWhite" [ngClass]="{ 'o-5': loading }">
    <div class="row g-0 align-items-center min-vh">
        <div class="col-xl-9 ">
            <div class="w-100">
                <img src="./assets/images/reset.png" class="img-fluid h-100 maskimg w-75" style="object-fit: contain"
                    alt="" />
            </div>
        </div>
        <div class="col-xl-3 mt-5 mt-lg-0 bg-white">
            <div class="px-lg-5 px-4 bg-white">
                <div class="w-100">
                    <div class="d-flex flex-column mt-4">
                        <div class="">
                            <a class="d-block">
                                <img src="./assets/images/logo1-trans.png" class="img-fluid" />

                            </a>
                        </div>
                        <div class="my-auto">
                            <h5 class="my-4 text-pink-custom">Reset password</h5>
                            <div class="mt-4">
                                <div class="tab-content" id="pills-tabContent">
                                    <form [formGroup]="resetPasswordForm" (ngSubmit)="onChangePassword()">
                                        <div class="mb-3">
                                            <div class="form-group">
                                                <label for="password" class="form-label">Password</label>
                                                <input type="password" placeholder="Password" formControlName="password"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': resetPasswordFormsubmitted && f['password'].errors }" />
                                                <div *ngIf="resetPasswordFormsubmitted && f['password'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f['password'].errors['required']">
                                                        Password is required
                                                    </div>
                                                    <div *ngIf="f['password'].errors['minlength']">
                                                        Password must be at least 8 characters long.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mb-3">
                                            <div class="form-group">
                                                <label for="" class="form-label">Confirm Password</label>
                                                <input type="password" placeholder="Confirm Password"
                                                    formControlName="conpassword" class="form-control"
                                                    [ngClass]="{ 'is-invalid': resetPasswordFormsubmitted && f['conpassword'].errors }" />
                                                <div *ngIf="resetPasswordFormsubmitted && f['conpassword'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f['conpassword'].errors['required']">
                                                        Confirm Password is required
                                                    </div>
                                                    <div *ngIf="f['conpassword'].errors['minlength']">
                                                        Password must be at least 8 characters long.
                                                    </div>
                                                    <div *ngIf="f['conpassword'].errors['confirmedValidator']">
                                                        Password and Confirm Password must be match.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-end ">
                                            <button
                                                class="d-flex align-items-center justify-content-center btn btn-custom w-100 "
                                                type="submit">
                                                <i class="bx bx-edit me-1"></i> Save Password
                                            </button>
                                        </div>
                                    </form>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>