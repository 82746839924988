<!-- <div class="loader-container" *ngIf="loading">
    <div class="loader-logo"></div>
</div> -->

<!-- <div [ngClass]="{ 'o-5': loading }"> -->


<div>
    <div class="p-2 pb-3 bg-white rounded">
        <div class="row my-2 px-2">
            <p class="h3"><strong>Templates</strong></p>
        </div>

        <div class="my-2 px-2">
            <div class="d-flex  justify-content-center">
                <div class="col-md-8">
                    <div class="search-box  me-1">
                        <div class="position-relative">
                            <input type="text" class="form-control" placeholder="Search template here..."
                                [(ngModel)]="search" (input)="onSearch()" />
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group  ">
                        <!-- <label for="category" class="mb-2 text-base">Category</label> -->
                        <!-- <select class="form-select form-select rounded" aria-label=".form-select example" id="category"
                            (change)="onSearch()" (change)="onCategorySelected()" [(ngModel)]="category_id"
                            [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let category of categories;let i = index" [value]="category?._id"
                                [selected]="i === 0">
                                {{ category?.name }}
                            </option>
                        </select> -->
                        <select class="form-select form-select rounded" aria-label=".form-select example" id="category"
                            [(ngModel)]="category_id" (change)="onCategorySelected()" (change)="onSearch()"
                            [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let category of categories,let i = index" [value]="category?._id">
                                {{ category?.name }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f['category_id'].errors" class="invalid-feedback">
                            <div *ngIf="f['category_id'].errors['required']">
                                Category is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 ms-lg-1 "> <a href="javascript:void(0)"
                        class="btn btn-custom d-flex justify-content-center align-items-center btn-sm  text-nowrap"
                        style="border-radius: 5px !important; padding:6px 0 !important; " data-bs-toggle="modal"
                        data-bs-target=".bs-template-modal-center">
                        <i class="fa fa-plus my-auto fs-6 me-1"></i> Add New Template</a></div>
            </div>
        </div>


        <div class="mt-4 px-2 pt-2 rounded bg-white ">
            <div class="d-flex flex-column justify-content-center align-items-center py-2" *ngIf="!foundTemplate">
                <p class="h4"><strong>Template not found</strong></p>
                <span class="d-flex justify-content-center mt-2">

                </span>
            </div>
            <div class="table-responsive-lg" *ngIf="foundTemplate">
                <table class="table table-hover">
                    <!--Table head-->
                    <thead>
                        <tr>
                            <th class="">Prompt</th>
                            <th class="d-flex justify-content-end pe-5">Action</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let l of temp ; let i=index">
                        <tr>
                            <!-- <th scope=" row">1</th> -->
                            <!-- <td>{{l?.scenario}}</td>
                        <td>{{l?.tone}}</td>
                        <td>{{l?.language}} -->
                            <!-- <select name="" id="" class="capitalize" class="form-control capitalize">
                                <option [value]="field" *ngFor="let field of l?.fields">
                                    {{field}}
                                </option>
                            </select> -->
                            <!-- </td> -->
                            <td class="text-truncate text-ellipsis" data-toggle="tooltip" data-placement="top"
                                title={{l?.prompt}}>{{l?.prompt}}</td>

                            <td class="d-flex justify-content-end pe-4">

                                <button class=" btn btn-info btn-sm me-2 btn-icon" data-toggle="tooltip"
                                    data-placement="top" data-backdrop="static" data-description board="false"
                                    data-bs-toggle="modal" data-bs-target=".bs-edit-modal-center" title="Edit template"
                                    (click)="editTemplate(i)">
                                    <!-- (click)="editTemplate(i)" -->
                                    <i class="fa fa-edit"> </i>
                                </button>
                                <button class="btn btn-danger btn-sm me-2 btn-icon" data-toggle="tooltip"
                                    data-placement="top" title="Delete template" (click)="deleteTemplate(l?._id)">
                                    <i class="fa fa-trash-alt"> </i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<!-- Add template -->
<div class="modal fade bs-template-modal-center" tabindex="-1" aria-hidden="true" style="display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add template</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #closebutton>
                </button>
            </div>
            <div class="modal-body">
                <form action="" [formGroup]="addTemplateForm" (ngSubmit)="addTemplate()">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group  ">
                                <label for="category" class="mb-2 text-base">Category</label>
                                <select class="form-select form-select rounded" aria-label=".form-select example"
                                    (change)="onCategorySelected()" id="category" [(ngModel)]="category_id"
                                    [ngModelOptions]="{standalone: true}">
                                    <option selected>Please select category</option>
                                    <option *ngFor="let category of categories" [value]="category?._id">
                                        {{ category?.name }}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f['category_id'].errors" class="invalid-feedback">
                                    <div *ngIf="f['category_id'].errors['required']">
                                        Category is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group  ">
                                <label for="library" class="mb-2 text-base">Library</label>
                                <select class="form-select form-select rounded" aria-label=".form-select example"
                                    id="library" [(ngModel)]="library_id" [ngModelOptions]="{standalone: true}">
                                    <option *ngFor="let library of labraries" [value]="library?._id">
                                        {{ library?.template }}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f['library_id'].errors" class="invalid-feedback">
                                    <div *ngIf="f['library_id'].errors['required']">
                                        Library is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="field" class="mb-2 text-base">Field</label>
                                <ng-select [items]="fields" bindLabel="name" class="" bindValue="name"
                                    [ngModelOptions]="{standalone: true}" [multiple]="true" [(ngModel)]="fieldarr">
                                    <ng-template ng-option let-item="item" let-index="index">
                                        <span class="custom-option">{{ item.name }}</span>
                                    </ng-template>
                                </ng-select>

                                <div *ngIf="submitted && f['field_id'].errors" class="invalid-feedback">
                                    <div *ngIf="f['field_id'].errors['required']">
                                        Field is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="token" class="mb-2 text-base">Max Token</label>

                                <input class="form-control" formControlName="max_tokens" type="number"
                                    placeholder="Token"
                                    [ngClass]="{'is-invalid': submitted && f['max_tokens'].errors}" />
                                <div *ngIf="submitted && f['max_tokens'].errors" class="invalid-feedback">
                                    <div *ngIf="f['max_tokens'].errors['required']">
                                        Token is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label for="prompt" class="form-label">Prompt</label>
                            <textarea class="form-control" formControlName="prompt" placeholder="Add your prompt"
                                [ngClass]="{'is-invalid': submitted && f['prompt'].errors}"></textarea>
                            <div *ngIf="submitted && f['prompt'].errors" class="invalid-feedback">
                                <div *ngIf="f['prompt'].errors['required']">
                                    Library template is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" mt-3 d-flex justify-content-end">
                        <div class="text-end">
                            <button class="btn btn-custom d-inline-flex gap-1 " type="submit">
                                <i class="bx bx-plus-medical my-auto fs-6"></i> Add template</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- edit template -->
<div class="modal fade bs-edit-modal-center" tabindex="-1" aria-hidden="true" style="display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Update template</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    #editclosebutton></button>
            </div>
            <div class="modal-body">
                <form action="" [formGroup]="editTemplateForm" (ngSubmit)="edit()">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="category" class="mb-2 text-base">Category</label>
                                <select class="form-select form-select rounded" aria-label=".form-select example"
                                    id="category" [(ngModel)]="category_id" [ngModelOptions]="{standalone: true}">
                                    <option selected>Please select category</option>
                                    <option *ngFor="let category of categories" [value]="category?._id">
                                        {{ category?.name }}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f1['category_id'].errors" class="invalid-feedback">
                                    <div *ngIf="f1['category_id'].errors['required']">
                                        Category is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group  ">
                                <label for="library" class="mb-2 text-base">Library</label>
                                <select class="form-select form-select rounded" aria-label=".form-select example"
                                    id="library" [(ngModel)]="library_id" [ngModelOptions]="{standalone: true}">
                                    <option *ngFor="let library of labraries" [value]="library?._id">
                                        {{ library?.template }}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f1['library_id'].errors" class="invalid-feedback">
                                    <div *ngIf="f1['library_id'].errors['required']">
                                        Library is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="field" class="mb-2 text-base">Field</label>
                                <ng-select [items]="fields" bindLabel="name" class="" bindValue="name"
                                    [ngModelOptions]="{standalone: true}" [multiple]="true" [(ngModel)]="fieldarr">
                                    <ng-template ng-option let-item="item" let-index="index">
                                        <span class="custom-option">{{ item.name }}</span>
                                    </ng-template>
                                </ng-select>

                                <div *ngIf="submitted && f1['field_id'].errors" class="invalid-feedback">
                                    <div *ngIf="f1['field_id'].errors['required']">
                                        Field is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="token" class="mb-2 text-base">Max Tokens</label>

                                    <input class="form-control" formControlName="max_tokens" type="number"
                                        placeholder="Token"
                                        [ngClass]="{'is-invalid': submitted && f['max_tokens'].errors}" />
                                    <div *ngIf="submitted && f['max_tokens'].errors" class="invalid-feedback">
                                        <div *ngIf="f['max_tokens'].errors['required']">
                                            Token is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group  ">
                                <label for="category" class="mb-2 text-base">Field</label>
                                <ng-select [items]="fields" bindLabel="name" class="" bindValue="name"
                                    [ngModelOptions]="{standalone: true}" [multiple]="true" [(ngModel)]="fieldarr">
                                    <ng-template ng-option let-item="item" let-index="index">
                                        <span class="custom-option">{{ item.name }}</span>
                                    </ng-template>
                                </ng-select>
                                <div *ngIf="submitted && f['field_id'].errors" class="invalid-feedback">
                                    <div *ngIf="f['field_id'].errors['required']">
                                        Field is required.
                                    </div>
                                </div>
                            </div> -->
                        </div>

                        <div class="col-12">
                            <div class="form-group">
                                <label for="prompt" class="form-label">Prompt</label>
                                <textarea class="form-control" rows="5" formControlName="prompt"
                                    placeholder="Add your prompt"
                                    [ngClass]="{'is-invalid': submitted && f1['prompt'].errors}"></textarea>
                                <div *ngIf="submitted && f1['prompt'].errors" class="invalid-feedback">
                                    <div *ngIf="f1['prompt'].errors['required']">
                                        Prompt is required.
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class=" mt-3 d-flex justify-content-end">
                        <div class="text-end">
                            <button class="btn btn-custom d-inline-flex gap-1" type="submit">
                                <i class="bx bx-plus-medical my-auto fs-6"></i> Save template</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>