import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-projectdetails',
  templateUrl: './projectdetails.component.html',
  styleUrls: ['./projectdetails.component.scss']
})
export class ProjectdetailsComponent {
  pageName = 'History';
  id: any
  longText: any = []
  inputshow = false
  current: any
  name: any;

  constructor(private route: ActivatedRoute, public auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id']
    this.current = JSON.parse(sessionStorage.getItem('project') || '{}');
    this.getProjectDetails()
  }

  getProjectDetails() {
    this.auth.get('project/' + this.id).subscribe({
      next: (res) => {
        this.longText = res.data.data[0]?.longtext;
        this.name = res.data.data
      },
      error: (err) => {
      }
    })
  }

  deleteProjectDetail(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete the history? This cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.auth.del('longtext/' + id, '').subscribe({
          next: (res: any) => {
            if (res.status == 200) {
              this.reset();
              Swal.fire({
                icon: 'success',
                position: 'top',
                toast: true,
                title: 'Success',
                text: res.data.message,
                showConfirmButton: false,
                timer: 2500,
              });
            }
          },
          error: (err) => {
          },
        });
      }
    });
  }

  reset() {
    this.longText = []
    this.getProjectDetails()
    this.inputshow = false

  }

  openLongText(data: any) {
    sessionStorage.setItem('longtext', JSON.stringify(data))
    if (this.auth.isAdmin()) {
      this.router.navigate(['/admin/template', data?.library_id])
    } else {
      this.router.navigate(['/user/template', data?.library_id])
    }
  }

  navigate1() {
    if (this.auth.isAdmin()) {
      this.router.navigate(['/admin/history'])
    } else {
      this.router.navigate(['/user/history'])
    }
  }

  navigate() {
    if (this.auth.isAdmin()) {
      this.router.navigate(['/admin'])
    } else {
      this.router.navigate(['/user'])
    }
  }
}
